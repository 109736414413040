import React, { useState, useEffect, createElement, useRef } from 'react';
import { Toaster, toast } from 'sonner';
import { useFutureSignalContext } from '../../context/FutureSignalContext';
import { useWebSocket } from '../../hooks/useWebSocket';
import Switcher from "../../components/Switchers/Switcher";
import { formatDate } from '../../utils/formatDate';
import { formatCurrency } from '../../utils/formatCurrency';
import { formatTimeUTC } from '../../utils/formatTimeUTC';
import { formatDateUTC } from '../../utils/formatDateUTC';

interface FutureSignalProps {
  // statistics: PositionItem[];
}

const TableFutureSignals: React.FC<FutureSignalProps> = ({
  // statistics,
}) => {

  // const { pageSize } = useFutureSignalContext();
  let messages: string[] = [];
  let arrayMessage: string[] = [];
  let triangles: string[] = []
  let symbolsMessage: string[] = []
  const [data, setData] = useState<string[]>(arrayMessage);
  // const { connectWS } = useWebSocket();
  let socket: WebSocket;
  let webSocket: any = useRef(null);
  const webSocketExchange: any = useRef(null);
  const webSocketTriangle: any = useRef(null);
  const output = document.getElementById("subscribe");
  const [statusConnect, setStatusConnect] = useState<boolean>(false)
  const [typeSignal, setTypeSignal] = useState<boolean>(true)
  const [uniqueSymbols, setUniqueSymbols] = useState<string[]>(symbolsMessage)

  const WS = function (url: string, type: string) {
    socket = new WebSocket(url);

    switch (type) {
      case 'interexchange':
        messages = arrayMessage;
        webSocket = webSocketExchange;
        break;
      case 'triangle':
        messages = triangles;
        webSocket = webSocketTriangle;
        break;
    }

    socket.onopen = function (e) {
      setStatusConnect(true)
      messages.unshift("[open] Connection established");
      // setData(data => [`[open] Connection established`, ...data])
      // setData(data.concat([`[open] Connection established`]))
      setData(data.concat([` `]))
      // setData(messages);
    };

    socket.onmessage = function (message) {
      const newObj = JSON.parse(message.data);

      const time = `${formatTimeUTC(new Date(newObj.time))} ${formatDateUTC(new Date(newObj.time))}`;
      const symbol = newObj.symbol;
      const buyExchange = newObj.buy_exchange;
      const buyPrice = newObj.buy_price;
      const buyQuantity = newObj.buy_quantity;
      const sellExchange = newObj.sell_exchange;
      const sellPrice = newObj.sell_price;
      const sellQuantity = newObj.sell_quantity;
      const profitPercent = newObj.profit_percent;

      //округление до 8 символов и отброска последний незначащих нулей!!!
      const newStr = `${time} [${symbol}] Buy ${buyExchange} ${Number.parseFloat(buyPrice).toFixed(8).replace(/(?<=\.[0-9]+)0{1,}$/, '')}$  Qty: ${buyQuantity} && Sell ${sellExchange} ${Number.parseFloat(sellPrice).toFixed(8).replace(/(?<=\.[0-9]+)0{1,}$/, '')}$  Qty: ${sellQuantity} | Profit: ${Number.parseFloat(profitPercent).toFixed(2)}% ${message.data}`
      
      if (data[0].includes("[message]")) {
        let strPrevMessage = data[0].split('% ')[1];  //предыдущее сообщение
        const objPrevMessage = JSON.parse(strPrevMessage)

        symbolsMessage.push(JSON.parse(message.data).symbol)
        setUniqueSymbols(uniqueSymbols.concat([` `]))
        
        const messagesAboutTimeToo: string[] = [];
        messages.map((item: string, index) => {
          if(
            item.includes(JSON.parse(message.data).time.split("T")[1].split(".")[0])
            &&
            item.includes(JSON.parse(message.data).symbol)
            &&
            index !== 0
          ) {
            messagesAboutTimeToo.push(item);
            console.log(messagesAboutTimeToo)
          }
        })
        if(!messagesAboutTimeToo.length) {
          // alert("Это сообщение можно выводить, данные не повторяются(время и символ)")
          if (JSON.parse(message.data).symbol === objPrevMessage.symbol) {
            const next = Date.parse(JSON.parse(message.data).time)
            const prev = Date.parse(objPrevMessage.time)
            const period = next - prev;
            if (period > 1000) {
              messages.unshift(`[message] ${newStr}`);
              setData(data.concat([` `]))
            }
          } else {
            messages.unshift(`[message] ${newStr}`);
            setData(data.concat([` `]))
          }
        }
      } else {
        messages.unshift(`[message] ${newStr}`);
        setData(data.concat([` `]))
      }
    };

    socket.onclose = function (event) {
      setStatusConnect(false)
      // arrayMessage.unshift('[close] Socket is closed. Reconnect will be attempted in 1 second.', event.reason)
      // triangles.unshift('[close] Socket is closed. Reconnect will be attempted in 1 second.', event.reason)
      messages.unshift('[close] Socket is closed. Reconnect will be attempted in 1 second.', event.reason)
      setData(messages);
      if (event.code !== 1000 && event.code !== 1005) {
        //переподключение 
        setTimeout(function () {
          WS(url, type);
        }, 1000);
      }
    };

    socket.onerror = function (err) {
      // arrayMessage.unshift(`[error] Socket encountered error: ${err} Closing socket`)
      // triangles.unshift(`[error] Socket encountered error: ${err} Closing socket`)
      messages.unshift(`[error] Socket encountered error: ${err} Closing socket`);
      setData(messages);
      socket.close();
    }

    webSocket.current = socket
  }

  useEffect(() => {
    WS("wss://interexchange.cem.su/ws", "interexchange")
    // WS("wss://triangle.cem.su/ws", "triangle")
    // WS("ws://91.240.84.3:80/ws", "interexchange")
    // WS("ws://91.107.127.146:80/ws", "triangle")

    return () => { console.log("CLOSE"); webSocket.current.close(); }
  }, [])

  // useEffect(() => {
  //   console.log("Дергаю, может данные отрисует...");
  //   // uniqueSymbols = symbolsMessage.filter((value, index, array) => array.indexOf(value) === index);
  //   // console.log(uniqueSymbols);
  // }, [messages, arrayMessage, triangles, data])

  const handleSwitcherChange = () => {
    if (statusConnect) {
      webSocketExchange?.current?.close()
      webSocketTriangle?.current?.close()
    } else {
      if(typeSignal) {
        // debugger
        WS("wss://interexchange.cem.su/ws", "interexchange");
      } else {
        // debugger
        WS("wss://triangle.cem.su/ws", "triangle");
      }
      // WS("ws://91.240.84.3:80/ws", "interexchange")
      // WS("ws://91.107.127.146:80/ws", "triangle")

    }
    setStatusConnect(!statusConnect)
  }

  const handleTypeSignalSwitcherChange = () => {
    setTypeSignal(!typeSignal)
  }

  return (
    <>
      <Toaster position="bottom-center" richColors />
      <div className="overflow-hidden rounded-[10px]">
        <div className="max-w-full max-h-full overflow-y-auto bg-white dark:bg-boxdark">
          <div className="min-h-[700px]">
            <div className="grid grid-cols-1 gap-4 bg-[#F9FAFB] dark:bg-meta-4 px-5 py-4 lg:px-7.5 2xl:px-11">
              <div className="col-span-1 flex gap-4 justify-between items-center">
                {/* <h5 className="font-medium text-[#637381] dark:text-bodydark text-sm">INFO</h5> */}
                <div className="flex gap-2 items-center">
                  <Switcher
                    isActive={statusConnect}
                    onChange={() => { handleSwitcherChange() }}
                    id="reconnect"
                    title="Reconnect WebSocket"
                    classNameWrap=""
                  />
                  <p className={`font-semibold ${statusConnect ? 'text-[#3CA745]' : 'text-[#FF5733]'}`}>Status connecting</p>
                </div>
                {/* <div className="flex gap-2 items-center">
                  <Switcher
                    isActive={typeSignal}
                    onChange={() => { handleTypeSignalSwitcherChange() }}
                    id="type"
                    title="Change type signal"
                    classNameWrap=""
                  />
                  <p className={`font-semibold ${typeSignal ? 'text-[#3CA745]' : 'text-[#FF5733]'}`}>{typeSignal ? 'Type interexchange' : 'Type triangle'}</p>
                </div> */}
                <div className="flex items-center flex-wrap gap-2">
                  {
                    uniqueSymbols.length > 0 ?

                      (uniqueSymbols.filter((value, index, array) => array.indexOf(value) === index) || []).map((item, key) => (
                        item.length > 2 ?
                          <b
                            className="border border-1 rounded-md border-[#FF5733] py-1 px-3 text-[#FF5733]"
                            key={key}
                          >{item}</b>
                          : null
                      ))

                      : null
                  }
                </div>
              </div>
            </div>
            <div className="px-4 py-4">
              <div className="mb-4 border-b border-gray-200 dark:border-gray-700">
                <ul className="flex flex-wrap -mb-px text-sm font-medium text-center" id="default-tab" data-tabs-toggle="#default-tab-content" role="tablist">
                  <li className="me-2" role="presentation">
                    <button
                      className="inline-block p-4 border-b-2 rounded-t-lg text-blue-600 hover:text-blue-600 dark:text-blue-500 dark:hover:text-blue-500 border-blue-600 dark:border-blue-500"
                      id="profile-tab"
                      data-tabs-target="#profile"
                      type="button"
                      role="tab"
                      aria-controls="profile"
                      aria-selected="true"
                    >Interexchange</button>
                  </li>
                  <li className="me-2" role="presentation">
                    <button
                      className="inline-block p-4 border-b-2 rounded-t-lg dark:border-transparent text-gray-500 hover:text-gray-600 dark:text-gray-400 border-gray-100 hover:border-gray-300 dark:border-gray-700 dark:hover:text-gray-300"
                      id="dashboard-tab"
                      data-tabs-target="#dashboard"
                      type="button"
                      role="tab"
                      aria-controls="dashboard"
                      aria-selected="false"
                    >Triangle</button>
                  </li>
                </ul>
              </div>
              <div id="default-tab-content">
                <div
                  className="p-4 rounded-lg bg-gray-50 dark:bg-gray-800"
                  id="profile"
                  role="tabpanel"
                  aria-labelledby="profile-tab"
                >
                  <div id="subscribe" className="h-96 w-full px-5 py-4 lg:px-7.5 2xl:px-11">
                    {
                      (data || []).map((item, key) => (
                        item.includes("[message]") ?
                          <p className="text-gray-600 dark:text-white mb-4" key={key}>{item.split('{')[0]}</p>
                          :
                          <p className="text-gray-600 dark:text-white mb-4" key={key}>{item}</p>
                      ))
                    }

                  </div>
                </div>
                <div className="hidden p-4 rounded-lg bg-gray-50 dark:bg-gray-800" id="dashboard" role="tabpanel" aria-labelledby="dashboard-tab">
                  <p className="text-sm text-gray-500 dark:text-gray-400">This is some placeholder content the <strong className="font-medium text-gray-800 dark:text-white">Dashboard tab's associated content</strong>. Clicking another tab will toggle the visibility of this one for the next. The tab JavaScript swaps classes to control the content visibility and styling.</p>
                </div>
            </div>
          </div>
          
            <div id="subscribe" className="h-96 w-full px-5 py-4 lg:px-7.5 2xl:px-11">
              {/* {
                (data || []).map((item, key) => (
                  item.includes("[message]") ?
                    <p className="text-gray-600 dark:text-white mb-4" key={key}>{item.split('{')[0]}</p>
                    :
                    <p className="text-gray-600 dark:text-white mb-4" key={key}>{item}</p>
                ))
              } */}

            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TableFutureSignals;