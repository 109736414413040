import { BsFillTrashFill, BsFillPencilFill, BsThreeDotsVertical } from "react-icons/bs";
import { FaStarHalfAlt } from "react-icons/fa";
import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom'
import ModalCenter from '../../components/Modals/ModalCenter';
import ModalMoreBalance from '../../components/Modals/ModalMoreBalance';
import ModalBottom from '../../components/Modals/ModalBottom';
import ModalSideRight from "../../components/Modals/ModalSideRight";
import { KEY, OrderHistory, GetOrderHistoryAll, Position, AccountFuture, AccountSpot, InfoBingX } from "../../types/key";
import { Toaster, toast } from 'sonner';
import Key from "./Key";
import Pagination from '../../components/Pagination/Pagination';
import CountPagination from './CountPagination';
import ContentConfirm from "../../components/Modals/ContentConfirm";
import { PiTrademarkFill } from "react-icons/pi";
import { BsClockHistory } from "react-icons/bs";
import NotFound from "../../common/NotFound";
import { formatCurrency } from "../../utils/formatCurrency";
import { formatAmount } from "../../utils/formatAmount";
import { RiRadioButtonLine } from "react-icons/ri";
import HistoryOrdersModal from '../HistoryOrders/HistoryOrdersModal'
import { FaMoneyBillTransfer } from "react-icons/fa6";
import ContentModalSend from "./ContentModalSend";
import { useApiKeysContext } from '../../context/ApiKeysContext';
import BotTooltip from '../../components/CustomTooltip/CustomTooltip';
import { FaComment } from "react-icons/fa";
import CommentForKey from './CommentForKey';


interface TasksProps {
  keys: KEY[];
  totalCount: number;
  fetchKeys: (page: number, keysPerPage: number, filters: {}) => void;
  onPageChange: (selectedItem: { selected: number }) => void;
  currentPage: number;
  setKeys: React.Dispatch<React.SetStateAction<KEY[]>>;
}

const TableApiKeys: React.FC<TasksProps> = ({
  keys,
  totalCount,
  fetchKeys,
  onPageChange,
  currentPage,
  setKeys,
}) => {

  const {
    isModalTransfer,
    isModalMoreBalance,
    toggleModalTransfer,
    toggleModalMoreBalance,
    isModalDeleteOpen,
    isModalCloseOrders,
    isModalFavoriteOpen,
    isModalEditOpen,
    isModalActionsOpen,
    handleTransferOpen,
    handleMoreOpen,
    selectedKey,
    isModalHistoryOrders,
    toggleModalHistoryOrders,
    handleHistoryOrdersKey,
    toggleModalDelete,
    toggleModalCloseOrders,
    toggleModalFavorite,
    toggleModalActions,
    toggleModalEdit,
    handleDeleteKey,
    handleCloseOrders,
    handleActionsKey,
    handleDeleteConfirm,
    handleCloseOrdersConfirm,
    handleFavoriteConfirm,
    handleSaveUpdate,
    handleEditKey,
    handleFavoriteKey,
    orderHistory,
    orderHistoryPage,
    orderHistoryTotalCount,
    handleOrderHistoryPageChange,
    handleTransfer,
    keysAll,
    handleCommentKey,
    isModalCommentOpen,
    toggleModalComment,
    pageSize,
  } = useApiKeysContext();

  const { keysPerPage } = useApiKeysContext();  //10; // количество записей, выводимых на 1 странице
  const pageCount = Math.ceil((totalCount || 0) / pageSize); // количество страниц

  // const offset = currentPage * keysPerPage;
  //const currentKeys = keys.slice(offset, offset + keysPerPage);


  const favoriteKeys = (keys != null && keys.length > 0) ? keys.filter((key) => key.favorite) : []
  const keysArray = (keys != null && keys.length > 0) ? keys.filter((key) => !key.favorite) : []

  // console.log(favoriteKeys.concat(keysArray))

  const memoizedKeys = useMemo(() => favoriteKeys.concat(keysArray), [favoriteKeys.concat(keysArray)])

  const navigate = useNavigate();

  const handleNavigateToManualTrading = (keyItem: KEY) => {
    if (!keyItem?.bot?.settings?.symbol || keyItem?.bot?.settings?.symbol == "") {
      let sym = "BTC-USDT"
      if (keyItem.exchange_name.toUpperCase() == "BINGX") {
        sym = "BTC-USDT"
      } else if (keyItem.exchange_name.toUpperCase() == "BYBIT") {
        sym = "BTCUSDT"
      } else if (keyItem.exchange_name.toUpperCase() == "GATE") {
        sym = "BTC_USDT"
      } else if (keyItem.exchange_name.toUpperCase() == "MEXC") {
        sym = "BTCUSDT"
      } else if (keyItem.exchange_name.toUpperCase() == "BITGET") {
        sym = "BTCUSDT"
      } else if (keyItem.exchange_name.toUpperCase() == "BINANCE") {
        sym = "BTCUSDT"
      }
      navigate(`/manual-trading/${keyItem.id}/${sym}`);
    } else {
      navigate(`/manual-trading/${keyItem.id}/${keyItem?.bot?.settings?.symbol}`);
    }
  };
  useEffect(() => {
    // Обновляем таблицу при изменении ключей
    // console.log("keys:", keys);
  }, [memoizedKeys]);

  return (
    <>
      <Toaster position="bottom-center" richColors />
      <div className="overflow-hidden rounded-[10px]">
        <div className="max-w-full overflow-x-auto">
          <div className="sm:min-w-[1170px]">

            <div className="hidden sm:grid sm:grid-cols-9 sm:grid-rows-1 gap-4 bg-[#F9FAFB] px-5 py-4 dark:bg-meta-4 lg:px-7.5 2xl:px-11">
              {/* <div className="col-span-1">
                <h5 className="font-medium text-[#637381] dark:text-bodydark">#</h5>
              </div> */}
              <div className="col-span-2 flex justify-center items-center">
                <h5 className="font-medium text-[#637381] dark:text-bodydark">NAME</h5>
              </div>
              <div className="col-span-1">
                <h5 className="font-medium text-center text-[#637381] dark:text-bodydark">TYPE</h5>
              </div>
              <div className="col-span-2">
                <h5 className="font-medium text-center text-[#637381] dark:text-bodydark">BALANCE</h5>
              </div>
              <div className="col-span-2">
                <h5 className="font-medium text-center text-[#637381] dark:text-bodydark">POS/ORDERS</h5>
              </div>
              <div className="col-span-1">
                <h5 className="font-medium text-[#637381] dark:text-bodydark">BOT NAME</h5>
              </div>
              <div className="col-span-1 flex justify-center items-center">
                <h5 className="font-medium text-[#637381] dark:text-bodydark">ACTIONS</h5>
              </div>
            </div>

            <div className="bg-white dark:bg-boxdark">
              {keys == null || keys.length === 0 ? (
                <NotFound />
              ) : (
                memoizedKeys.map((memoizedKey, index) => (
                  <Row
                    keysAll={keysAll}
                    keys={keys}
                    handleTransfer={handleTransfer}
                    isModalTransfer={isModalTransfer}
                    isModalMoreBalance={isModalMoreBalance}
                    toggleModalTransfer={toggleModalTransfer}
                    toggleModalMoreBalance={toggleModalMoreBalance}
                    handleTransferOpen={handleTransferOpen}
                    handleMoreOpen={handleMoreOpen}
                    isModalHistoryOrders={isModalHistoryOrders}
                    toggleModalHistoryOrders={toggleModalHistoryOrders}
                    handleHistoryOrdersKey={handleHistoryOrdersKey}
                    key={memoizedKey.id}
                    keyItem={memoizedKey}
                    index={index}
                    currentPage={currentPage}
                    keysPerPage={keysPerPage}
                    handleEditKey={handleEditKey}
                    handleDeleteKey={handleDeleteKey}
                    handleCloseOrders={handleCloseOrders}
                    handleFavoriteKey={handleFavoriteKey}
                    handleActionsKey={handleActionsKey}
                    isModalEditOpen={isModalEditOpen}
                    toggleModalEdit={toggleModalEdit}
                    isModalDeleteOpen={isModalDeleteOpen}
                    isModalCloseOrders={isModalCloseOrders}
                    isModalFavoriteOpen={isModalFavoriteOpen}
                    isModalActionsOpen={isModalActionsOpen}
                    toggleModalDelete={toggleModalDelete}
                    toggleModalCloseOrders={toggleModalCloseOrders}
                    toggleModalFavorite={toggleModalFavorite}
                    toggleModalActions={toggleModalActions}
                    selectedKey={selectedKey}
                    handleDeleteConfirm={handleDeleteConfirm}
                    handleCloseOrdersConfirm={handleCloseOrdersConfirm}
                    handleFavoriteConfirm={handleFavoriteConfirm}
                    handleSaveUpdate={handleSaveUpdate}
                    updateKeys={() => fetchKeys(currentPage, 100, {})}
                    orderHistory={orderHistory}
                    orderHistoryPage={orderHistoryPage}
                    orderHistoryTotalCount={orderHistoryTotalCount}
                    handleOrderHistoryPageChange={handleOrderHistoryPageChange}
                    handleCommentKey={handleCommentKey}
                    isModalCommentOpen={isModalCommentOpen}
                    toggleModalComment={toggleModalComment}
                    pageSize={pageSize}
                  />
                ))
              )}
            </div>

            <ModalBottom
              isOpen={isModalActionsOpen}
              toggleModal={toggleModalActions}
              content={
                <>
                  {selectedKey ?
                    <>
                      <li className="w-full border-b border-solid bg-white dark:bg-[#5A616B]  outline-none dark:text-white hover:text-primary hover:bg-gray-2 dark:border-strokedark dark:hover:bg-primary cursor-pointer">
                        <span
                          className="flex w-full p-4 justify-center"
                          onClick={() => { toggleModalActions(); handleEditKey(selectedKey) }}
                        >Edit key</span>
                      </li>
                      <li
                        className="w-full border-b border-solid bg-white dark:bg-[#5A616B]  outline-none dark:text-white hover:text-primary hover:bg-gray-2 dark:border-strokedark dark:hover:bg-primary cursor-pointer"
                        onClick={() => { toggleModalActions(); handleFavoriteKey(selectedKey) }}
                      >
                        <span className="flex w-full p-4 justify-center">to Favorites</span>
                      </li>
                      {/* <li className="w-full border-b border-solid bg-white dark:bg-[#5A616B]  outline-none dark:text-white hover:text-primary hover:bg-gray-2 dark:border-strokedark dark:hover:bg-primary cursor-pointer">
                        <span
                          className="flex w-full p-4 justify-center"
                          onClick={() => { toggleModalActions(); handleTransferOpen(selectedKey) }}
                        >Transfer balance</span>
                      </li> */}
                      <li className="w-full border-b border-solid bg-white dark:bg-[#5A616B]  outline-none dark:text-white hover:text-primary hover:bg-gray-2 dark:border-strokedark dark:hover:bg-primary cursor-pointer">
                        <span
                          className="flex w-full p-4 justify-center"
                          onClick={() => { toggleModalActions(); handleHistoryOrdersKey(selectedKey) }}
                        >History Orders</span>
                      </li>
                      <li className="w-full border-b border-solid bg-white dark:bg-[#5A616B]  outline-none dark:text-white hover:text-primary hover:bg-gray-2 dark:border-strokedark dark:hover:bg-primary cursor-pointer">
                        <span
                          className="flex w-full p-4 justify-center"
                          onClick={() => { toggleModalActions(); handleDeleteKey(selectedKey) }}
                        >Delete key</span>
                      </li>
                      <li className="w-full border-b border-solid bg-white dark:bg-[#5A616B]  outline-none dark:text-white hover:text-primary hover:bg-gray-2 dark:border-strokedark dark:hover:bg-primary cursor-pointer">
                        <span
                          className="flex w-full p-4 justify-center"
                          onClick={() => { handleNavigateToManualTrading(selectedKey) }}
                        >Manual trading</span>
                      </li>
                      <li
                        className="w-full border-b border-solid bg-white dark:bg-[#a93f3fa8]  outline-none dark:text-white hover:text-primary hover:bg-gray-2 dark:border-strokedark dark:hover:bg-primary cursor-pointer"
                        onClick={() => { toggleModalActions(); handleCloseOrders(selectedKey) }}
                      >
                        <span className="flex w-full p-4 justify-center">Close all Orders/Positions</span>
                      </li>
                    </>
                    : null
                  }
                </>
              }
              data={{
                type: "key",
                value: selectedKey,
              }}
            />

            <ModalSideRight
              isOpen={isModalHistoryOrders}
              toggleModal={toggleModalHistoryOrders}
              title="History Orders"
              content={
                selectedKey && (
                  <HistoryOrdersModal
                    isOpen={isModalHistoryOrders}
                    toggleModal={toggleModalHistoryOrders}
                    orderHistory={orderHistory}
                    orderHistoryPage={orderHistoryPage}
                    orderHistoryTotalCount={orderHistoryTotalCount}
                    handleOrderHistoryPageChange={handleOrderHistoryPageChange}
                  />
                )
              }
            />

            <ModalSideRight
              isOpen={isModalEditOpen}
              toggleModal={toggleModalEdit}
              title="EDIT KEY"
              content={
                selectedKey && (
                  <Key
                    toggleModal={toggleModalEdit}
                    action="edit"
                    keyItem={selectedKey}
                    onSave={handleSaveUpdate}
                    updateKeys={() => fetchKeys(currentPage, 100, {})} />
                )
              }
            />

            <ModalCenter
              isOpen={isModalDeleteOpen}
              toggleModal={toggleModalDelete}
              content={
                selectedKey && (
                  <ContentConfirm
                    toggleModal={toggleModalDelete}
                    text="Are you sure you want to delete this key?"
                    onHandle={handleDeleteConfirm}
                    updateItems={() => fetchKeys(currentPage, 100, {})}
                  />
                )
              }
            />

            <ModalCenter
              isOpen={isModalCloseOrders}
              toggleModal={toggleModalCloseOrders}
              content={
                selectedKey && (
                  <ContentConfirm
                    toggleModal={toggleModalCloseOrders}
                    text="Are you sure you want to CLOSE ALL orders and positions?"
                    onHandle={handleCloseOrdersConfirm}
                    updateItems={() => fetchKeys(currentPage, 100, {})}
                  />
                )
              }
            />

            <ModalCenter
              isOpen={isModalFavoriteOpen}
              toggleModal={toggleModalFavorite}
              content={
                selectedKey && (
                  <ContentConfirm
                    toggleModal={toggleModalFavorite}
                    text="Are you sure you want to toggle this key in Favorites?"
                    onHandle={handleFavoriteConfirm}
                    updateItems={() => fetchKeys(currentPage, 100, {})}
                  />
                )
              }
            />

            <ModalSideRight
              isOpen={isModalCommentOpen}
              toggleModal={toggleModalComment}
              title="COMMENT FOR KEY"
              content={
                selectedKey && (
                  <CommentForKey
                    toggleModal={toggleModalComment}
                    updateKey={() => fetchKeys(currentPage, 100, {})}
                    idKey={selectedKey.id}
                    currentPage={currentPage}
                    commentDefault={selectedKey.comment}
                  />
                )
              }
            />

            <ModalCenter
              isOpen={isModalTransfer}
              toggleModal={toggleModalTransfer}
              content={
                selectedKey && (
                  <ContentModalSend
                    keyFrom={selectedKey}
                    onHandle={(result) => {
                      handleTransfer(result);
                      // fet(currentPage);
                    }}
                    toggleModal={toggleModalTransfer}
                    keys={keysAll}
                  />
                )
              }
            />
          </div>
        </div>
      </div>

      {memoizedKeys && memoizedKeys.length > 0 &&
        <div className='flex flex-wrap items-center gap-4 mt-4'>
          <Pagination
            pageCount={pageCount}
            onPageChange={onPageChange}
          // pageRangeDisplayed={1}
          // marginPagesDisplayed={1}
          />
          <CountPagination />
        </div>
      }
    </>
  );
};

const Row = ({
  keysAll,
  keys,
  handleTransfer,
  toggleModalTransfer,
  toggleModalMoreBalance,
  isModalTransfer,
  isModalMoreBalance,
  keyItem,
  index,
  currentPage,
  isModalHistoryOrders,
  toggleModalHistoryOrders,
  handleTransferOpen,
  handleMoreOpen,
  handleHistoryOrdersKey,
  keysPerPage,
  handleEditKey,
  handleDeleteKey,
  handleCloseOrders,
  handleFavoriteKey,
  handleActionsKey,
  isModalEditOpen,
  toggleModalEdit,
  isModalDeleteOpen,
  isModalCloseOrders,
  isModalFavoriteOpen,
  isModalActionsOpen,
  toggleModalDelete,
  toggleModalCloseOrders,
  toggleModalFavorite,
  toggleModalActions,
  selectedKey,
  handleDeleteConfirm,
  handleCloseOrdersConfirm,
  handleFavoriteConfirm,
  handleSaveUpdate,
  updateKeys,
  orderHistory,
  orderHistoryPage,
  orderHistoryTotalCount,
  handleOrderHistoryPageChange,
  handleCommentKey,
  isModalCommentOpen,
  toggleModalComment,
  pageSize,
}: {
  keysAll: KEY[],
  keys: KEY[],
  keyItem: KEY;
  index: number;
  currentPage: number;
  keysPerPage: number;
  handleEditKey: (key: KEY) => void;
  handleFavoriteKey: (key: KEY) => void;
  handleDeleteKey: (key: KEY) => void;
  handleCloseOrders: (key: KEY) => void;
  handleActionsKey: (key: KEY) => void;
  isModalTransfer: boolean;
  isModalEditOpen: boolean;
  isModalHistoryOrders: boolean;
  isModalMoreBalance: boolean;
  toggleModalTransfer: () => void;
  toggleModalMoreBalance: () => void;
  toggleModalHistoryOrders: () => void;
  handleTransferOpen: (key: KEY) => void;
  handleMoreOpen: (key: KEY, spot: AccountSpot[]) => void;
  handleHistoryOrdersKey: (key: KEY) => void;
  toggleModalEdit: () => void;
  isModalDeleteOpen: boolean;
  isModalCloseOrders: boolean;
  isModalFavoriteOpen: boolean;
  isModalActionsOpen: boolean;
  toggleModalDelete: () => void;
  toggleModalCloseOrders: () => void;
  toggleModalActions: () => void;
  toggleModalFavorite: () => void;
  selectedKey: KEY | null;
  handleTransfer: (result: boolean) => void;
  handleDeleteConfirm: (result: boolean) => void;
  handleCloseOrdersConfirm: (result: boolean) => void;
  handleFavoriteConfirm: (result: boolean) => void;
  handleSaveUpdate: (result: boolean) => void;
  updateKeys: () => void;
  orderHistory: OrderHistory[];
  orderHistoryPage: number;
  orderHistoryTotalCount: number;
  handleOrderHistoryPageChange: (selectedItem: { selected: number }) => void;
  handleCommentKey: (key: KEY) => void;
  isModalCommentOpen: boolean;
  toggleModalComment: () => void;
  pageSize: number;
}) => {

  // Функция для расчета суммы позиций и количества ордеров для выбранного символа
  const calculatePositionsAndOrders = (keyItem: KEY) => {

    // if (!keyItem.bot || !keyItem.bot.symbol) {
    //   return { totalPositionValue: 0, openOrdersCount: 0 };
    // }

    // let positions = []
    // let openOrder = []
    // if (keyItem.exchange_name.toUpperCase() == "BINGX" && !keyItem.infoBingX) {
    //   return { totalPositionValue: 0, openOrdersCount: 0 };
    // } else if (keyItem.exchange_name.toUpperCase() == "BYBIT" && !keyItem.infoBybit) {
    //   return { totalPositionValue: 0, openOrdersCount: 0 };
    // } else if (keyItem.exchange_name.toUpperCase() == "GATE" && !keyItem.infoGate) {
    //   return { totalPositionValue: 0, openOrdersCount: 0 };
    // } else if (keyItem.exchange_name.toUpperCase() == "MEXC" && !keyItem.infoMexc) {
    //   return { totalPositionValue: 0, openOrdersCount: 0 };
    // } else if (keyItem.exchange_name.toUpperCase() == "BITGET" && !keyItem.infoBitget) {
    //   return { totalPositionValue: 0, openOrdersCount: 0 };
    // } else if (keyItem.exchange_name.toUpperCase() == "BINANCE" && !keyItem.infoBinance) {
    //   return { totalPositionValue: 0, openOrdersCount: 0 };
    // }

    // if (keyItem.exchange_name.toUpperCase() == "BYBIT") {
    //   // positions = keyItem.infoBybit.positions || [];
    //   openOrder = keyItem.infoBybit.openOrder || [];
    // } else if (keyItem.exchange_name.toUpperCase() == "GATE") {
    //   // positions = keyItem.infoGate.positions || [];
    //   openOrder = keyItem.infoGate.openOrder || [];
    // } else if (keyItem.exchange_name.toUpperCase() == "MEXC") {
    //   // positions = keyItem.infoMexc.positions || [];
    //   openOrder = keyItem.infoMexc.openOrder || [];
    // } else if (keyItem.exchange_name.toUpperCase() == "BITGET") {
    //   // positions = keyItem.infoBitget.positions || [];
    //   openOrder = keyItem.infoBitget.openOrder || [];
    // } else if (keyItem.exchange_name.toUpperCase() == "BINANCE") {
    //   // positions = keyItem.infoBinance.positions || [];
    //   openOrder = keyItem.infoBinance.openOrder || [];
    // } else {
    //   // positions = keyItem.infoBingX.positions || [];
    //   openOrder = keyItem.infoBingX.openOrder || [];
    // }

    let positions = keyItem?.account?.futurePositions || [];
    let openOrder = keyItem?.account?.futureOpenOrder || [];

    // const filteredPositions = positions.filter(position => position.symbol === keyItem.bot.symbol);
    // const filteredOpenOrders = openOrder.filter(order => order.symbol === keyItem.bot.symbol);

    // const totalPositionValue = filteredPositions.reduce((sum, position) => sum + position.notional, 0);
    const totalPositionValue = positions.reduce((sum, position) => sum + position.notional, 0);
    const openOrdersCount = openOrder.length;
    const positionsCount = positions.length;

    return { totalPositionValue, openOrdersCount, positionsCount };
  };

  const { totalPositionValue, openOrdersCount, positionsCount } = calculatePositionsAndOrders(keyItem);
  const keyName = {
    name: keyItem.name,
  };
  const botName = {
    name: keyItem?.bot?.name,
  };

  // let accountFuture: AccountFuture
  // let accountSpot: AccountSpot
  const moreBalance = (keyItem: KEY | null) => {
    let accountInfo: InfoBingX | undefined
    if (keyItem?.exchange_name.toUpperCase() == "BYBIT") {
      accountInfo = keyItem.infoBybit;
    } else if (keyItem?.exchange_name.toUpperCase() == "GATE") {
      accountInfo = keyItem.infoGate;
    } else if (keyItem?.exchange_name.toUpperCase() == "MEXC") {
      accountInfo = keyItem.infoMexc;
    } else if (keyItem?.exchange_name.toUpperCase() == "BITGET") {
      accountInfo = keyItem.infoBitget;
    } else if (keyItem?.exchange_name.toUpperCase() == "BINANCE") {
      accountInfo = keyItem.infoBinance;
    } else if (keyItem?.exchange_name.toUpperCase() == "HUOBI") {
      accountInfo = keyItem.infoHuobi;
    } else if (keyItem?.exchange_name.toUpperCase() == "OKX") {
      accountInfo = keyItem.infoOKX;
    } else {
      accountInfo = keyItem?.infoBingX;
    }
    return accountInfo;
  }

  let accountInfo = keyItem.account
  // let accountInfo: InfoBingX
  // if (keyItem.exchange_name.toUpperCase() == "BYBIT") {
  //   accountInfo = keyItem.infoBybit;
  // } else if (keyItem.exchange_name.toUpperCase() == "GATE") {
  //   accountInfo = keyItem.infoGate;
  // } else if (keyItem.exchange_name.toUpperCase() == "MEXC") {
  //   accountInfo = keyItem.infoMexc;
  // } else if (keyItem.exchange_name.toUpperCase() == "BITGET") {
  //   accountInfo = keyItem.infoBitget;
  // } else if (keyItem.exchange_name.toUpperCase() == "BINANCE") {
  //   accountInfo = keyItem.infoBinance;
  // } else if (keyItem.exchange_name.toUpperCase() == "HUOBI") {
  //   accountInfo = keyItem.infoHuobi;
  // } else if (keyItem.exchange_name.toUpperCase() == "OKX") {
  //   accountInfo = keyItem.infoOKX;
  // } else {
  //   accountInfo = keyItem.infoBingX;
  // }

  return (
    <div className="grid grid-cols-2 grid-rows-auto sm:grid-cols-9 sm:grid-rows-1 gap-4 border-t border-[#EEEEEE] px-5 py-4 dark:border-strokedark lg:px-7.5 2xl:px-11">
      {/* <div className="col-span-1">
        <div className="flex gap-3 items-left">
          <RiRadioButtonLine
            className={`${keyItem.invalid ? 'text-red-500' : 'text-green-500'}`}
          />
          <p className="text-[#637381] dark:text-bodydark">{currentPage * keysPerPage + index + 1}</p>
        </div>
      </div> */}
      <div className="sm:hidden col-span-1 flex items-center">
        <h5 className="font-medium text-[#637381] dark:text-bodydark">NAME</h5>
      </div>
      <div className="sm:col-span-2 flex justify-between items-start sm:items-center sm:block">
        <div className="flex flex-wrap sm:flex-nowrap gap-3 items-left items-center">
          <p className="text-[#637381] dark:text-bodydark">{currentPage * pageSize + index + 1}</p>
          <RiRadioButtonLine
            className={`${keyItem.invalid ? 'text-red-500' : 'text-green-500'}`}
          />
          {
            keyItem?.favorite ?
              <svg className="h-5 w-5 text-primary" fill="currentColor" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z" />
              </svg>
              :
              <svg className="h-5 w-5 text-slate-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z" />
              </svg>
          }

          <BotTooltip bot={keyName} />
        </div>
        <BsThreeDotsVertical
          className="sm:hidden cursor-pointer duration-300 ease-in-out hover:text-primary text-xl"
          onClick={() => { handleActionsKey(keyItem) }}
          title="Actions"
        />
      </div>

      <div className="sm:hidden col-span-1">
        <h5 className="font-medium text-[#637381] dark:text-bodydark">TYPE</h5>
      </div>
      <div className="sm:col-span-1">
        <p className="text-[#637381] text-center dark:text-bodydark mb-2">{`${keyItem?.exchange_name} (${keyItem?.market})`}</p>
        {/* <div className='col-span-3 flex items-start gap-2'>
          <div className="text-center">
            <FaComment
              className={`${keyItem?.comment && 'text-primary'} cursor-pointer duration-300 ease-in-out hover:text-primary text-xl`}
              onClick={() => { handleCommentKey(keyItem) }}
              title="Add/edit comment"
            />
          </div>

          {keyItem?.comment ?
            <div className={`flex flex-col items-start justify-start overflow-hidden transition-all duration-300 ${true ? 'max-h-96' : 'max-h-18'}`}>
              <p className={`text-[#637381] dark:text-bodydark whitespace-normal`}>{`${keyItem?.comment}`}</p>
            </div>
            :
            null
          }
        </div> */}
      </div>

      <div className="sm:hidden col-span-1">
        <h5 className="font-medium text-[#637381] dark:text-bodydark">BALANCE</h5>
      </div>
      <div className="sm:col-span-2">
        {keyItem.market.toUpperCase() == "FUTURES" ?
          (<p className="text-[#637381] text-center dark:text-bodydark">
            {keyItem.asset == "BTC" ? formatAmount(accountInfo?.futureBalance?.balance) + " BTC" : accountInfo?.futureBalance?.balance > 0 ? formatCurrency(accountInfo?.futureBalance?.balance) : formatCurrency(accountInfo?.futureBalance?.availableBalance)}
          </p>)
          :
          null
          // (
          //   (accountInfo?.accountSpot || []).map((item, index) => (
          //     item.asset === "USDT" ?
          //       (
          //         <>
          //           <p className="text-[#637381] dark:text-bodydark">{formatCurrency(item.free).startsWith("0.00", 1) ? formatCurrency(item.free).slice(0, 2) : formatCurrency(item.free)}
          //             <span
          //               className="flex cursor-pointer text-[#637381] dark:text-bodydark underline :hover:text-primary dark:hover:text-primary"
          //               onClick={() => {
          //                 console.log(`item =`, accountInfo?.accountSpot, selectedKey, moreBalance(selectedKey));
          //                 handleMoreOpen(keyItem, accountInfo?.accountSpot)
          //               }
          //               }
          //             >more...</span>

          //           </p>

          //         </>
          //       )
          //       : null
          //   ))
          // )
        }
        {/* <ModalMoreBalance
          isOpen={isModalMoreBalance}
          toggleModal={toggleModalMoreBalance}
          content={
            <></>
          }
          dataBalance={selectedKey}
        /> */}
      </div>

      <div className="sm:hidden col-span-1">
        <h5 className="font-medium text-[#637381] dark:text-bodydark">POS/ORDERS</h5>
      </div>
      <div className="sm:col-span-2">
        <p className="text-[#637381] text-center dark:text-bodydark">{`${formatCurrency(totalPositionValue).startsWith("0.00", 1) ? formatCurrency(totalPositionValue).slice(0, 2) : formatCurrency(totalPositionValue)} (${positionsCount}) / ${openOrdersCount}`}</p>
      </div>

      <div className="sm:hidden col-span-1">
        <h5 className="font-medium text-[#637381] dark:text-bodydark">BOT NAME</h5>
      </div>
      <div className="sm:col-span-1">
        {keyItem.bot?.name ? <BotTooltip bot={keyItem.bot} /> : <p className="text-[#637381] dark:text-bodydark">—</p>}
      </div>
      <div className="hidden col-span-1 flex items-center">
        <h5 className="font-medium text-[#637381] dark:text-bodydark">ACTIONS</h5>
      </div>
      <div className="hidden sm:flex sm:col-span-1 flex justify-end gap-4">

        {/* <FaMoneyBillTransfer
          className="cursor-pointer duration-300 ease-in-out hover:text-primary text-xl"
          onClick={() => {
            handleTransferOpen(keyItem)
          }}
        /> */}

        {/* <BsClockHistory
          className="cursor-pointer duration-300 ease-in-out hover:text-primary text-xl"
          onClick={() => {
            handleHistoryOrdersKey(keyItem)
          }}
        /> */}

        {/* <PiTrademarkFill
          className="cursor-pointer duration-300 ease-in-out hover:text-primary text-xl"
          onClick={handleNavigateToManualTrading}
        />

        <BsFillPencilFill
          className="cursor-pointer duration-300 ease-in-out hover:text-primary text-xl"
          onClick={() => handleEditKey(keyItem)}
          title="Edit key"
        /> */}

        {/* <BsFillTrashFill
          className="cursor-pointer duration-300 ease-in-out hover:text-primary text-xl"
          onClick={() => handleDeleteKey(keyItem)}
          title="Delete key"
        /> */}

        {/* <FaStarHalfAlt
        className="cursor-pointer duration-300 ease-in-out hover:text-primary text-xl"
        onClick={() => handleFavoriteKey(keyItem)} 
        title="Add to Favorites"
        /> */}

        <BsThreeDotsVertical
          className="cursor-pointer duration-300 ease-in-out hover:text-primary text-xl"
          onClick={() => { console.log(`actions key `, keyItem); handleActionsKey(keyItem) }}
          title="Actions"
        />
      </div>
    </div>
  );
};

export default TableApiKeys;