import React  from 'react';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import TableUsers from './TableUsers';
import ModalSideRight from '../../components/Modals/ModalSideRight';
import ModalCenter from '../../components/Modals/ModalCenter';
import Loader from '../../common/Loader/index';
import { Toaster, toast } from 'sonner';
import BtnPrimary from '../../components/Buttons/BtnPrimary';
import { BsPlusCircleFill } from 'react-icons/bs';
import User from './User';
import { useUsers } from '../../hooks/useUsers';
import { messages } from '../../data/messages';
import ContentConfirm from '../../components/Modals/ContentConfirm';

const breadcrumbItems = [
  { name: 'Dashboard', link: '/dashboard' },
  { name: 'Users', link: '/users' }
];

const Users: React.FC = () => {
  const {
    users,
    isLoading,
    getUsers,
    isModalAddUserOpen,
    toggleModalAddUser,
    selectedUser,
    isModalEditOpen,
    isModalDeleteOpen,
    toggleModalEdit,
    toggleModalDelete,
    handleEditUser,
    handleDeleteUser,
    handleDeleteConfirm,
  } = useUsers();

  const handleEditSave = (result: boolean) => {
    if (result) {
      toast.success(messages.user.edited.success);
    } else {
      toast.error(messages.user.edited.failing);
    }
  };

  const handleAddUserSave = (result: boolean) => {
    if (result) {
      toast.success('User added successfully');
    } else {
      toast.error('Failed to add user');
    }
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <Toaster position="bottom-center" richColors />
      <Breadcrumb items={breadcrumbItems} />

      <BtnPrimary onClick={toggleModalAddUser} className='mb-4'>
        <BsPlusCircleFill /> Add user
      </BtnPrimary>


      <TableUsers users={users} callbackEdit={handleEditUser} callbackDelete={handleDeleteUser} />

      <ModalSideRight
        isOpen={isModalAddUserOpen}
        toggleModal={toggleModalAddUser}
        title="ADD USER"
        content={
          <User
            action="add"
            toggleModal={toggleModalAddUser}
            onSave={handleAddUserSave}
            updateUsers={getUsers}
          />
        }
      />

      <ModalSideRight
        isOpen={isModalEditOpen && selectedUser !== null}
        toggleModal={toggleModalEdit}
        title="EDIT USER"
        content={
          selectedUser && (
            <User
              user={selectedUser}
              action="edit"
              toggleModal={toggleModalEdit}
              onSave={handleEditSave}
              updateUsers={getUsers}
            />
          )
        }
      />
      
      <ModalCenter
        isOpen={isModalDeleteOpen && selectedUser !== null}
        toggleModal={toggleModalDelete}
        content={
          selectedUser && (
            <ContentConfirm
              toggleModal={toggleModalDelete}
              text="Are you sure you want to delete this user?"
              onHandle={handleDeleteConfirm}
              updateItems={getUsers}
            />
          )
        }
      />
    </>
  );
};

export default Users;