export const categorys = [
  {
    value: "Syndicate",
    text: "Syndicate",
  },
  {
    value: "FullLimitV2",
    text: "FullLimitV2",
  },
  {
    value: "FullLimitV3",
    text: "FullLimitV3",
  },
  {
    value: "AutoLimit",
    text: "AutoLimit",
  },
  {
    value: "AutoLimitBTC",
    text: "AutoLimitBTC",
  },
  {
    value: "BasketV1",
    text: "BasketV1",
  },
  {
    value: "AutoMarket",
    text: "AutoMarket",
  },
  {
    value: "Testing",
    text: "Testing",
  }
]