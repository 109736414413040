import React, { useState, useEffect } from 'react';
import { BsFillTrashFill, BsFillPencilFill, BsChevronDown, BsChevronUp, BsPlusCircleFill, BsClockHistory } from "react-icons/bs";
import { IoSettings } from "react-icons/io5";
import { GrDetach } from "react-icons/gr";
import { Toaster, toast } from 'sonner';
import { useNavigate } from 'react-router-dom';
import NotFound from '../../common/NotFound/index'
import { GetAllPositions, HistoryTransfers } from '../../types/positions'
import { formatCurrency } from '../../utils/formatCurrency'
import { RiRadioButtonLine } from "react-icons/ri";
import { useTransfersHistoryContext } from '../../context/TransfersHistoryContext';
import Pagination from '../../components/Pagination/Pagination'
import CountPagination from './CountPagination'

interface TranStatisticsProps {
  statistics: HistoryTransfers[];
  updateStatistics: (page: number, size: number, from: number, to: number, accounts: string[], bots: string[], symbols: string[]) => void;
  totalCount: number;
  onPageChange: (selectedItem: { selected: number }) => void;
  currentPage: number;
  setStatistics: React.Dispatch<React.SetStateAction<HistoryTransfers[]>>;
}

const TableTranStatistics: React.FC<TranStatisticsProps> = ({
  statistics,
  updateStatistics,
  totalCount,
  onPageChange,
  currentPage,
  setStatistics
}) => {

  const { pageSize } = useTransfersHistoryContext();
  const [expandedRows, setExpandedRows] = useState<string[]>([]);
  const pageCount = Math.ceil((totalCount || 0) / pageSize);

  useEffect(() => {
    // Обновляем таблицу только при изменении statistics
  }, [statistics]);

  const toggleRowExpansion = (id: string) => {
    setExpandedRows(prevExpandedRows =>
      prevExpandedRows.includes(id)
        ? prevExpandedRows.filter(rowId => rowId !== id)
        : [...prevExpandedRows, id]
    );
  };

  return (
    <>
      <Toaster position="bottom-center" richColors />
      <div className="overflow-hidden rounded-[10px]">
        <div className="max-w-full overflow-x-auto">
          <div className="min-w-[1170px]">

            <div className="grid grid-cols-5 gap-4 bg-[#F9FAFB] dark:bg-meta-4 px-5 py-4 lg:px-7.5 2xl:px-11">
              <div className="col-span-1">
                <h5 className="font-medium text-[#637381] dark:text-bodydark text-sm">Main</h5>
              </div>
              <div className="col-span-1">
                <h5 className="font-medium text-[#637381] dark:text-bodydark text-sm">WAY</h5>
              </div>
              <div className="col-span-1">
                <h5 className="font-medium text-[#637381] dark:text-bodydark text-sm">Sub</h5>
              </div>
              <div className="col-span-1">
                <h5 className="font-medium text-[#637381] dark:text-bodydark text-sm">AMOUNT</h5>
              </div>
              <div className="col-span-1">
                <h5 className="font-medium text-[#637381] dark:text-bodydark text-sm">TIME</h5>
              </div>
            </div>

            <div className="bg-white dark:bg-boxdark">

              {statistics && statistics.length === 0 || statistics === null ? (
                <NotFound />
              ) : (statistics && statistics.map((item, key) => {
                const isExpanded = expandedRows.includes(item.id);

                return (
                  <div className="border-t border-[#EEEEEE] dark:border-strokedark" key={key}>
                    <div className="grid grid-cols-5 gap-4 px-5 py-4 lg:px-7.5 2xl:px-11">
                      <div className="col-span-1 items-start">
                        <div className='flex items-center gap-2'>
                          <p className={`text-[#637381] dark:text-bodydark`}>{item?.accountName}</p>
                        </div>
                      </div>
                      <div className="col-span-1 items-start">
                        <div className='flex items-center gap-2'>
                          <p className={`text-[#637381] dark:text-bodydark`}>{item?.transfer.type}</p>
                        </div>
                      </div>
                      <div className="col-span-1 items-start">
                        <div className='flex items-center gap-2'>
                          <p className={`text-[#637381] dark:text-bodydark`}>{item?.transfer.subID}</p>
                        </div>
                      </div>
                      <div className={`col-span-1 flex flex-col items-start justify-start overflow-hidden transition-all duration-300`}>
                        {formatCurrency(item?.transfer.amount)}
                      </div>
                      <div className="col-span-1 flex items-start">
                        {new Date(item?.transfer.createTime).toLocaleString()}
                      </div>
                    </div>
                  </div>
                );
              }))}
            </div>
          </div>
        </div>
      </div>

      {statistics && statistics.length > 0 &&
        <div className='flex flex-wrap items-center gap-4 mt-4'>
          <Pagination
            pageCount={pageCount}
            onPageChange={onPageChange}
            pageRangeDisplayed={1}
            marginPagesDisplayed={1}
          />
          <CountPagination />
        </div>
      }

    </>
  );
};

export default TableTranStatistics;