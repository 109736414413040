import React, { useEffect } from 'react';
import { BsFillTrashFill, BsFillPencilFill, BsPersonFillCheck, BsPersonFillDash } from "react-icons/bs";
import { USER } from '../../types/user';
import { Toaster } from 'sonner';
import NotFound from '../../common/NotFound';
import { useUsers } from '../../hooks/useUsers';
import { UserRoles } from '../../types/user';
import { UsersProps } from './interface';

const TableUsers: React.FC<UsersProps> = ({ users, callbackEdit, callbackDelete }) => {
  const {
    isAdmin,
  } = useUsers();

  useEffect(() => {
    // Обновляем таблицу только при изменении users
  }, [users]);

  const renderRowTableUser = (user: USER, key: number) => {
    return (
      <div className="grid grid-cols-12 gap-4 border-t border-[#EEEEEE] dark:border-strokedark px-5 py-4 lg:px-7.5 2xl:px-11" key={key}>
        <div className="col-span-1 flex items-center">
          <p className="text-[#637381] dark:text-bodydark">{key + 1}</p>
        </div>
        <div className="col-span-2 flex items-center gap-2">
          {user.role === UserRoles.Admin ? <BsPersonFillCheck className='text-xl text-green-700' /> : <BsPersonFillDash className='text-xl text-red-700' />}
          <p className="text-[#637381] dark:text-bodydark">{user.name}</p>
        </div>
        <div className="col-span-2 flex items-center">
          {user?.role === UserRoles.Admin && <p className='inline-flex rounded-full bg-primary py-1 px-3 text-sm font-medium text-white'>{user?.role}</p>}
          {user?.role === UserRoles.Moderator && <p className="inline-flex rounded-full bg-[#637381] py-1 px-3 text-sm font-medium text-white">{user.role}</p>}
          {user?.role === UserRoles.User && <p className="inline-flex rounded-full bg-[#EFEFEF] py-1 px-3 text-sm font-medium text-[#212B36]">{user.role}</p>}
        </div>
        <div className="col-span-3 flex items-center">
          <p className="text-[#637381] dark:text-bodydark">{user.email}</p>
        </div>
        <div className="col-span-2 flex items-center">
          <p className="text-[#637381] dark:text-bodydark">{user.description}</p>
        </div>
        <div className="col-span-1 flex items-center justify-center gap-4">
          {isAdmin() && (
            <>
              <BsFillPencilFill
                className="cursor-pointer duration-300 ease-in-out hover:text-primary"
                onClick={() => callbackEdit(user)}
                title="EDIT USER"
              />

              <BsFillTrashFill
                className="cursor-pointer duration-300 ease-in-out hover:text-primary"
                onClick={() => callbackDelete(user)}
                title="DELETE USER"
              />
            </>
          )}
        </div>
      </div>
    )
  };

  const renderTableUser = (users: USER[]) => {
    return (users.length === 0 && Array.isArray(users) ? (
      <NotFound />
    ) : (
      users.map((user, key) => (
        renderRowTableUser(user, key)
      ))
    ))
  };

  return (
    <>
      <Toaster position="bottom-center" richColors />
      <div className="overflow-hidden rounded-[10px]">
        <div className="max-w-full overflow-x-auto">
          <div className="min-w-[1170px]">

            <div className="grid grid-cols-12 gap-4 bg-[#F9FAFB] px-5 py-4 dark:bg-meta-4 lg:px-7.5 2xl:px-11">
              <div className="col-span-1">
                <h5 className="font-medium text-[#637381] dark:text-bodydark">#</h5>
              </div>
              <div className="col-span-2">
                <h5 className="font-medium text-[#637381] dark:text-bodydark">NAME</h5>
              </div>
              <div className="col-span-2">
                <h5 className="font-medium text-[#637381] dark:text-bodydark">PERMISSION</h5>
              </div>
              <div className="col-span-3">
                <h5 className="font-medium text-[#637381] dark:text-bodydark">EMAIL</h5>
              </div>
              <div className="col-span-2">
                <h5 className="font-medium text-[#637381] dark:text-bodydark">COMMENT</h5>
              </div>
              <div className="col-span-1">
                <h5 className="font-medium text-[#637381] dark:text-bodydark">ACTIONS</h5>
              </div>
            </div>

            <div className="bg-white dark:bg-boxdark">
              { renderTableUser(users) }
            </div>

          </div>
        </div>
      </div>
    </>
  );
};

export default TableUsers;