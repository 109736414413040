import React, { useState, useEffect, useRef } from 'react';
import SimpleSelect from "../../components/Forms/Select/SimpleSelect";
import InputField from '../../components/Forms/Inputs/InputField';
import BtnPrimary from '../../components/Buttons/BtnPrimary';
import { FaMoneyBillTransfer } from "react-icons/fa6";
import { KEY, GetApiKeysAll, InfoBingX } from '../../types/key';
import { ApiResponse } from '../../types/api';
import api from '../../utils/api';
import { Toaster, toast } from 'sonner';
import { formatCurrency } from '../../utils/formatCurrency';
import { useApiKeysContext } from '../../context/ApiKeysContext';
import { ManagementAccounts, Account } from './interface';
import { BiTransfer } from "react-icons/bi";
import { TbPointFilled } from "react-icons/tb";
import { ContentModalCenterProps, requestTrasferManagement } from './interface';
import { messages } from '../../data/messages';

const ContentModalSend: React.FC<ContentModalCenterProps> = ({ toggleModal, accFrom, onHandle, accounts }) => {
  let sName = ""
  if (accFrom.subAccounts.length > 0) {
    sName = accFrom.subAccounts[0].account.name
  }
  const [mainID, setMainID] = useState<string>(accFrom.id);
  const [subID, setSubID] = useState<string>(sName);
  const [selectWay, setSelectWay] = useState<string>('1');
  const [selectFrom, setSelectFrom] = useState<string>('18');
  const [selectTo, setSelectTo] = useState<string>('18');
  const [amount, setAmount] = useState<number>(0);

  const [selectedAcc, setSelectedAcc] = useState<string | null>(null);
  const [toAcc, setToAcc] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const isKeysFetchedRef = useRef(false);

  const handleSubIDChange = (value: string) => {
    setSubID(value);
  };

  const handleFromChange = (value: string) => {
    setSelectFrom(value);
  };

  const handleToChange = (value: string) => {
    setSelectTo(value);
  };

  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setAmount(parseFloat(value));
  };

  const handlerWayAccount = () => {
    if (selectWay === '1') {
      setSelectWay('2')
    } else if (selectWay === '2') {
      setSelectWay('1')
    }
  };

  const handlerMaxAmount = (maxAmount: number) => {
    setAmount(maxAmount)
  };


  const transSubAccounts = (subs: Account[]) => {
    const t = subs.map(acc => ({
      value: acc.account.name,
      text: `${acc.account.name} (${formatCurrency(acc.valuation.totalBalance)}$)`,
    }))
    return t;
  };

  const transAccountOptions = (w: string) => {
    if (w == "from") {
      if (selectWay == "1") {
        return [
          {
            value: "6",
            text: `Funding account ${formatCurrency(accFrom.valuation.fundingBalance)}$`
          },
          {
            value: "18",
            text: `Trading account ${formatCurrency(accFrom.valuation.tradingBalance)}$`
          },
        ]
      } else {
        const f = accFrom.subAccounts
          .filter(acc => acc.account.name == subID)

        if (f.length == 0) {
          return []
        }

        return [
          {
            value: "6",
            text: `Funding account ${formatCurrency(f[0].valuation.fundingBalance)}$`
          },
          {
            value: "18",
            text: `Trading account ${formatCurrency(f[0].valuation.tradingBalance)}$`
          },
        ]
      }
    } else {
      if (selectWay == "1") {
        const f = accFrom.subAccounts
          .filter(acc => acc.account.name == subID)

        if (f.length == 0) {
          return []
        }

        return [
          {
            value: "6",
            text: `Funding account ${formatCurrency(f[0].valuation.fundingBalance)}$`
          },
          {
            value: "18",
            text: `Trading account ${formatCurrency(f[0].valuation.tradingBalance)}$`
          },
        ]
      } else {
        return [
          {
            value: "6",
            text: `Funding account ${formatCurrency(accFrom.valuation.fundingBalance)}$`
          },
          {
            value: "18",
            text: `Trading account ${formatCurrency(accFrom.valuation.tradingBalance)}$`
          },
        ]
      }
    }
  }

  const handleTransfer = async () => {
    let max = maxMoney();
    if (parseFloat(amount.toString()) > max) {
      toast.error(messages.transfers.maxAmount);
      setAmount(max)
      return;
    }

    try {
      const response = await api.post(`/api/management/transfer`, {
        mainID: mainID,
        subID: subID,
        way: selectWay,
        from: selectFrom,
        to: selectTo,
        amount: parseFloat(amount.toString()),
      });

      if (response.data.code === 404) {
        return;
      }

      if (response.data.code === 200 && response.data.error === null) {
        toast.success(messages.transfers.failed);
        onHandle(true);
        toggleModal();
      } else {
        toast.error(response.data.error);
        setError(messages.transfers.failed);
        onHandle(false);
      }
    } catch (error) {
      setError(messages.transfers.failed);
      onHandle(false);
    }
  };

  const maxMoney = () => {
    if (selectWay === "1") {
      if (selectFrom == "18") {
        return accFrom?.valuation?.tradingBalance;
      } else if (selectFrom == "6") {
        return accFrom?.valuation?.fundingBalance;
      }
    } else if (selectWay === "2") {
      if (selectFrom == "18") {
        return accFrom.subAccounts
          .filter((item, i) => { return item.account.label === subID })[0]?.valuation.tradingBalance
      } else if (selectFrom == "6") {
        return accFrom.subAccounts
          .filter((item, i) => { return item.account.label === subID })[0]?.valuation.fundingBalance;
      }
    }
    return 0;
  };

  return (
    <>
      <p className="mb-6 text-black dark:text-white">Transfer</p>

      <div className="flex flex-row items-center gap-3">
        {
          selectWay === '1' ?
            <SimpleSelect
              label={`From Main`}
              options={[{
                value: accFrom.id,
                text: accFrom.name,
              }]}
              value={mainID}
              onChange={() => { }}
              className="w-full mb-4 text-left"
            />
            :
            <SimpleSelect
              label={`From Sub`}
              options={transSubAccounts(accFrom.subAccounts)}
              value={subID}
              onChange={handleSubIDChange}
              className="w-full mb-4 text-left"
            />
        }


        <button
          className='flex items-center justify-self-start gap-2 transition duration-300 ease-in-out rounded-lg py-1 px-1 font-medium hover:bg-opacity-90 bg-transparent text-white w-auto'
          onClick={handlerWayAccount}
        >
          <BiTransfer className="shrink-0 mt-2" />
        </button>

        {
          selectWay === '2' ?
            <SimpleSelect
              label={`To Main`}
              options={[{
                value: accFrom.id,
                text: accFrom.name,
              }]}
              value={mainID}
              onChange={() => { }}
              className="w-full mb-4 text-left"
            />
            :
            <SimpleSelect
              label={`To Sub`}
              options={transSubAccounts(accFrom.subAccounts)}
              value={subID}
              onChange={handleSubIDChange}
              className="w-full mb-4 text-left"
            />
        }
      </div>

      <div className="flex flex-row items-center gap-3">
        <SimpleSelect
          label={selectFrom == "18" ? "Trading account" : "Funding account"}
          options={transAccountOptions("from")}
          value={selectFrom}
          onChange={handleFromChange}
          className="w-full mb-4 text-left"
        />
        <SimpleSelect
          label={selectTo == "18" ? "Trading account" : "Funding account"}
          options={transAccountOptions("to")}
          value={selectTo}
          onChange={handleToChange}
          className="w-full mb-4 text-left"
        />
      </div>

      <div className="relative">
        <InputField
          label="Amount"
          value={amount}
          onChange={handleAmountChange}
          placeholder="Amount"
          className="mb-1 text-left"
          type="number"
          step="any"
        />
        <BtnPrimary
          className='absolute top-[45%] left-auto right-4 transform-[translateY(-20px)] justify-center py-1 px-1.5 dark:disabled:bg-black dark:border-form-strokedark dark:bg-form-input'
          style="outline"
          onClick={() => { handlerMaxAmount(maxMoney()) }}
        >max</BtnPrimary>
      </div>

      <p className="text-xs text-left mb-4">
        Accessibly:&nbsp;
        <span className="text-black dark:text-white">
          {maxMoney()} USDT
        </span>
      </p>

      <BtnPrimary
        className='justify-center w-full'
        onClick={handleTransfer}
        disabled={!mainID || !subID || !selectFrom || !selectTo || !amount}
      >
        <FaMoneyBillTransfer /> Transfer USDT
      </BtnPrimary>
    </>
  );
};

export default ContentModalSend;