import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import api from '../../utils/api';
import {
  Reports_struct,
  ReportDetails_struct,
  ReportDetails_struct_tmp,
  BotSettingsDetails_struct,
  Report_struct,
} from '../../types/report';
import { formatCurrency } from '../../utils/formatCurrency';
import Loader from '../../common/Loader';

interface DetailsReportProps {
  toggleModal: () => void;
  report?: Reports_struct;
  idReport?: string;
  // updateTasks?: () => void;
  // onSave?: (result: boolean) => void;
  // action: string;
}

const formatToProc = (num: any) => {
  return (num * 100).toFixed(2)
}

const secondsToHms = function (d: any) {
  d = Number(d);
  var day = Math.floor(d / 86400);
  var h = Math.floor(d % 86400 / 3600);
  var m = Math.floor(d % 3600 / 60);
  var s = Math.floor(d % 3600 % 60);

  var dDisplay = day > 0 ? day + (day == 1 ? " day, " : " days, ") : "";
  var hDisplay = h > 0 ? h + (h == 1 ? " h, " : " h, ") : "";
  var mDisplay = m > 0 ? m + (m == 1 ? " min, " : " min, ") : "";
  var sDisplay = s > 0 ? s + (s == 1 ? " sec" : " sec") : "";
  return dDisplay + hDisplay + mDisplay + sDisplay;
}

const DetailsReport: React.FC<DetailsReportProps> = ({ toggleModal, report, idReport }) => {
  // const { idReport } = useParams<{ idReport: string }>();
  const [reportData, setReportData] = useState<ReportDetails_struct | null>(null);
  const [periodsList, setPeriodsList] = useState<Report_struct[] | undefined>([])
  const [activePeriod, setActivePeriod] = useState<string>("all")
  const [visiblePeriodData, setVisiblePeriodData] = useState<Report_struct>()

  const [settings, setSettings] = useState<BotSettingsDetails_struct>()
  const [info, setInfo] = useState<Report_struct>()


  useEffect(() => {
    console.log(report);
    const fetchReportData = async () => {
      try {
        const response = await api.get<ReportDetails_struct_tmp>(`/benchmark-api/reports/full/${idReport}`);
        // console.log(response.data.result);

        setReportData(response.data.result);
        setPeriodsList(reportData?.report?.bots[0]?.reports)
        setVisiblePeriodData(reportData?.report?.total)
        setSettings(response.data.result?.report.bots[0].settings)
        setInfo(response.data.result?.report.total)

      } catch (error) {
        console.error('Error fetching report data:', error);
      }
    };

    fetchReportData();
  }, [idReport]);

  if (!reportData) {
    return <Loader />;
  }

  return (
    <div>
      <h2 className="text-title-md2 font-semibold text-center text-black dark:text-white mb-4">Details Report</h2>
      <div className='grid gap-4 grid-cols-1 md:grid-cols-3'>
        <section className="col-span-1 md:col-span-3 bg-white dark:bg-boxdark p-4 rounded-[10px]">
          <h3 className="text-left text-title-md font-semibold text-black dark:text-white mb-4">Periods List</h3>
          <ul className="flex items-center flex-wrap gap-5">
            <li
              key="periods-all"
              className={`px-2 py-1 border rounded-[8px]  ${activePeriod === "all" ? 'border-primary bg-primary cursor-default' : 'cursor-pointer'}`}
              onClick={(e) => {
                setActivePeriod("all");
                setInfo(reportData?.report.total)
                setVisiblePeriodData(reportData?.report?.total)
              }}
            >
              <div className={`text-xs text-black dark:text-white ${activePeriod === "all" ? "text-white dark:text-white" : null}`}>All</div>
            </li>
            {
              reportData !== undefined &&
              reportData?.report.bots.map((bot, index) => {
                return (
                  bot.reports?.map((item, i) => (
                    activePeriod === i.toString() ?
                      <li
                        key={"periods" + i}
                        className={`px-2 py-1 border rounded-[8px]  ${activePeriod === i.toString() ? 'border-primary bg-primary cursor-default' : 'cursor-pointer'}`}
                      >
                        <div className={`text-xs text-black dark:text-white ${activePeriod === "all" ? "text-white dark:text-white" : null}`}>{item.period}</div>
                      </li>
                      :
                      <li
                        key={"periods" + i}
                        className={`px-2 py-1 border rounded-[8px]  ${activePeriod === i.toString() ? 'border-primary bg-primary cursor-default' : 'cursor-pointer'}`}
                        onClick={(e) => {
                          setActivePeriod(i.toString());
                          if (periodsList !== undefined) {
                            setVisiblePeriodData(periodsList[i])
                          }
                          setInfo(item)
                          // console.log(activePeriod)
                        }}
                      >
                        <div className={`text-xs text-black dark:text-white ${activePeriod === "all" ? "text-white dark:text-white" : null}`}>{item.period}</div>
                      </li>
                  ))
                )
              })
            }
          </ul>
        </section>
        <section className="bg-white dark:bg-boxdark p-4 rounded-[10px]">
          <h3 className="text-title-md font-semibold text-black dark:text-white mb-4">Settings</h3>
          <div className="">
            <div className="" >
              <div className={`px-2 py-1`}>
                <p className={`text-md text-black dark:text-white`}>StartBalance: {formatCurrency(settings?.startBalance)}</p>
              </div>
              <div className={`px-2 py-1`}>
                <p className={`text-md text-black dark:text-white`}>Init: {settings?.init} {settings?.isInitProcent ? "%" : "$"}</p>
              </div>
              <div className={`px-2 py-1`}>
                <p className={`text-md text-black dark:text-white`}>InitAVG: {settings?.initAVG} {settings?.isInitAVGProcent ? "%" : "$"}</p>
              </div>
              <div className={`px-2 py-1`}>
                <p className={`text-md text-black dark:text-white`}>CountAVG: {settings?.countAVG}</p>
              </div>
              <div className={`px-2 py-1`}>
                <p className={`text-md text-black dark:text-white`}>FirstProcentAVG: {settings?.firstProcentAVG}</p>
              </div>
              <div className={`px-2 py-1`}>
                <p className={`text-md text-black dark:text-white`}>MultimetrStep: {settings?.multimetrStep}</p>
              </div>
              <div className={`px-2 py-1`}>
                <p className={`text-md text-black dark:text-white`}>MultimetrVolume: {settings?.multimetrVolume}</p>
              </div>
              <div className={`px-2 py-1`}>
                <p className={`text-md text-black dark:text-white`}>TakeProfit: {settings?.takeProfit}</p>
              </div>
              <div className={`px-2 py-1`}>
                <p className={`text-md text-black dark:text-white`}>LessRSI: {settings?.lessRSI}</p>
              </div>
              <div className={`px-2 py-1`}>
                <p className={`text-md text-black dark:text-white`}>AboveRSI: {settings?.aboveRSI}</p>
              </div>
              <div className={`px-2 py-1`}>
                <p className={`text-md text-black dark:text-white`}>TimeFrame: {settings?.tFString}</p>
              </div>
              <div className={`px-2 py-1`}>
                <p className={`text-md text-black dark:text-white`}>LengthRSI: {settings?.lengthRSI}</p>
              </div>
            </div>
          </div>
        </section>
        <section className="bg-white dark:bg-boxdark p-4 rounded-[10px]">
          <h3 className="text-title-md font-semibold text-black dark:text-white mb-4">Account Results</h3>
          <div className="888">
            <div className={`px-2 py-1`}>
              <p className={`text-md text-black dark:text-white`}>Account Balance: {formatCurrency(info?.balance)}</p>
            </div>
            <div className={`px-2 py-1`}>
              <p className={`text-md text-black dark:text-white`}>RealizedProfit: {formatCurrency(info?.realizedProfit)}</p>
            </div>
            <div className={`px-2 py-1`}>
              <p className={`text-md text-black dark:text-white`}>UnrealizedProfit NOW: {formatCurrency(info?.unrealizedProfit)}</p>
            </div>
            <div className={`px-2 py-1`}>
              <p className={`text-md text-black dark:text-white`}>MaxDrawDown: {formatToProc(info?.maxDrawDown)}%</p>
            </div>
            <div className={`px-2 py-1`}>
              <p className={`text-md text-black dark:text-white`}>Max AVG LONG: {info?.maxAVGLONG}</p>
            </div>
            <div className={`px-2 py-1`}>
              <p className={`text-md text-black dark:text-white`}>Max AVG SHORT: {info?.maxAVGSHORT}</p>
            </div>
            {/* <div className={`px-2 py-1`}>
              <p className={`text-md text-black dark:text-white`}>Min Time in Positions: {secondsToHms(reportData?.report?.total?.timeInPositionsMin)}</p>
            </div> */}
            <div className={`px-2 py-1`}>
              <p className={`text-md text-black dark:text-white`}>Max Time in Positions: {secondsToHms(reportData?.report?.total?.timeInPositionsMax)} </p>
            </div>
            <div className={`px-2 py-1`}>
              <p className={`text-md text-black dark:text-white`}>AVG Time in Positions:{secondsToHms(reportData?.report?.total?.timeInPositions)}</p>
            </div>
          </div>
        </section>
        <section className="bg-white dark:bg-boxdark p-4 rounded-[10px]">
          <h3 className="text-title-md font-semibold text-black dark:text-white mb-4">Statistics</h3>
          <div className={`px-2 py-1`}>
            <p className={`text-md text-black dark:text-white`}>Count Orders LONG: {info?.countOrdersLONG}</p>
          </div>
          <div className={`px-2 py-1`}>
            <p className={`text-md text-black dark:text-white`}>Count Orders SHORT: {info?.countOrdersSHORT}</p>
          </div>
          <div className={`px-2 py-1`}>
            <p className={`text-md text-black dark:text-white`}>Volume: {formatCurrency(info?.volume)}</p>
          </div>
          <div className={`px-2 py-1`}>
            <p className={`text-md text-black dark:text-white`}>Max. Position LONG: {formatCurrency(info?.maxPositionLONG)}</p>
          </div>
          <div className={`px-2 py-1`}>
            <p className={`text-md text-black dark:text-white`}>Max. Position SHORT: {formatCurrency(info?.maxPositionSHORT)}</p>
          </div>
          <div className={`px-2 py-1`}>
            <p className={`text-md text-black dark:text-white`}>Max. Plus PnL LONG: {formatCurrency(info?.maxProfitPnlLONG)}</p>
          </div>
          <div className={`px-2 py-1`}>
            <p className={`text-md text-black dark:text-white`}>Max. Plus PnL SHORT: {formatCurrency(info?.maxProfitPnlSHORT)}</p>
          </div>
          <div className={`px-2 py-1`}>
            <p className={`text-md text-black dark:text-white`}>Max. Minus PnL LONG: {formatCurrency(info?.maxLosePnlLONG)}</p>
          </div>
          <div className={`px-2 py-1`}>
            <p className={`text-md text-black dark:text-white`}>Max. Minus PnL SHORT: {formatCurrency(info?.maxLosePnlSHORT)}</p>
          </div>
        </section>
      </div>
    </div>
  )
};

export default DetailsReport;