import React, { useContext, useState, useCallback,useEffect, useRef } from 'react';
import { useBotsContext } from '../../../context/BotsAllContext';
import MultipleSelect from '../../../components/Forms/Select/MultiSelect';
import SimpleSelect from '../../../components/Forms/Select/SimpleSelect';
import { toast } from 'sonner';
import DatePicker from '../../../components/Forms/DatePicker';
import { BsFunnelFill, BsXCircleFill } from "react-icons/bs";
import BtnPrimary from '../../../components/Buttons/BtnPrimary';
import { KEY, GetApiKeysAll } from '../../../types/key'
import api from '../../../utils/api';
import { ApiResponse } from '../../../types/api'
import { Db_Bots } from '../../../types/botsAll'
import { Db_Symbols } from '../../../types/symbols'
import CheckboxInput from '../../../components/Forms/Inputs/CheckboxInput';
import InputField from '../../../components/Forms/Inputs/InputField';

interface BotsAllFilterProps {
  toggleModal: () => void;
}

const BotsAllFilter: React.FC<BotsAllFilterProps> = ({ toggleModal }) => {
  const {
    // keysAll,
    currentPage,
    pageSize,
    // fetchKeys,
    // fetchKeysFiltered,
    selectedMarket,
    setSelectedMarket,
    selectedFavorite,
    setSelectedFavorite,
    // selectedBalance,
    // setSelectedBalance,
    // selectedPositions,
    // setSelectedPositions,
    selectedName,
    setSelectedName,
    selectedExchange,
    setSelectedExchange,
    getBotsAll,
    selectedApiAttached,
    setSelectedApiAttached,
  } = useBotsContext();

  const markets = [
    {
      value: "futures",
      text: "Futures",
    },
    {
      value: "spot",
      text: "Spot",
    },
  ];

  const exchangers = [
    {
      value: "Binance",
      text: "Binance",
    },
    {
      value: "BingX",
      text: "BingX",
    },
    {
      value: "Bybit",
      text: "Bybit",
    },
    {
      value: "Gate",
      text: "Gate",
    },
    {
      value: "Mexc",
      text: "Mexc",
    },
    {
      value: "Bitget",
      text: "Bitget",
    },
    {
      value: "OKX",
      text: "OKX",
    },
    {
      value: "Huobi",
      text: "Huobi",
    },
  ];
  
  const [market, setMarket] = useState<string>(selectedMarket || markets[0].value)
  const [favorite, setFavorite] = useState<boolean>(selectedFavorite)
  const [searchName, setSearchName] = useState(selectedName)
  const [exchange, setExchange] = useState(selectedExchange || exchangers[0].value)
  const [apiAttached, setApiAttached] = useState(selectedApiAttached)

  const handleMarketChange = (value: string) => {
    setMarket(value);
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchName(value)
  }

  const handleExchangeChange = (value: string) => {
    setExchange(value);
  }

  const handleApplyFilter = () => {
    const filters = {
      // "active": true,
      "favorite": favorite,
      "exchange": [exchange],
      "search": searchName,
      "apiAttached": apiAttached,
      "market": market
    };
    setSelectedName(searchName);
    setSelectedMarket(market);
    setSelectedFavorite(favorite);
    setSelectedExchange(exchange);
    setSelectedApiAttached(apiAttached);
    // fetchKeys(currentPage, pageSize, filters)
    getBotsAll(currentPage, pageSize, filters);
    toggleModal();
  };

  return (
    <div className="flex flex-col h-full">
      <div className="flex-grow mb-4">
        {/* <SimpleSelect
          label="Exchangers"
          options={exchangers}
          value={exchanger}
          onChange={handleExchangerChange}
          className="w-full mb-4"
        /> */}
        <SimpleSelect
          label="Market"
          options={markets}
          value={market}
          onChange={handleMarketChange}
          className="w-full mb-4"
        />

        <SimpleSelect
          label="Exchanger"
          options={exchangers}
          value={exchange}
          onChange={handleExchangeChange}
          className='w-full mb-4'
        />

        <InputField
          label="Name"
          value={searchName}
          onChange={handleNameChange}
          placeholder="Search by name..."
          className="mb-4"
        />

        <div className='flex flex-col gap-2 mb-4'>
          {
            favorite ?
            <CheckboxInput
              label="Show favourite api keys"
              value=""
              id="favorite"
              checked
              onChange={() => { setFavorite(!favorite)}}
              className=""
            />
            :
            <CheckboxInput
              label="Show favourite api keys"
              value=""
              id="favorite"
              onChange={() => { setFavorite(!favorite)}}
              className=""
            />
          }

          {
            apiAttached ?
            <CheckboxInput
              label="Only with linked api keys"
              value=""
              checked
              id="attached"
              onChange={() => { setApiAttached(!apiAttached)}}
              className=""
            />
            :
            <CheckboxInput
              label="Only with linked api keys"
              value=""
              id="attached"
              onChange={() => { setApiAttached(!apiAttached)}}
              className=""
            />
          }
          
          {/* {
            positions ?
            <CheckboxInput
              label="Show api keys with filled positions"
              value=""
              checked
              id="positions"
              onChange={() => { setPositions(!positions)}}
              className=""
            />
            :
            <CheckboxInput
              label="Show api keys with filled positions"
              value=""
              id="positions"
              onChange={() => { setPositions(!positions)}}
              className=""
            />
          } */}
        </div>
      </div>

      <div className='flex items-center justify-between gap-4 mt-auto'>
        <BtnPrimary
          className='w-full'
          onClick={handleApplyFilter}>
          <BsFunnelFill /> Apply
        </BtnPrimary>
        <BtnPrimary
          className='w-full'
          style="outline"
          onClick={toggleModal}>
          <BsXCircleFill /> Close
        </BtnPrimary>
      </div>

    </div>
  );
};

export default BotsAllFilter;