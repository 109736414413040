import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import api from '../utils/api';
import { ApiResponse } from '../types/api';
import { Db_Bots } from '../types/botsAll';
import { ManagementAccounts, AccountsContextProps, GetApiAccountsAll } from '../pages/AccountsManagement/interface';

const AccountsManagementContext = createContext<AccountsContextProps | undefined>(undefined);

export const useAccountsManagementContext = () => {
  const context = useContext(AccountsManagementContext);
  if (!context) {
    throw new Error('useAccountsManagementContext must be used within a AccountsProvider');
  }
  return context;
};

export const AccountsProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [accounts, setAccounts] = useState<ManagementAccounts[]>([]);
  const [selectedAccount, setSelectedAccount] = useState<ManagementAccounts | null>(null);
  const [isModalActionsOpen, setIsModalActionsOpen] = useState(false);
  const [isModalTransfer, setIsModalTransfer] = useState(false);
  const [isModalAddTask, setIsModalAddTask] = useState(false);
  
  const [rend, setRend] = useState(false);
  
  const toggleModalActions = () => setIsModalActionsOpen(!isModalActionsOpen);
  const toggleModalTransfer = () => setIsModalTransfer(!isModalTransfer);
  const toggleModalAddTask = () => setIsModalAddTask(!isModalAddTask);

  const fetchAccounts = async () => {
    console.log("fetchAccounts start")
    // Object.keys(filters).length === 0 ? setIsVisibleResetFilter(true) : setIsVisibleResetFilter(false);
    try {
      const response = await api.get<ApiResponse<ManagementAccounts[]>>(`/api/management/accounts`, {
        // "filters": filters,
        // "pagination": {
        //   "page": page + 1,
        //   "limit": pageSize
        // }
      }
      );
      console.log("1", response, response.data.code, response.data.result)
      // await new Promise(resolve => setTimeout(resolve, 500));
      if (response.data.code == 200 && response.data.result) {
        setAccounts(response.data.result);
        // debugger
      } else {
        setAccounts([]);


      }
    } catch (error) {
      console.error('Error fetching accounts:', error);
    }
  };

  useEffect(() => {
    console.log("useEffect")
    fetchAccounts();
    console.log("useEffect finish")

    // setTimeout(() => {
    //   setRend(!rend)
    //   console.log("setTimeout")
    // }, 5000);
  }, []);

  const handleActionsAccount = (selectedAccount: ManagementAccounts) => {
    setSelectedAccount(selectedAccount);
    toggleModalActions();
  };

  const handleTransferOpen = (selectedAccount: ManagementAccounts) => {
    setSelectedAccount(selectedAccount);
    toggleModalTransfer();
  };

  const handleTransfer = async (result: boolean) => {
    if (result && selectedAccount) {
      await fetchAccounts();
    }
    if (!result) return;
    toggleModalTransfer();
  };

  const handleAddTaskOpen = (selectedAccount: ManagementAccounts) => {
    setSelectedAccount(selectedAccount);
    toggleModalAddTask();
  };

  const handleAddTask = async (result: boolean) => {
    if (result && selectedAccount) {
      // await fetchAccounts();
    }
    if (!result) return;
    toggleModalAddTask();
  };

  // const fetchAccounts = useCallback(async () => {
  //   // Object.keys(filters).length === 0 ? setIsVisibleResetFilter(true) : setIsVisibleResetFilter(false);
  //   try {
  //     const accessToken = localStorage.getItem('accessToken');
  //     const response = await api.get<ApiResponse<ManagementAccounts[]>>(`/api/management/accounts`, {
  //       // "filters": filters,
  //       // "pagination": {
  //       //   "page": page + 1,
  //       //   "limit": pageSize
  //       // }
  //     }
  //     );
  //     console.log("1", response)
  //     await new Promise(resolve => setTimeout(resolve, 500));
  //     if (response.data.code == 200 && response.data.result) {
  //       setAccounts(response.data.result);
  //       // debugger
  //     } else {
  //       setAccounts([]);
  //     }
  //   } catch (error) {
  //     console.error('Error fetching accounts:', error);
  //   }
  // }, [api, setAccounts]);

  const contextValue = {
    // botsAll,
    // isLoading,
    // error,
    accounts,
    setAccounts,
    selectedAccount,
    fetchAccounts,
    handleActionsAccount,
    isModalActionsOpen,
    toggleModalActions,
    handleTransferOpen,
    isModalTransfer,
    toggleModalTransfer,
    handleTransfer,
    handleAddTaskOpen,
    isModalAddTask,
    toggleModalAddTask,
    handleAddTask,
  };

  return (
    <AccountsManagementContext.Provider value={contextValue}>
      {children}
    </AccountsManagementContext.Provider>
  );
};