import React, { useState, useContext, useEffect, useMemo } from 'react';
import { BsFillPencilFill, BsFillTrashFill, BsExclamationCircle, BsStar, BsStarFill, BsFillChatFill, BsEyeFill, BsThreeDotsVertical, BsFileBarGraphFill, BsPauseBtn, BsPlayBtnFill } from "react-icons/bs";
import { MdCopyAll } from "react-icons/md";
import ModalSideRight from '../Modals/ModalSideRight';
import ModalCenter from '../Modals/ModalCenter';
import Task from '../../pages/Benchmark/Task';
import { TASK } from '../../types/task';
import { Toaster, toast } from 'sonner';
import { UserContext } from '../../context/UserContext'; 
import api from '../../utils/api'
import Pagination from '../../components/Pagination/Pagination'; 
import Comment from '../../pages/Benchmark/Comment/Comment';
import { useNavigate } from 'react-router-dom'; 
import NotFound from '../../common/NotFound'
import { formatDate } from '../../utils/formatDate'
import { ApiResponse } from '../../types/api';
import ContentConfirm from '../Modals/ContentConfirm';
import { formatTimeUTC } from '../../utils/formatTimeUTC';
import { formatDateUTC } from '../../utils/formatDateUTC';

interface TasksProps {
  tasks: TASK[];
  totalCount: number;
  fetchTasks: (page: number) => void;
  updateTasks: () => void;
  addToFavorites: (taskId: string) => Promise<void>;
  removeFromFavorites: (taskId: string) => Promise<void>;
  onPageChange: (selectedItem: { selected: number }) => void;
}

const TableTasks: React.FC<TasksProps> = ({ tasks, totalCount, fetchTasks, updateTasks, addToFavorites, removeFromFavorites, onPageChange }) => {
  const { user } = useContext(UserContext);
  const [isModalCommentOpen, setIsModalCommentOpen] = useState(false)
  const [isModalCloneOpen, setIsModalCloneOpen] = useState(false); 
  const [isModalShowOpen, setIsModalShowOpen] = useState(false);
  const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);
  const [isModalChangeStatusTaskOpen, setIsModalChangeStatusTaskOpen] = useState(false);
  const [selectedTask, setSelectedTask] = useState<TASK | null>(null);
  const [currentPage, setCurrentPage] = useState(0);
  const tasksPerPage = 10; 
  const pageCount = Math.ceil((totalCount || 0) / tasksPerPage);
  const navigate = useNavigate();

  // const periodStartFinish = (task: TASK) => {
  //   const strStart = task.started_at == 0 ? "-" : (formatTimeUTC(new Date(task.started_at)) + " " + formatDateUTC(new Date(task.started_at)));
  //   const strFinish = task.finished_at == 0 ? "-" : (formatTimeUTC(new Date(task.finished_at)) + " " + formatDateUTC(new Date(task.finished_at)));
  //   return `${strStart} /\r\n ${strFinish}` 
  // }

  const tasksArray = useMemo(() => tasks || [], [tasks]);

  useEffect(() => {
    fetchTasks(currentPage);
  }, [currentPage]);

  const toggleModalDelete = () => {
    setIsModalDeleteOpen(!isModalDeleteOpen);
  };

  const toggleModalShow = () => {
    setIsModalShowOpen(!isModalShowOpen);
  };

  const toggleModalComment = () => {
    setIsModalCommentOpen(!isModalCommentOpen);
  };

  const toggleModalClone = () => {
    setIsModalCloneOpen(!isModalCloneOpen);
  };

  const toggleModalChangeStatusTask = () => {
    setIsModalChangeStatusTaskOpen(!isModalChangeStatusTaskOpen);
  };

  const handleShowTask = (task: TASK) => {
    setSelectedTask(task);
    toggleModalShow();
  };

  const handleCloneTask = (task: TASK) => {
    setSelectedTask(task);
    toggleModalClone();
  };

  const handleCommentTask = (task: TASK) => {
    setSelectedTask(task);
    console.log("selectedTask.id", selectedTask);
    toggleModalComment();
  };

  const handleDeleteTask = (task: TASK) => {
    setSelectedTask(task);
    toggleModalDelete();
  };

  const handleChangeStatusTaskConfirm = async (selectedTask: TASK) => {
    try {
      let newStatus: string = "";

      switch(selectedTask.status) {
        case "PAUSE": 
          newStatus = "WAIT";    /** play */
          break;
        case "RUN":
          newStatus = "PAUSE";  /** pause */
          break;
      }
      const updateData = {
        status: newStatus
      };

      await api.put<ApiResponse<string>>(`/benchmark-api/tasks/${selectedTask.id}`, updateData);

      updateTasks();
      toast.success('Task update successfully');
    } catch (error) {
      toast.error('Failed to update task');
    }
  };

  const handleCloneSave = (result: boolean) => {
    if (result) {
      toast.success('Task clone successfully');
      updateTasks()
    } else {
      toast.error('Failed to clone task');
    }
  };

  const handleDeleteConfirm = async (result: boolean) => {
    if (result && selectedTask) {
      try {
        await api.delete<ApiResponse<string>>(`/benchmark-api/tasks/${selectedTask.id}`);

        updateTasks();
        toast.success('Task delete successfully');
      } catch (error) {
        toast.error('Failed to delete task');
      }
    }
    toggleModalDelete();
  };

  const handlePageChange = (selectedItem: { selected: number }) => {
    setCurrentPage(selectedItem.selected);
    onPageChange(selectedItem);
  };

  const handleReportClick = (taskId: string) => {
    navigate(`/report/${taskId}`);
  };

  const handleStatusTaskChange = (task: TASK) => {
    setSelectedTask(task);
    toggleModalChangeStatusTask()
  }

  return (
    <>
      <Toaster position="bottom-center" richColors />

      <div className='overflow-hidden rounded-[10px]'>
        <div className='max-w-full overflow-x-auto'>
          <div className='min-w-[1170px]'>

            <div className='grid grid-cols-12 gap-4 bg-[#F9FAFB] px-5 py-4 dark:bg-meta-4 lg:px-7.5 2xl:px-11'>
              {/* <div className="col-span-1">
                <h5 className="font-medium text-[#637381] dark:text-bodydark">#</h5>
              </div> */}
              <div className="col-span-2">
                <h5 className="font-medium text-[#637381] dark:text-bodydark">NAME</h5>
              </div>
              <div className="col-span-2">
                <h5 className="font-medium text-center text-[#637381] dark:text-bodydark">STATUS</h5>
              </div>
              <div className="col-span-2">
                <h5 className="font-medium text-[#637381] dark:text-bodydark">TIME</h5>
              </div>
              <div className="col-span-2">
                <h5 className="font-medium text-[#637381] dark:text-bodydark">COPMLETE/TOTAL</h5>
              </div>
              <div className="col-span-3">
                <h5 className="font-medium text-[#637381] dark:text-bodydark">COMMENTS</h5>
              </div>
              <div className="col-span-1">
                <h5 className="font-medium text-[#637381] dark:text-bodydark">ACTIONS</h5>
              </div>
            </div>

            <div className='bg-white dark:bg-boxdark'>
              {tasksArray.length === 0 || tasksArray == null ? (
                <NotFound />
              ) : (
                tasksArray.map((task, key) => (
                  <div className='grid grid-cols-12 gap-4 border-t border-[#EEEEEE] px-5 py-4 dark:border-strokedark lg:px-7.5 2xl:px-11' key={key}>
                    {/* <div className="col-span-1">
                      <div className="flex gap-3 items-center">
                        <p className="text-[#637381] dark:text-bodydark">{currentPage * tasksPerPage + key + 1}</p>
                      </div>
                    </div> */}
                    <div className="col-span-2">
                      <p className="text-[#637381] dark:text-bodydark">{task.name}</p>
                    </div>
                    <div className="col-span-2 flex gap-3 justify-center items-center">
                      <p className="text-[#637381] dark:text-bodydark">{task.status} </p>
                      {task.status === "RUN" ?
                        <BsPauseBtn
                          className="cursor-pointer"
                          title="Pause"
                          onClick={() => {
                            handleStatusTaskChange(task);
                          }}
                        />
                        : task.status === "PAUSE" ?
                        <BsPlayBtnFill
                          className="cursor-pointer"
                          title="Play"
                          onClick={() => {
                            handleStatusTaskChange(task)
                          }}
                        />
                        : null
                      }
                      {
                        task.status === "RUN" || task.status === "PAUSE" ?
                          <ModalCenter
                            isOpen={isModalChangeStatusTaskOpen && selectedTask?.id === task.id}
                            toggleModal={toggleModalChangeStatusTask}
                            content={
                              selectedTask && (
                                <ContentConfirm
                                  toggleModal={toggleModalChangeStatusTask}
                                  text="Are you sure you want to update this task?"
                                  onHandle={() => handleChangeStatusTaskConfirm(task)}
                                  updateItems={updateTasks}
                                />
                              )
                            }
                          />
                        : null
                      }
                    </div>
                    <div className="col-span-2">
                      <p className="text-[#637381] dark:text-bodydark">
                        { task.started_at == 0 && task.finished_at == 0 ?
                        "- / -"
                        :
                        (
                          <>
                            {task.started_at == 0 ? "-" : (formatTimeUTC(new Date(task.started_at)) + " " + formatDateUTC(new Date(task.started_at)))}
                            <br/>
                            {task.finished_at == 0 ? "-" : (formatTimeUTC(new Date(task.finished_at)) + " " + formatDateUTC(new Date(task.finished_at)))}
                          </>
                        )
                        }
                        </p>
                    </div>
                    <div className="col-span-2">
                      {/* <p className="text-[#637381] dark:text-bodydark">{`${task.started_at ? formatDate(task.started_at): '-'} / ${task.finished_at ? formatDate(task.finished_at) : '-'}`}</p> */}
                      <p className="text-[#637381] dark:text-bodydark">{`${task?.nowRange} / ${task?.totalRange}`}</p>
                      {/* <p className="text-[#637381] dark:text-bodydark">{JSON.stringify(task)}</p> */}
                    </div>
                    <div className="col-span-3">
                      <div className="flex gap-3 items-start">
                        <button 
                          className="text-xl" 
                          onClick={() => handleCommentTask(task)}>
                          { task.comment ? <BsFillChatFill className="text-primary" /> : <BsFillChatFill /> }
                        </button>
                        <ModalSideRight
                          isOpen={isModalCommentOpen}
                          toggleModal={toggleModalComment}
                          title="COMMENT FOR TASK"
                          content={
                            selectedTask && (
                              <Comment
                                updateTasks={updateTasks}
                                toggleModal={toggleModalComment}
                                id={selectedTask.id}
                                commentDefault={selectedTask.comment}
                              />
                            )
                          }
                        />
                        <p className="text-[#637381] dark:text-bodydark">{task.comment ? task.comment : "-"}</p>
                      </div>
                    </div>
                    <div className="col-span-1 flex items-center gap-4">
                      <BsFileBarGraphFill
                        className="cursor-pointer"
                        onClick={() => handleReportClick(task.id)}
                        title="Report"
                      />
                      {/* <BsEyeFill 
                        className="cursor-pointer"
                        onClick={() => handleShowTask(task)}
                        title="Show task"
                      />
                      <ModalSideRight
                        isOpen={isModalShowOpen}
                        toggleModal={toggleModalShow}
                        title="SHOW TASK"
                        content={
                          selectedTask && (
                            <Task 
                              toggleModal={toggleModalShow}
                              task={selectedTask}
                              action='show'
                            />
                          )
                        }
                      /> */}
                      <MdCopyAll 
                        className="cursor-pointer"
                        onClick={() => handleCloneTask(task)}
                        title="Clone task"
                      />
                      {/* <ModalSideRight
                        isOpen={isModalCloneOpen}
                        toggleModal={toggleModalClone}
                        title="CLONE TASK"
                        content={
                          selectedTask && (
                            <Task 
                              toggleModal={toggleModalClone}
                              task={selectedTask}
                              action='clone'
                              updateTasks={updateTasks}
                              onSave={handleCloneSave}
                            />
                          )
                        }
                      /> */}
                      <BsFillTrashFill
                        className="cursor-pointer"
                        onClick={() => handleDeleteTask(task)}
                        title="Delete task"
                      />
                      {/* <ModalCenter
                        isOpen={isModalDeleteOpen}
                        toggleModal={toggleModalDelete}
                        content={
                          selectedTask && (
                            <ContentConfirm
                              toggleModal={toggleModalDelete}
                              text="Are you sure you want to delete this user?"
                              onHandle={handleDeleteConfirm}
                              updateItems={updateTasks}
                            />
                          )
                        }
                      /> */}
                      {/* <button 
                        className="text-xl" 
                        onClick={() => handleCommentTask(task)}>
                        { task.comment ? <BsFillChatFill className="text-primary" /> : <BsFillChatFill /> }
                      </button>
                      <ModalSideRight
                        isOpen={isModalCommentOpen}
                        toggleModal={toggleModalComment}
                        title="COMMENT FOR TASK"
                        content={
                          selectedTask && (
                            <Comment
                              updateTasks={updateTasks}
                              toggleModal={toggleModalComment}
                              id={selectedTask.id}
                              commentDefault={selectedTask.comment}
                            />
                          )
                        }
                      /> */}
                    </div>
                  </div>
                ))
              )}
              {
                tasksArray.length > 0 ?
                <>
                  <ModalSideRight
                    isOpen={isModalCloneOpen}
                    toggleModal={toggleModalClone}
                    title="CLONE TASK"
                    content={
                      selectedTask && (
                        <Task 
                          toggleModal={toggleModalClone}
                          task={selectedTask}
                          action='clone'
                          updateTasks={updateTasks}
                          onSave={handleCloneSave}
                        />
                      )
                    }
                  />
                  <ModalCenter
                    isOpen={isModalDeleteOpen}
                    toggleModal={toggleModalDelete}
                    content={
                      selectedTask && (
                        <ContentConfirm
                          toggleModal={toggleModalDelete}
                          text="Are you sure you want to delete this user?"
                          onHandle={handleDeleteConfirm}
                          updateItems={updateTasks}
                        />
                      )
                    }
                  />
                </>
                : null
              }
            </div>

          </div>
        </div>
      </div>

      {tasksArray.length != 0 && 
        <Pagination
          pageCount={pageCount}
          onPageChange={handlePageChange}
        />
      }
    </>
  );
};

export default TableTasks;