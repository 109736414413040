import React, { useState, useEffect } from 'react';
import api from '../../utils/api';
import { ApiResponse } from '../../types/api';
import { AnalyticsProvider } from '../../context/AnalyticsContext';
import { useAccountsManagementContext } from '../../context/AccountsManagementContext';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import { Toaster, toast } from 'sonner';
import BtnPrimary from "../../components/Buttons/BtnPrimary";
import { BsPlusCircleFill } from "react-icons/bs";
import ModalSideRight from '../../components/Modals/ModalSideRight';
import Account from './Account';
import TableAccounts from './TableAccounts';
import { ManagementAccounts } from './interface';
import { messages } from '../../data/messages';

const breadcrumbItems = [
  { name: 'Dashboard', link: '/dashboard' },
  { name: 'Management Accounts', link: '/accounts-management' }
];

const AccountsContent: React.FC = () => {
  const {
    accounts,
    setAccounts,
    selectedAccount,
    fetchAccounts,
    handleActionsAccount,
    isModalActionsOpen,
    toggleModalActions,
    handleTransferOpen,
    isModalTransfer,
    toggleModalTransfer,
    handleTransfer,
    isModalAddTask,
    toggleModalAddTask,
    handleAddTaskOpen,
    handleAddTask,
  } = useAccountsManagementContext();
  const [isModalSideOpen, setIsModalSideOpen] = useState(false);
  const [acc, setAcc] = useState<ManagementAccounts[]>(accounts);
  const [rend, setRend] = useState(false);


  const toggleModalSide = () => {
    setIsModalSideOpen(!isModalSideOpen);
  };

  const handleSave = (result: boolean) => {
    if (result) {
      toast.success(messages.account.create.success);
      fetchAccounts();
    } else {
      toast.error(messages.account.create.failed);
    }
  };

  return (
    <AnalyticsProvider>
      <Toaster position="bottom-center" richColors />
      <Breadcrumb items={breadcrumbItems} />

      <div className='grid grid-cols-2 flex-col items-stretch sm:flex sm:flex-row gap-4 items-start mb-4'>
        <BtnPrimary onClick={toggleModalSide} className='col-span-2 justify-center sm:justify-start'>
          <BsPlusCircleFill /> Add Main Account
        </BtnPrimary>
      </div>

      <ModalSideRight
        isOpen={isModalSideOpen}
        toggleModal={toggleModalSide}
        title="ADD MAIN ACCOUNT"
        content={
          <Account toggleModal={toggleModalSide} action='add' onSave={handleSave} />
        }
      />

      <TableAccounts
        accounts={accounts}
        // totalCount={totalCount}
        fetchAccounts={fetchAccounts}
        // onPageChange={handlePageChange}
        // currentPage={currentPage}
        setAccounts={setAcc}
        handleActionsAccount={handleActionsAccount}
        isModalActionsOpen={isModalActionsOpen}
        toggleModalActions={toggleModalActions}
        selectedAccount={selectedAccount}
        handleTransferOpen={handleTransferOpen}
        isModalTransfer={isModalTransfer}
        toggleModalTransfer={toggleModalTransfer}
        handleAddTaskOpen={handleAddTaskOpen}
        handleTransfer={handleTransfer}
        isModalAddTask={isModalAddTask}
        toggleModalAddTask={toggleModalAddTask}
        handleAddTask={handleAddTask}
      />

    </AnalyticsProvider>
  );
};

export default AccountsContent;