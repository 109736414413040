import React, { useState, useEffect } from 'react';
import { BsEyeFill, BsEyeSlashFill, BsLockFill } from 'react-icons/bs';
import { validatePassword } from '../../../utils/validations';

interface InputPasswordProps {
  name?: string,
  label?: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  validate?: (value: string) => boolean;
  errorForm?: boolean;
  disabled?: boolean;
  className?: string,
}

const InputPassword: React.FC<InputPasswordProps> = ({
  name,
  value,
  onChange,
  validate = validatePassword,
  errorForm,
  label = 'Password',
  disabled,
  className }) => {
  const [isValid, setIsValid] = useState<boolean | null>(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    onChange(e);

    if (errorForm) {
      setIsValid(false);
      return;
    }

    if (newValue.length > 0) {
      const valid = validate(newValue);
      setIsValid(valid);
      if (!valid) {
        setErrorMessage('Password must be more than 6 characters long');
      } else {
        setErrorMessage('');
      }
    } else {
      setIsValid(null);
      setErrorMessage('');
    }
  };

  useEffect(() => {
    if (isValid) {
      setErrorMessage('');
    }
  }, [isValid]);

  return (
    <div className={`${className}`}>
      <label className="block font-medium text-[#637381] dark:text-white mb-2.5">
        {label}
      </label>
      <div className="relative">
        <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
          <BsLockFill className="text-gray-500 text-xl" />
        </div>
        <input
          autoComplete='off'
          name={name}
          type={showPassword ? 'text' : 'password'}
          placeholder="6+ Characters"
          className={`
            w-full rounded-lg border-[1.5px] border-form-strokedark active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input py-3 pl-10 pr-12 text-white outline-none focus-visible:shadow-none border-form-stroke bg-form-input focus:border-primary transition-all
            ${isValid === null ? '' : isValid ? 'border-green-500 focus:border-green-500' : 'border-red-500 focus:border-red-500'}
          `}
          value={value}
          onChange={handleInputChange}
          disabled={disabled}
        />
        <div className="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer" onClick={() => setShowPassword(!showPassword)}>
          {showPassword ? <BsEyeFill className="text-gray-500 text-xl" /> : <BsEyeSlashFill className="text-gray-500 text-xl" />}
        </div>
      </div>
      {errorMessage && <p className="text-red-500 mt-2">{errorMessage}</p>}
    </div>
  );
};

export default InputPassword;