export const formatCurrency = (value: number | string | undefined): string => {
  const formatter = new Intl.NumberFormat('ru-RU', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
  if (typeof value === 'undefined') {
    return "—"
    // return formatter.format(0)
  }
  const numValue = typeof value === 'string' ? parseFloat(value) : value;

  return formatter.format(numValue);


  // const formatter = new Intl.NumberFormat('en-US', {
  //   style: 'currency',
  //   currency: 'USD',
  //   minimumFractionDigits: 2,
  //   maximumFractionDigits: 2,
  // });
  // if (typeof value === 'undefined') {
  //   return "—"
  //   // return formatter.format(0)
  // }
  // const numValue = typeof value === 'string' ? parseFloat(value) : value;

  // return formatter.format(numValue);
};