import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import api from '../utils/api';
import { ApiResponse } from '../types/api';
import { ManagementTask, TasksContextProps } from '../pages/TasksManagement/interface';
import { toast } from 'sonner';
import { messages } from '../data/messages';

const TasksManagementContext = createContext<TasksContextProps | undefined>(undefined);

export const useTasksManagementContext = () => {
  const context = useContext(TasksManagementContext);
  if (!context) {
    throw new Error('useTasksManagementContext must be used within a TasksProvider');
  }
  return context;
};

export const TasksProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [tasks, setTasks] = useState<ManagementTask[]>([]);
  const [selectedTask, setSelectedTask] = useState<ManagementTask | null>(null);
  const [isModalActionsOpen, setIsModalActionsOpen] = useState(false);
  const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);
  
  const toggleModalActions = () => setIsModalActionsOpen(!isModalActionsOpen);
  const toggleModalDelete = () => setIsModalDeleteOpen(!isModalDeleteOpen);

  const fetchTasks = async () => {
    // console.log("fetchTasks start")
    try {
      const response = await api.get<ApiResponse<ManagementTask[]>>(`/api/management/tasks`, {}
      );
      console.log("1", response, response.data.code, response.data.result)
      // await new Promise(resolve => setTimeout(resolve, 500));
      if (response.data.code == 200 && response.data.result) {
        // debugger
        setTasks(response.data.result);
      } else {
        setTasks([]);
      }
    } catch (error) {
      console.error('Error fetching accounts:', error);
    }
  };

  const handleDeleteTask = (selectedTask: ManagementTask) => {
    setSelectedTask(selectedTask);
    toggleModalDelete();
  };

  const deleteTask = async (id: string) => {
    try {
      const response = await api.delete(`/api/management/tasks/${id}`);
      if (response.data.code === 200 && response.data.error === null) {
        toast.success(messages.task.delete.success);
        return true;
      } else {
        toast.error(messages.task.delete.failed);
        return false;
      }
    } catch (error) {
      toast.error(messages.task.delete.noSelectedTaskError);
      return false;
    }
  };

  const handleDeleteConfirm = async (result: boolean) => {
    if (result && selectedTask?.id ) {
      try {
        const success = await deleteTask(selectedTask.id);
        if (success) {
          toast.success(messages.task.delete.success);
          await fetchTasks();
        } else {
          toast.error(messages.task.delete.failed);
        }
      } catch (error) {
        toast.error(messages.task.delete.failed);
      }
    } else {
      toast.error(messages.task.delete.noSelectedTaskError);
    }
    toggleModalDelete();
  };

  useEffect(() => {
    fetchTasks();
  }, []);

  const contextValue = {
    tasks,
    fetchTasks,
    selectedTask,
    toggleModalActions,
    handleDeleteTask,
    isModalDeleteOpen,
    toggleModalDelete,
    handleDeleteConfirm,
  };

  return (
    <TasksManagementContext.Provider value={contextValue}>
      {children}
    </TasksManagementContext.Provider>
  );
};