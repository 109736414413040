import React, { useContext, useState, useEffect } from 'react';
import { useOrdersHistoryContext } from '../../context/OrdersHistoryContext';
import SimpleSelect from '../../components/Forms/Select/SimpleSelect';

interface CountPaginationProps { }

const paginationTypes = [
  {
    value: "10",
    text: "10",
  },
  {
    value: "25",
    text: "25",
  },
  {
    value: "50",
    text: "50",
  },
  {
    value: "100",
    text: "100",
  },
];

const CountPagination: React.FC<CountPaginationProps> = ({ }) => {
  const {
    pageSize,
    setPageSize,
    getTransactionStatistics,
    currentPage,
    startDate,
    endDate,
    selectedApiKeys,
    selectedBots,
    selectedSymbols
  } = useOrdersHistoryContext();
  const [counter, setCounter] = useState(pageSize.toString());

  const handleCounterChange = (value: string) => {
    const newPageSize = parseInt(value, 10);
    setCounter(value);
    setPageSize(newPageSize);
    getTransactionStatistics(currentPage, newPageSize, startDate, endDate, selectedApiKeys, selectedBots, selectedSymbols);
  };

  useEffect(() => {
    setCounter(pageSize.toString());
  }, [pageSize]);

  return (
    <SimpleSelect
      options={paginationTypes}
      value={counter}
      onChange={handleCounterChange}
      className='w-[5rem]'
    />
  );
};

export default CountPagination;