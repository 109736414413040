
import React from 'react';
import MultiSelect from '../../components/Forms/Select/MultiSelect';
import InputField from '../../components/Forms/Inputs/InputField';
import SimpleSelect from '../../components/Forms/Select/SimpleSelect';
import BtnPrimary from '../../components/Buttons/BtnPrimary';
import { types, typesForSpot } from '../../data/typesMarket';
import { useManualTradingContext, ManualTradingProvider } from '../../context/ManualTradingContext';
import { Db_Symbols } from '../../types/symbols'

const AddOrder: React.FC = () => {

  const {
    symbol,
    type,
    leverage,
    amount,
    takeProfit,
    stopLoss,
    isSubmittingLong,
    isSubmittingShort,
    position,
    price,
    handleLeverageChange,
    handleSelectChange,
    handleTypeChange,
    handleAmountChange,
    handleTakeProfitChange,
    handleStopLossChange,
    handlePriceChange,
    handleSubmit,
    fetchAccountInfo,
    accountInfo,
  } = useManualTradingContext();

  const renderAdditionalFields = () => {
    switch (type) {
      case 'Market':
        return (
          <FieldMarket
            amount={amount} handleAmountChange={handleAmountChange}
            takeProfit={takeProfit} handleTakeProfitChange={handleTakeProfitChange}
            stopLoss={stopLoss} handleStopLossChange={handleStopLossChange}
            market={accountInfo?.market} />
        );
      case 'Limit':
        return (
          <FieldLimit
            amount={amount} handleAmountChange={handleAmountChange}
            takeProfit={takeProfit} handleTakeProfitChange={handleTakeProfitChange}
            stopLoss={stopLoss} handleStopLossChange={handleStopLossChange}
            price={price} handlePriceChange={handlePriceChange}
            market={accountInfo?.market} />
        );
      case 'Take Profit':
        return (
          <FieldTakeProfit
            amount={amount} handleAmountChange={handleAmountChange}
            takeProfit={takeProfit} handleTakeProfitChange={handleTakeProfitChange}
            stopLoss={stopLoss} handleStopLossChange={handleStopLossChange}
            market={accountInfo?.market} />
        );
      case 'Stop Loss':
        return (
          <FieldStopLose
            amount={amount} handleAmountChange={handleAmountChange}
            takeProfit={takeProfit} handleTakeProfitChange={handleTakeProfitChange}
            stopLoss={stopLoss} handleStopLossChange={handleStopLossChange}
            market={accountInfo?.market} />
        );
      default:
        return null;
    }
  };

  const transformSymbolsToOptions = (symbols: Db_Symbols[]) => {
    return symbols.map(symbols => ({
      value: symbols?.symbol,
      text: symbols.symbol
    }));
  };

  let symbolData = localStorage.getItem('symbols') ? transformSymbolsToOptions(JSON.parse(localStorage.getItem('symbols') || "")) : []
  if (accountInfo?.exchange_name.toUpperCase() == "BINANCE") {
    symbolData = transformSymbolsToOptions(JSON.parse(localStorage.getItem('binanceFutures_symbols') || "[]"))
  } else if (accountInfo?.exchange_name.toUpperCase() == "BYBIT") {
    symbolData = transformSymbolsToOptions(JSON.parse(localStorage.getItem('bybitFutures_symbols') || "[]"))
  } else if (accountInfo?.exchange_name.toUpperCase() == "GATE") {
    symbolData = transformSymbolsToOptions(JSON.parse(localStorage.getItem('gateSpot_symbols') || "[]"))
  } else if (accountInfo?.exchange_name.toUpperCase() == "MEXC") {
    // symbolData = transformSymbolsToOptions(JSON.parse(localStorage.getItem('mexcSpot_symbols') || "[]"))
    symbolData = transformSymbolsToOptions(JSON.parse(localStorage.getItem('mexcFutures_symbols') || "[]"))
  } else if (accountInfo?.exchange_name.toUpperCase() == "BITGET") {
    symbolData = transformSymbolsToOptions(JSON.parse(localStorage.getItem('bitgetSpot_symbols') || "[]"))
  }

  // console.log(accountInfo, symbolData)
  return (
    <div className='flex flex-col items-center justify-start mb-4 rounded-lg border border-stroke bg-white dark:border-strokedark dark:bg-boxdark px-3 py-2.5 shadow-default h-full'>

      <div className='grid grid-cols-1 md:grid-cols-3 gap-2 w-full mb-4'>
        <div className='md:col-span-2'>
          <MultiSelect
            className='w-full'
            label='Choose symbol'
            options={symbolData}
            defaultValue={[symbol]}
            onChange={handleSelectChange}
          />
        </div>

        {accountInfo?.market?.toUpperCase() != "SPOT" && (
          <div className='md:col-span-1'>
            <InputField
              label="Leverage"
              value={leverage === '' ? '' : leverage}
              onChange={handleLeverageChange}
              placeholder="Leverage"
              type="number"
              className="w-full"
            />
          </div>
        )}
      </div>

      <SimpleSelect
        options={accountInfo?.market.toUpperCase() != "SPOT" ? types : typesForSpot}
        value={type}
        onChange={handleTypeChange}
        className="w-full mb-2"
      />

      {renderAdditionalFields()}

      <div className='flex items-center gap-2 mt-4'>
        <BtnPrimary style='green' disabled={amount == 0 || isSubmittingLong} onClick={() => handleSubmit('LONG')} >
          {isSubmittingLong ?
            <div className='flex gap-3 items-center justify-center'>
              <span className="animate-spin">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <mask id="path-1-inside-1_1881_16183" fill="white">
                    <path d="M15.328 23.5293C17.8047 22.8144 19.9853 21.321 21.547 19.2701C23.1087 17.2193 23.9686 14.72 23.9992 12.1424C24.0297 9.56481 23.2295 7.04587 21.7169 4.95853C20.2043 2.8712 18.0597 1.32643 15.6007 0.552947C13.1417 -0.220538 10.499 -0.181621 8.0638 0.663935C5.62864 1.50949 3.53049 3.11674 2.07999 5.24771C0.629495 7.37868 -0.096238 9.92009 0.0102418 12.4957C0.116722 15.0713 1.04975 17.5441 2.6712 19.5481L4.96712 17.6904C3.74474 16.1796 3.04133 14.3154 2.96106 12.3737C2.88079 10.432 3.42791 8.51604 4.52142 6.90953C5.61493 5.30301 7.19671 4.09133 9.03255 3.45387C10.8684 2.81642 12.8607 2.78708 14.7145 3.3702C16.5683 3.95332 18.1851 5.1179 19.3254 6.69152C20.4658 8.26514 21.0691 10.1641 21.046 12.1074C21.023 14.0506 20.3748 15.9347 19.1974 17.4809C18.02 19.027 16.3761 20.1528 14.5089 20.6918L15.328 23.5293Z"></path></mask><path d="M15.328 23.5293C17.8047 22.8144 19.9853 21.321 21.547 19.2701C23.1087 17.2193 23.9686 14.72 23.9992 12.1424C24.0297 9.56481 23.2295 7.04587 21.7169 4.95853C20.2043 2.8712 18.0597 1.32643 15.6007 0.552947C13.1417 -0.220538 10.499 -0.181621 8.0638 0.663935C5.62864 1.50949 3.53049 3.11674 2.07999 5.24771C0.629495 7.37868 -0.096238 9.92009 0.0102418 12.4957C0.116722 15.0713 1.04975 17.5441 2.6712 19.5481L4.96712 17.6904C3.74474 16.1796 3.04133 14.3154 2.96106 12.3737C2.88079 10.432 3.42791 8.51604 4.52142 6.90953C5.61493 5.30301 7.19671 4.09133 9.03255 3.45387C10.8684 2.81642 12.8607 2.78708 14.7145 3.3702C16.5683 3.95332 18.1851 5.1179 19.3254 6.69152C20.4658 8.26514 21.0691 10.1641 21.046 12.1074C21.023 14.0506 20.3748 15.9347 19.1974 17.4809C18.02 19.027 16.3761 20.1528 14.5089 20.6918L15.328 23.5293Z" stroke="white" strokeWidth="14" mask="url(#path-1-inside-1_1881_16183)"></path>
                </svg>
              </span>
              <p className='text-sm'>Loading...</p>
            </div>
            : <p className='text-sm'>BUY/LONG</p>}
        </BtnPrimary>
        <BtnPrimary style='red' disabled={amount == 0 || isSubmittingShort} onClick={() => { handleSubmit('SHORT') }} >
          {isSubmittingShort ?
            <div className='flex gap-3 items-center justify-center'>
              <span className="animate-spin">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <mask id="path-1-inside-1_1881_16183" fill="white">
                    <path d="M15.328 23.5293C17.8047 22.8144 19.9853 21.321 21.547 19.2701C23.1087 17.2193 23.9686 14.72 23.9992 12.1424C24.0297 9.56481 23.2295 7.04587 21.7169 4.95853C20.2043 2.8712 18.0597 1.32643 15.6007 0.552947C13.1417 -0.220538 10.499 -0.181621 8.0638 0.663935C5.62864 1.50949 3.53049 3.11674 2.07999 5.24771C0.629495 7.37868 -0.096238 9.92009 0.0102418 12.4957C0.116722 15.0713 1.04975 17.5441 2.6712 19.5481L4.96712 17.6904C3.74474 16.1796 3.04133 14.3154 2.96106 12.3737C2.88079 10.432 3.42791 8.51604 4.52142 6.90953C5.61493 5.30301 7.19671 4.09133 9.03255 3.45387C10.8684 2.81642 12.8607 2.78708 14.7145 3.3702C16.5683 3.95332 18.1851 5.1179 19.3254 6.69152C20.4658 8.26514 21.0691 10.1641 21.046 12.1074C21.023 14.0506 20.3748 15.9347 19.1974 17.4809C18.02 19.027 16.3761 20.1528 14.5089 20.6918L15.328 23.5293Z"></path></mask><path d="M15.328 23.5293C17.8047 22.8144 19.9853 21.321 21.547 19.2701C23.1087 17.2193 23.9686 14.72 23.9992 12.1424C24.0297 9.56481 23.2295 7.04587 21.7169 4.95853C20.2043 2.8712 18.0597 1.32643 15.6007 0.552947C13.1417 -0.220538 10.499 -0.181621 8.0638 0.663935C5.62864 1.50949 3.53049 3.11674 2.07999 5.24771C0.629495 7.37868 -0.096238 9.92009 0.0102418 12.4957C0.116722 15.0713 1.04975 17.5441 2.6712 19.5481L4.96712 17.6904C3.74474 16.1796 3.04133 14.3154 2.96106 12.3737C2.88079 10.432 3.42791 8.51604 4.52142 6.90953C5.61493 5.30301 7.19671 4.09133 9.03255 3.45387C10.8684 2.81642 12.8607 2.78708 14.7145 3.3702C16.5683 3.95332 18.1851 5.1179 19.3254 6.69152C20.4658 8.26514 21.0691 10.1641 21.046 12.1074C21.023 14.0506 20.3748 15.9347 19.1974 17.4809C18.02 19.027 16.3761 20.1528 14.5089 20.6918L15.328 23.5293Z" stroke="white" strokeWidth="14" mask="url(#path-1-inside-1_1881_16183)"></path>
                </svg>
              </span>
              <p className='text-sm'>Loading...</p>
            </div>
            : <p className='text-sm'>SELL/SHORT</p>}
        </BtnPrimary>
      </div>
    </div>
  );
};

export default AddOrder;

interface FieldProps {
  amount: number | '';
  handleAmountChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  takeProfit: number | '';
  handleTakeProfitChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  stopLoss: number | '';
  handleStopLossChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  price?: number | '';
  handlePriceChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  market: string | undefined;
}

const FieldMarket: React.FC<FieldProps> = ({
  amount, handleAmountChange,
  takeProfit, handleTakeProfitChange,
  stopLoss, handleStopLossChange, market
}) => {
  return (
    <div className="w-full">
      <div className='flex items-center gap-2 mb-4'>
        <InputField
          label="Amount"
          value={amount}
          onChange={handleAmountChange}
          placeholder="Amount"
          type="number"
          className="w-full"
        />
      </div>

      {market?.toUpperCase() != "SPOT" && (
        <>
          <InputField
            label="Take Profit"
            value={takeProfit}
            onChange={handleTakeProfitChange}
            placeholder="Take Profit"
            type="number"
            className="w-full"
          />
          <InputField
            label="Stop Loss"
            value={stopLoss}
            onChange={handleStopLossChange}
            placeholder="Stop Loss"
            type="number"
            className="w-full"
          />
        </>
      )}

    </div>
  );
};

const FieldLimit: React.FC<FieldProps> = ({
  amount, handleAmountChange,
  takeProfit, handleTakeProfitChange,
  stopLoss, handleStopLossChange,
  price, handlePriceChange,
  market
}) => {
  return (
    <div className="w-full">
      <div className='flex items-center gap-2 mb-4'>
        <InputField
          label="Amount"
          value={amount}
          onChange={handleAmountChange}
          placeholder="Amount"
          type="number"
          className="w-full"
        />

        <InputField
          label="Price"
          value={price ?? ''}
          onChange={handlePriceChange ?? (() => { })}
          placeholder="Price"
          type="number"
          className="w-full"
        />
      </div>
      {market?.toUpperCase() != "SPOT" && (
        <div className='flex items-center gap-2'>
          <InputField
            label="Take Profit"
            value={takeProfit}
            onChange={handleTakeProfitChange}
            placeholder="Take Profit"
            type="number"
            className="w-full"
          />

          <InputField
            label="Stop Loss"
            value={stopLoss}
            onChange={handleStopLossChange}
            placeholder="Stop Loss"
            type="number"
            className="w-full"
          />
        </div>
      )}

    </div>
  );
};

const FieldTakeProfit: React.FC<FieldProps> = ({ amount, handleAmountChange, takeProfit, handleTakeProfitChange }) => {
  return (
    <div className="w-full">
      <InputField
        label="Amount"
        value={amount}
        onChange={handleAmountChange}
        placeholder="Amount"
        type="number"
        className="w-full mb-4"
      />
      <InputField
        label="Take Profit"
        value={takeProfit}
        onChange={handleTakeProfitChange}
        placeholder="Take Profit"
        type="number"
        className="w-full"
      />
    </div>
  );
};

const FieldStopLose: React.FC<FieldProps> = ({ amount, handleAmountChange, stopLoss, handleStopLossChange }) => {
  return (
    <div className="w-full">
      <InputField
        label="Amount"
        value={amount}
        onChange={handleAmountChange}
        placeholder="Amount"
        type="number"
        className="w-full mb-4"
      />
      <InputField
        label="Stop Loss"
        value={stopLoss}
        onChange={handleStopLossChange}
        placeholder="Stop Loss"
        type="number"
        className="w-full"
      />
      {/* <InputField
        label="Take Profit"
        value={takeProfit}
        onChange={handleTakeProfitChange}
        placeholder="Take Profit"
        type="number"
        className="w-full"
      /> */}
    </div>
  );
};