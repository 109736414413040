import React, { useState, useEffect, useMemo } from 'react';
import { TasksManagersProps } from './interface';
import { useTasksManagementContext } from '../../context/TasksManagementContext';
import NotFound from '../../common/NotFound';
import { ManagementTask } from './interface';
import { SiMainwp } from "react-icons/si";
import { formatCurrency } from '../../utils/formatCurrency';
import { BsThreeDotsVertical, BsChevronDown, BsChevronUp, BsFillTrashFill } from "react-icons/bs";
import ModalBottom from '../../components/Modals/ModalBottom';
import ModalCenter from '../../components/Modals/ModalCenter';
import ContentConfirm from "../../components/Modals/ContentConfirm";

const TableTasks: React.FC<TasksManagersProps> = ({
  tasks,
  fetchTasks,
  selectedTask,
  toggleModalActions,
  handleDeleteTask,
  isModalDeleteOpen,
  toggleModalDelete,
  handleDeleteConfirm,
}) => {

  const {
    //
  } = useTasksManagementContext();

  useEffect(() => {
    // Обновляем таблицу при изменении данных
    // fetchTasks();
  }, []);

  return (
    <div className="overflow-hidden rounded-[10px] mb-4">
      <div className="max-w-full overflow-x-auto">
        <div className="w-full sm:min-w-[1170px]">

          <div className="hidden sm:grid grid-cols-12 gap-2 bg-[#F9FAFB] px-1 py-5 dark:bg-meta-4 lg:px-7.5 2xl:px-11">
            <div className="col-span-1 flex w-[40px] grow-0 shrink justify-start">
              <h5 className="font-medium text-[#637381] dark:text-bodydark uppercase">#</h5>
            </div>
            <div className="col-span-2 flex grow justify-start">
              <h5 className="font-medium text-[#637381] dark:text-bodydark uppercase">Type</h5>
            </div>
            <div className="col-span-2 flex justify-center">
              <h5 className="font-medium text-[#637381] dark:text-bodydark uppercase">From Subs</h5>
            </div>
            <div className="col-span-2 flex justify-center">
              <h5 className="font-medium text-[#637381] dark:text-bodydark uppercase">To Main</h5>
            </div>
            <div className="col-span-2 flex justify-center">
              <h5 className="font-medium text-[#637381] dark:text-bodydark uppercase">Start Balance</h5>
            </div>
            <div className="col-span-2 flex justify-center">
              <h5 className="font-medium text-[#637381] dark:text-bodydark uppercase">Amount</h5>
            </div>
            <div className="col-span-1 flex justify-end items-center">
              <h5 className="font-medium text-[#637381] dark:text-bodydark uppercase">Actions</h5>
            </div>
          </div>

          <div className="bg-white dark:bg-boxdark">
            {
              tasks == null || tasks.length === 0 ?
                (
                  <NotFound />
                )
                : (
                  tasks.map((task, index) => {

                    return (
                      <div key={"task_row-" + index}  className="border-t border-[#EEEEEE] px-5 py-4 dark:border-strokedark lg:px-7.5 2xl:px-11">
                        <div className="grid grid-cols-12 grid-rows-auto gap-2">
                          <div className="col-span-1 flex w-[40px] grow-0 shrink justify-start">
                            <h5 className="font-medium text-[#637381] dark:text-bodydark ">{index+1}</h5>
                          </div>
                          <div className="col-span-2 flex grow justify-start">
                            <h5 className="font-medium text-[#637381] dark:text-bodydark ">{task.type}</h5>
                          </div>
                          <div className="col-span-2 flex justify-center">
                            <h5 className="font-medium text-center text-[#637381] dark:text-bodydark ">{task?.fromSubsName.join(', ')}</h5>
                          </div>
                          <div className="col-span-2 flex justify-center">
                            <h5 className="font-medium text-[#637381] dark:text-bodydark ">{task?.toMainName}</h5>
                          </div>
                          <div className="col-span-2 flex justify-center">
                            <h5 className="font-medium text-[#637381] dark:text-bodydark ">{task?.startBalance}</h5>
                          </div>
                          <div className="col-span-2 flex justify-center">
                            <h5 className="font-medium text-[#637381] dark:text-bodydark ">{task?.amount}</h5>
                          </div>
                          <div className="col-span-1 flex justify-end items-start">
                            {/* <BsFillTrashFill
                              className="cursor-pointer duration-300 ease-in-out hover:text-primary text-xl"
                              onClick={() => handleDeleteTask(task)}
                              title="Delete task"
                            /> */}
                            <button
                              onClick={() => handleDeleteTask(task)}
                              type="button"
                              className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center"
                            >
                              Delete
                            </button>
                          </div>
                        </div>
                      </div>
                    )
                  })
                )
            }
          </div>
        </div>
      </div>
      <ModalCenter
        isOpen={isModalDeleteOpen}
        toggleModal={toggleModalDelete}
        content={
          selectedTask && (
            <ContentConfirm
              toggleModal={toggleModalDelete}
              text="Are you sure you want to delete this task?"
              onHandle={handleDeleteConfirm}
              updateItems={() => fetchTasks()}
            />
          )
        }
      />
    </div>
  );
};
export default TableTasks;