export const Strategies = [
  // {
  //   value: "Trend_Mashka",
  //   text: "Trend_Mashka",
  // },
  // {
  //   value: "Trend_Squeez",
  //   text: "Trend_Squeez",
  // },
  // {
  //   value: "Wave_Squeez",
  //   text: "Wave_Squeez",
  // },
  // {
  //   value: "ConterTrend_Squeez",
  //   text: "ConterTrend_Squeez",
  // },
  // {
  //   value: "Ludik",
  //   text: "Ludik",
  // },
  // {
  //   value: "Constructor",
  //   text: "Constructor",
  // },
  {
    value: "Syndicate",
    text: "Syndicate",
  },
  {
    value: "SyndicateV2",
    text: "SyndicateV2",
  },
  {
    value: "Kamikaze",
    text: "Kamikaze",
  },
  {
    value: "Kamikaze2",
    text: "Kamikaze2",
  },
  {
    value: "LimitMan",
    text: "LimitMan",
  },
  {
    value: "HighLow",
    text: "HighLow",
  },
]