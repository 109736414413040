import React, { useState, useEffect } from 'react';
import MultiSelect from "../../components/Forms/Select/MultiSelect";
import api from '../../utils/api'
import Switcher from "../../components/Switchers/Switcher";
import { BenchmarkPeriod, DownloadItem } from '../../types/period';
import { Toaster, toast } from 'sonner';
import MultipleChoose from '../../components/Forms/Select/MultipleChoose';
import { BsRobot, BsTrash, BsPencil, BsPlusCircleFill, BsXCircleFill } from "react-icons/bs";
import ModalCenter from '../../components/Modals/ModalCenter';
import SettingBot from './SettingBot/SettingBot';
import InputField from '../../components/Forms/Inputs/InputField';
import { Bot, TASK } from '../../types/task';
import BtnPrimary from '../../components/Buttons/BtnPrimary';
import ContentDelete from '../../components/Modals/ContentConfirm';
import axios from 'axios';
import { ApiResponse } from '../../types/api';
import { Db_Symbols } from '../../types/symbols';

enum TaskActions {
  Add = "add",
  Clone = "clone",
  Show = "show",
}

interface TaskFormProps {
  toggleModal: () => void;
  task?: TASK;
  updateTasks?: () => void;
  onSave?: (result: boolean) => void;
  action: string;
}

const TaskForm: React.FC<TaskFormProps> = ({ toggleModal, task, updateTasks, onSave, action }) => {
  const [name, setName] = useState(task?.name || '');
  const [saveOrders, setSaveOrders] = useState<boolean>(task?.save_orders || false);
  const [coinm, setCoinm] = useState<boolean>(false);
  const [botBalance, setBotBalance] = useState<number | ''>(task?.bot_balance || 40000);
  const [symbol, setSymbol] = useState<string[]>(task?.symbol ? [task.symbol] : []); // Исправлено
  const [leverage, setLeverage] = useState<number | ''>(task?.leverage || 1);
  const [maxMargin, setMaxMargin] = useState<number | ''>(task?.max_margin || 0.1);
  const [liquidationRate, setLiquidationRate] = useState<number | ''>(task?.liquidation_rate || 0.1);
  const [maintenance, setMaintenance] = useState<number | ''>(task?.maintenance || 0.1);
  const [optionsSymbol, setOptionsSymbol] = useState<{ value: string; text: string }[]>([]);
  const [chooseSymbolPeriods, setChooseSymbolPeriods] = useState<DownloadItem[]>(task?.symbol_periods || []);
  const [periods, setPeriods] = useState<DownloadItem[]>([]);
  const [isModalBot, setIsModalBot] = useState(false);
  const [bots, setBots] = useState<Bot[]>(task?.bots || []);
  const [editingBot, setEditingBot] = useState<Bot | undefined>(undefined);
  const [isModalDeleteBot, setIsModalDeleteBot] = useState(false);
  const [botToDeleteIndex, setBotToDeleteIndex] = useState<number | null>(null);

  const toggleModalBot = () => {
    setIsModalBot(!isModalBot);
  };

  const toggleModalDeleteBot = () => {
    setIsModalDeleteBot(!isModalDeleteBot);
  };

  useEffect(() => {
    const fetchSymbols = async () => {
      try {
        // const response = await api.get<ApiResponse<any>>('/benchmark-api/symbols');

        // const symbols: { value: string; text: string }[] = response.data?.result.map((symbol: any) => ({
        //   value: symbol,
        //   text: symbol,
        // }));
        let symbols;
        if (!localStorage.getItem('binanceFutures_symbols')) {
          symbols = await api.get<ApiResponse<Db_Symbols>>('/api/symbols?exchange=binance');
          await new Promise(resolve => setTimeout(resolve, 500));
          localStorage.setItem('binanceFutures_symbols', JSON.stringify(symbols.data.result));
        } else {
          symbols = JSON.parse(localStorage.getItem('binanceFutures_symbols') || "[]").map((item: any) => ({
            value: item.symbol,
            text: item.symbol,
          }));
        }

        setOptionsSymbol(symbols);
      } catch (error) {
        toast.error('Error while getting symbols');
      }
    };

    fetchSymbols();
  }, [task]);

  const fetchPeriods = async (symbol: string) => {
    try {
      const response = await api.get<ApiResponse<BenchmarkPeriod>>(`/benchmark-api/periods/${symbol}?coinm=${coinm}`);

      setPeriods(response.data?.result?.symbolPeriods || []);
    } catch (error) {
      toast.error('Error while getting periods');
    }
  };

  const fetchPeriodsCoinM = async (c: boolean) => {
    try {
      const response = await api.get<ApiResponse<BenchmarkPeriod>>(`/benchmark-api/periods/${symbol}?coinm=${c}`);

      setPeriods(response.data?.result?.symbolPeriods || []);
    } catch (error) {
      toast.error('Error while getting periods');
    }
  };

  useEffect(() => {
    // console.log(chooseSymbolPeriods)
    if (!periods.length && chooseSymbolPeriods.length) {
      fetchPeriods(chooseSymbolPeriods[0]?.data?.symbol)
    }
  }, [])

  const handleSelectChange = (values: string[]) => {
    setSymbol(values);
    setChooseSymbolPeriods([])
    if (values.length > 0) {
      fetchPeriods(values[0]);
    } else {
      setPeriods([]);
    }
  };



  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const handleSaveOrdersChange = () => {
    setSaveOrders(!saveOrders);
  };

  const handleCoinmChange = () => {
    setChooseSymbolPeriods([])
    fetchPeriodsCoinM(!coinm);
    setCoinm(!coinm);
  };

  const handleBotBalanceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setBotBalance(value === '' ? '' : parseFloat(value));
  };

  const handleLeverageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setLeverage(value === '' ? '' : parseInt(value, 10));
  };

  const handleMaxMarginChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setMaxMargin(value === '' ? '' : parseFloat(value));
  };

  const handleLiquidationRateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setLiquidationRate(value === '' ? '' : parseFloat(value));
  };

  const handleMaintenanceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setMaintenance(value === '' ? '' : parseFloat(value));
  };

  const handleAddTask = async (e: React.FormEvent) => {
    e.preventDefault();

    const taskData = {
      name: name,
      save_orders: saveOrders,
      bot_balance: Number(botBalance),
      symbol: symbol[0],
      symbol_periods: chooseSymbolPeriods,
      leverage: Number(leverage),
      max_margin: Number(maxMargin),
      liquidation_rate: Number(liquidationRate),
      maintenance: Number(maintenance),
      status: "Wait",
      bots: bots.map(bot => ({
        ...bot,
        // initUsdt: Number(bot.initUsdt),
        // openProcentMax: bot.openProcentMax !== undefined ? Number(bot.openProcentMax) : 0,
        // stopProcent: bot.stopProcent !== undefined ? Number(bot.stopProcent) : 0,
        // takeProfit: bot.takeProfit !== undefined ? Number(bot.takeProfit) : 0,
        // stopLose: bot.stopLose !== undefined ? Number(bot.stopLose) : 0,
      })),
    };

    try {

      await api.post<ApiResponse<string>>('/benchmark-api/tasks', taskData);

      toggleModal();
      onSave?.(true);
    } catch (error) {
      onSave?.(false);
    }
  };

  const handleCloneTask = async (e: React.FormEvent) => {
    e.preventDefault();

    const taskData = {
      name: name,
      save_orders: saveOrders,
      bot_balance: Number(botBalance),
      symbol: symbol[0],
      status: "Wait",
      symbol_periods: chooseSymbolPeriods,
      leverage: Number(leverage),
      max_margin: Number(maxMargin),
      liquidation_rate: Number(liquidationRate),
      maintenance: Number(maintenance),
      // bots: []
      bots: bots.map(bot => ({
        ...bot,
        // initUsdt: Number(bot.initUsdt),
        // openProcentMax: bot.openProcentMax !== undefined ? Number(bot.openProcentMax) : 0,
        // stopProcent: bot.stopProcent !== undefined ? Number(bot.stopProcent) : 0,
        // takeProfit: bot.takeProfit !== undefined ? Number(bot.takeProfit) : 0,
        // stopLose: bot.stopLose !== undefined ? Number(bot.stopLose) : 0,
      })),
    };

    try {
      await api.post<ApiResponse<string>>('/benchmark-api/tasks', taskData);
      toggleModal();
      onSave?.(true);
    } catch (error) {
      onSave?.(false);
    }
  };

  const handleRemoveBot = (index: number) => {
    setBotToDeleteIndex(index);
    toggleModalDeleteBot();
  };

  const handleSelectPeriod = (period: DownloadItem) => {
    setChooseSymbolPeriods([...chooseSymbolPeriods, period]);
  };

  const handleDeselectPeriod = (period: DownloadItem) => {
    setChooseSymbolPeriods(chooseSymbolPeriods.filter(p => p.periodId !== period.periodId));
  };

  const handleEditBot = (bot: Bot) => {
    setEditingBot(bot);
    setIsModalBot(true);
  };

  const handleDeleteBotConfirm = (index: number) => {
    const newBots = [...bots];
    newBots.splice(index, 1);
    setBots(newBots);
    toggleModalDeleteBot();
  };

  return (
    <>
      <form onSubmit={action === TaskActions.Clone ? handleCloneTask : handleAddTask} className="flex flex-col h-full">
        <div className="flex-grow mb-2">
          <InputField
            label="Name"
            value={name}
            onChange={handleNameChange}
            placeholder="Name task"
            className="mb-4"
            disabled={action === TaskActions.Show}
          />

          <div className="mb-4">
            <MultiSelect
              disabled={action === TaskActions.Show}
              label='Choose symbol'
              options={optionsSymbol}
              onChange={handleSelectChange}
              defaultValue={symbol}
            />
          </div>

          {(periods?.length > 0 || chooseSymbolPeriods.length) ?
            <>
              <div className='flex gap-3 items-center mb-3'>
                <p className='text-md text-black dark:text-white font-medium'>Coin-M</p>
                <Switcher
                  key={`coinM${new Date().getTime()}`}
                  isActive={coinm}
                  onChange={handleCoinmChange}
                  id={`coinM${new Date().getTime()}`}
                  disabled={action === TaskActions.Show}
                />
              </div>
              <MultipleChoose
                periods={periods}
                selectedPeriods={chooseSymbolPeriods}
                onSelect={handleSelectPeriod}
                onDeselect={handleDeselectPeriod}
                disabled={action === TaskActions.Show}
              />
            </>

            : null
          }

          <div className="mb-4 flex gap-3 items-center justify-between">
            <InputField
              label="Bot balance"
              value={botBalance === '' ? '' : botBalance}
              onChange={handleBotBalanceChange}
              placeholder="Balance"
              type="number"
              step="0.01"
              disabled={action === TaskActions.Show}
            />

            <div className='flex gap-3 items-center mb-3'>
              <p className='text-md text-black dark:text-white font-medium'>Save Orders</p>
              <Switcher
                key={`save_orders${new Date().getTime()}`}
                isActive={saveOrders}
                onChange={handleSaveOrdersChange}
                id={`save_orders${new Date().getTime()}`}
                disabled={action === TaskActions.Show}
              />
            </div>

            {/* <InputField
              label="Leverage"
              value={leverage === '' ? '' : leverage}
              onChange={handleLeverageChange}
              placeholder="Leverage"
              type="number"
              disabled={action === TaskActions.Show || action === TaskActions.Clone}
            /> */}
          </div>

          {/* <div className="mb-4 flex gap-3 items-center justify-between">
            <InputField
              label="Max margin"
              value={maxMargin === '' ? '' : maxMargin}
              onChange={handleMaxMarginChange}
              placeholder="Max margin"
              type="number"
              step="0.01"
              disabled={action === TaskActions.Show || action === TaskActions.Clone}
            />

            <InputField
              label="Liquidation Rate"
              value={liquidationRate === '' ? '' : liquidationRate}
              onChange={handleLiquidationRateChange}
              placeholder="Liquidation Rate"
              type="number"
              step="0.01"
              disabled={action === TaskActions.Show || action === TaskActions.Clone}
            />
          </div>

          <div className="mb-4 flex gap-3 items-end justify-between">
            <InputField
              label="Maintenance"
              value={maintenance === '' ? '' : maintenance}
              onChange={handleMaintenanceChange}
              placeholder="Maintenance"
              type="number"
              step="0.01"
              disabled={action === TaskActions.Show || action === TaskActions.Clone}
            />

            <div className='flex gap-3 items-center mb-3'>
              <p className='text-md text-black dark:text-white font-medium'>Save Orders</p>
              <Switcher
                isActive={saveOrders}
                onChange={handleSaveOrdersChange}
                id="save-orders"
                disabled={action === TaskActions.Show || action === TaskActions.Clone}
              />
            </div>
          </div> */}

          {action !== TaskActions.Show && (
            <BtnPrimary
              onClick={() => {
                setEditingBot(undefined);
                toggleModalBot();
              }}
            >
              <BsRobot /> Add bot
            </BtnPrimary>
          )}

          <ModalCenter
            isOpen={isModalBot}
            toggleModal={toggleModalBot}
            content={
              <SettingBot toggleModal={toggleModalBot} setBots={setBots} bot={editingBot} />
            }
            size={'lg'}
          />

          {/* Отображение ботов */}
          <div className="mt-4">
            {bots.map((bot, index) => (
              <div key={index} className="flex items-center justify-between p-4 border rounded-md mb-2">
                <div className='flex gap-2'>
                  <p className="font-bold text-lg">bot.strategy</p>
                  <span className="inline-flex rounded-full bg-[#3BA2B8] py-1 px-3 text-sm font-medium text-white hover:bg-opacity-90">bot.timeFrame</span>
                </div>
                {(action !== TaskActions.Show) && (
                  <div className="flex items-center gap-2">
                    <span onClick={() => handleRemoveBot(index)} className="text-red-500 cursor-pointer">
                      <BsTrash />
                    </span>

                    <ModalCenter
                      isOpen={isModalDeleteBot && botToDeleteIndex === index}
                      toggleModal={toggleModalDeleteBot}
                      content={
                        <ContentDelete
                          toggleModal={toggleModalDeleteBot}
                          text={`Are you sure you want to delete this bot.strategy bot?`}
                          onHandle={() => handleDeleteBotConfirm(index)}
                        />
                      }
                    />

                    <span onClick={() => handleEditBot(bot)} className="text-blue-500 cursor-pointer">
                      <BsPencil />
                    </span>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>

        {(action !== TaskActions.Show) && (
          <div className='flex items-center justify-between gap-4 mt-auto'>
            <BtnPrimary className='w-full' type='submit'>
              <BsPlusCircleFill />  {action === TaskActions.Add ? 'Add task' : 'Clone task'}
            </BtnPrimary>
            <BtnPrimary
              className='w-full'
              style="outline"
              onClick={() => {
                toggleModal();
              }}>
              <BsXCircleFill /> Close
            </BtnPrimary>
          </div>
        )}
      </form>
    </>
  );
};

export default TaskForm;