import React, { useState, useEffect } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import Loader from './common/Loader';
import PageTitle from './components/PageTitle';
import SignIn from './pages/Authentication/SignIn';
import Dashboard from './pages/Dashboard/Dashboard';
import ErrorPage from './pages/Error/Error';
import ApiKeys from './pages/ApiKeys/ApiKeys';
import DefaultLayout from './layout/DefaultLayout';
import SignInLayout from './layout/SignInLayout';
import Users from './pages/Users/Users';
import Profile from './pages/Profile/Profile';
import { UserProvider } from './context/UserContext';
import { TasksProvider } from './context/TasksContext';
import ManualTrading from './pages/ManualTrading/ManualTrading';
import BotSettings from './pages/Bots/All/BotSettings';
import BotsAll from './pages/Bots/All/BotsAll';
import Benchmark from './pages/Benchmark/Benchmark';
import Analytics from './pages/Analytics/Analytics';
import ReportPage from './pages/Report/Report';
import DetaiilsReportPage from './pages/DetaiilsReport/DetaiilsReport';
import { startProactiveTokenRefreshing } from './utils/startProactiveTokenRefreshing';
import TransactionStatistics from './pages/DealsStatistics/DealsStatistics';
import OrdersHistory from './pages/OrdersHistory/OrdersHistory';
import TransfersHistory from './pages/TransfersHistory/TransfersHistory';

import PnlStatistics from './pages/PnlStatistics/PnlStatistics';
import FutureSignal from './pages/FutureSignal/FutureSignal';
import { linkInterval, setLinkInterval } from "./utils/checkTimers";
import UiElements from './pages/UiElements/UiElements';
import AccountsManagement from './pages/AccountsManagement/AccountsManagement';
import TasksManagement from './pages/TasksManagement/TasksManagement';

function App() {
  const [loading, setLoading] = useState<boolean>(true);
  const [isInitialized, setIsInitialized] = useState<boolean>(false);
  const [pageNow, setPageNow] = useState<String>("");
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem('user') || '{}');


  useEffect(() => {
    window.scrollTo(0, 0);
    // console.log("Смена URL", pathname, linkInterval)
    // if (linkInterval && linkInterval != 0) {
    //   console.log("Очищаем таймер", pathname, linkInterval)
    //   clearInterval(linkInterval)
    //   setLinkInterval(0)
    // }

  }, [pathname]);

  useEffect(() => {
    const checkUserAuth = async () => {
      if (!isInitialized) {
        if (user && user.id) {
          // Запуск proactive token refreshing
          startProactiveTokenRefreshing();
          // console.log("_переход_");
        } else {
          if (pathname !== '/') {
            navigate('/');
          }
        }
        setIsInitialized(true);
        setLoading(false);
      }
    };

    checkUserAuth();

  }, [pathname, navigate, user, isInitialized]);

  return loading ? (
    <Loader />
  ) : (
    <UserProvider>
      <TasksProvider>
        <Routes>
          <Route
            path="/"
            element={
              <SignInLayout>
                <PageTitle title="Sign in" />
                <SignIn />
              </SignInLayout>
            }
          />
          <Route
            path="/dashboard"
            element={
              <DefaultLayout>
                <PageTitle title="Dashboard" />
                <Dashboard />
              </DefaultLayout>
            }
          />
          <Route
            path="/benchmark"
            element={
              <DefaultLayout>
                <PageTitle title="Benchmark" />
                <Benchmark />
              </DefaultLayout>
            }
          />
          <Route
            path="/analytics"
            element={
              <DefaultLayout>
                <PageTitle title="Analytics" />
                <Analytics />
              </DefaultLayout>
            }
          />
          <Route
            path="/profile"
            element={
              <DefaultLayout>
                <PageTitle title="Profile" />
                <Profile />
              </DefaultLayout>
            }
          />
          <Route
            path="/positions-history"
            element={
              <DefaultLayout>
                <PageTitle title="Positions history" />
                <TransactionStatistics />
              </DefaultLayout>
            }
          />
          <Route
            path="/orders-history"
            element={
              <DefaultLayout>
                <PageTitle title="Orders history" />
                <OrdersHistory />
              </DefaultLayout>
            }
          />
          <Route
            path="/transfers-history"
            element={
              <DefaultLayout>
                <PageTitle title="Transfers history" />
                <TransfersHistory />
              </DefaultLayout>
            }
          />
          <Route
            path="/pnl-statistics"
            element={
              <DefaultLayout>
                <PageTitle title="PnL statistics" />
                <PnlStatistics />
              </DefaultLayout>
            }
          />
          <Route
            path="/users"
            element={
              <DefaultLayout>
                <PageTitle title="Users" />
                <Users />
              </DefaultLayout>
            }
          />
          <Route
            path="/bots-all"
            element={
              <DefaultLayout>
                <PageTitle title="Bots All" />
                <BotsAll />
              </DefaultLayout>
            }
          />
          {/* <Route
            path="/bots-test"
            element={
              <DefaultLayout>
                <PageTitle title="Bots Test" />
                <Dashboard />
              </DefaultLayout>
            }
          /> */}
          {/* <Route
            path="/bots-investors"
            element={
              <DefaultLayout>
                <PageTitle title="Bots Investors" />
                <Dashboard />
              </DefaultLayout>
            }
          /> */}
          {/* <Route
            path="/bots-btc"
            element={
              <DefaultLayout>
                <PageTitle title="Bots BTC" />
                <Dashboard />
              </DefaultLayout>
            }
          /> */}
          {/* <Route
            path="/settings"
            element={
              <DefaultLayout>
                <PageTitle title="Settings" />
                <Settings />
              </DefaultLayout>
            }
          /> */}
          <Route
            path="/api-keys"
            element={
              <DefaultLayout>
                <PageTitle title="Api keys" />
                <ApiKeys />
              </DefaultLayout>
            }
          />
          <Route
            path="/manual-trading/:keyId/:symbol"
            element={
              <DefaultLayout>
                <PageTitle title="Manual trading" />
                <ManualTrading />
              </DefaultLayout>
            }
          />
          <Route
            path="/bot/:botUuid"
            element={
              <DefaultLayout>
                <PageTitle title="Bot settings" />
                <BotSettings />
              </DefaultLayout>
            }
          />
          {/* <Route
            path="/api-keys-test"
            element={
              <DefaultLayout>
                <PageTitle title="Api keys" />
                <ApiKeysTest />
              </DefaultLayout>
            }
          /> */}
          {/* <Route
            path="/orders"
            element={
              <DefaultLayout>
                <PageTitle title="Orders" />
                <Orders />
              </DefaultLayout>
            }
          /> */}
          <Route
            path="/error"
            element={
              <DefaultLayout>
                <PageTitle title="Error" />
                <ErrorPage />
              </DefaultLayout>
            }
          />
          <Route
            path="/report/:idTask"
            element={
              <DefaultLayout>
                <PageTitle title="Report" />
                <ReportPage />
              </DefaultLayout>
            }
          />
          <Route
            path="/details-report/:idReport"
            element={
              <DefaultLayout>
                <PageTitle title="Detaiils Report" />
                <DetaiilsReportPage />
              </DefaultLayout>
            }
          />
          <Route
            path="*"
            element={
              <DefaultLayout>
                <PageTitle title="Error" />
                <ErrorPage />
              </DefaultLayout>
            }
          />
          <Route
            path="/future-signal"
            element={
              <DefaultLayout>
                <PageTitle title="Signal Future" />
                <FutureSignal />
              </DefaultLayout>
            }
          />
          {/* <Route
            path="/spot-signal"
            element={
              <DefaultLayout>
                <PageTitle title="Signal Spot" />
                <SpotSignal />
              </DefaultLayout>
            }
          /> */}
          <Route
            path="/ui-elements"
            element={
              <DefaultLayout>
                <PageTitle title="UI-Elements" />
                <UiElements />
              </DefaultLayout>
            }
          />
          <Route
            path="/accounts-management"
            element={
              <DefaultLayout>
                <PageTitle title="Management Accounts" />
                <AccountsManagement />
              </DefaultLayout>
            }
          />
          <Route
            path="/tasks-management"
            element={
              <DefaultLayout>
                <PageTitle title="Management Tasks" />
                <TasksManagement />
              </DefaultLayout>
            }
          />
        </Routes>
      </TasksProvider>
    </UserProvider>
  );
}

export default App;