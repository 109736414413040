import React from 'react';
import { OrdersHistoryProvider } from '../../context/OrdersHistoryContext';
import OrdersHistoryContent from './OrdersHistoryContent';

const ApiKeys: React.FC = () => {
  return (
    <OrdersHistoryProvider>
      <OrdersHistoryContent />
    </OrdersHistoryProvider>
  );
};

export default ApiKeys;