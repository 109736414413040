import React, { useState, useEffect, useRef } from 'react';
import SimpleSelect from "../../components/Forms/Select/SimpleSelect";
import InputField from '../../components/Forms/Inputs/InputField';
import BtnPrimary from '../../components/Buttons/BtnPrimary';
import { FaMoneyBillTransfer } from "react-icons/fa6";
import { KEY, GetApiKeysAll, InfoBingX } from '../../types/key';
import { ApiResponse } from '../../types/api';
import api from '../../utils/api';
import { Toaster, toast } from 'sonner';
import { formatCurrency } from '../../utils/formatCurrency';
import { useApiKeysContext } from '../../context/ApiKeysContext';


interface ContentModalCenterProps {
  toggleModal: () => void;
  keyFrom: KEY;
  onHandle: (result: boolean) => void;
  keys: KEY[],
}

const ContentModalSend: React.FC<ContentModalCenterProps> = ({ toggleModal, keyFrom, onHandle, keys }) => {

  const [selectedApiKey, setSelectedApiKey] = useState<string | null>(null);
  const [amount, setAmount] = useState('');
  const [error, setError] = useState<string | null>(null);
  const isKeysFetchedRef = useRef(false);

  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setAmount(value);
  };

  const moreBalance = (keyItem: KEY | null) => {
      let accountInfo: InfoBingX | undefined
      if (keyItem?.exchange_name.toUpperCase() == "BYBIT") {
        accountInfo = keyItem.infoBybit;
      } else if (keyItem?.exchange_name.toUpperCase() == "GATE") {
        accountInfo = keyItem.infoGate;
      } else if (keyItem?.exchange_name.toUpperCase() == "MEXC") {
        accountInfo = keyItem.infoMexc;
      } else if (keyItem?.exchange_name.toUpperCase() == "BITGET") {
        accountInfo = keyItem.infoBitget;
      } else if (keyItem?.exchange_name.toUpperCase() == "BINANCE") {
        accountInfo = keyItem.infoBinance;
      } else if (keyItem?.exchange_name.toUpperCase() == "HUOBI") {
        accountInfo = keyItem.infoHuobi;
      } else if (keyItem?.exchange_name.toUpperCase() == "OKX") {
        accountInfo = keyItem.infoOKX;
      } else {
        accountInfo = keyItem?.infoBingX;
      }
      return accountInfo;
    }

  useEffect(() => {
    // Выполняем запрос на получение ключей при открытии модального окна, если они еще не были получены
    setSelectedApiKey(null);
  }, []);

  const handleApiKeyChange = (value: string) => {
    setSelectedApiKey(value);
  };

  const handleTransfer = async () => {
    console.log("keyFrom.id: ", keyFrom.id);
    console.log("selectedApiKey: ", selectedApiKey);
    console.log("amount: ", Number(amount));
    // toggleModal()
    if (!selectedApiKey) {
      setError('Please select an API key');
      return;
    }

    try {
      const response = await api.post(`/trade-api/transfer/${keyFrom.id}`, {
        // const response = await api.post(`http://127.0.0.1:8080/trade-api/transfer/${keyFrom.id}`, {
        toKeyID: selectedApiKey,
        amount: Number(amount)
      });

      if (response.data.code === 404) {
        return;
      }

      if (response.data.code === 200 && response.data.result) {
        toast.success('Transfered successfully');
        onHandle(true);
        toggleModal();
      } else {
        setError('Failed');
        onHandle(false);
      }
    } catch (error) {
      setError('Failed');
      onHandle(false);
    }
  };

  const transformKeysToOptions = (keys: KEY[]) => {
    return keys
      .filter(key => key.id !== keyFrom.id) // Исключаем keyFrom из списка
      .map(key => ({
        value: key.id,
        text: key.name
      }));
  };
  
  

  return (
    <>
      <svg
        className="mx-auto mb-4 text-gray-400 w-12 h-12 dark:text-gray-200"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 20 20"
      >
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
        />
      </svg>
      <p className="mb-2.5 text-black dark:text-white">Transfer from {keyFrom?.name}</p>
      <p className="mb-2.5 text-black dark:text-white">Balance {formatCurrency(moreBalance(keyFrom)?.accountFuture?.balance)}</p>s

      <SimpleSelect
        label='API Key'
        options={[{ value: '', text: 'Select option...' }, ...transformKeysToOptions(keys)]}
        value={selectedApiKey || ''}
        onChange={handleApiKeyChange}
        className="mb-4"
      />

      <InputField
        label="Amount"
        value={amount}
        onChange={handleAmountChange}
        placeholder="Amount"
        className="mb-4"
      />

      <BtnPrimary
        className='w-full'
        onClick={handleTransfer}
      >
        <FaMoneyBillTransfer /> Transfer USDT
      </BtnPrimary>
    </>
  );
};

export default ContentModalSend;