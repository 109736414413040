import React, { useReducer, useEffect, useContext } from 'react';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import ModalSideRight from '../../components/Modals/ModalSideRight';
import Task from './Task';
import api from '../../utils/api';
import Loader from '../../common/Loader/index';
import { TASK } from '../../types/task';
import { Toaster, toast } from 'sonner';
import TableTasks from '../../components/Tables/TableTasks';
import TaskFilters from './TaskFilters';
import { UserContext } from '../../context/UserContext';
import { TasksContext, TasksProvider } from '../../context/TasksContext';
import { BsPlusCircleFill } from "react-icons/bs";
import BtnPrimary from '../../components/Buttons/BtnPrimary';
import { ApiResponse } from '../../types/api';
import { GetTasksAll } from '../../types/task';

// Типы действий
type Action = 
  | { type: 'SET_TASKS'; payload: TASK[] }
  | { type: 'SET_TOTAL_COUNT'; payload: number }
  | { type: 'SET_LOADING'; payload: boolean }
  | { type: 'SET_MODAL_ADD_TASK'; payload: boolean }
  | { type: 'SET_FILTERED_TASKS'; payload: TASK[] }
  | { type: 'ADD_TO_FAVORITES'; payload: string }
  | { type: 'REMOVE_FROM_FAVORITES'; payload: string }
  | { type: 'SET_CURRENT_PAGE'; payload: number };

// Начальное состояние
const initialState = {
  tasks: [] as TASK[],
  totalCount: 0,
  isLoading: true,
  isModalAddTask: false,
  currentPage: 0,
};

const breadcrumbItems = [
  { name: 'Dashboard', link: '/dashboard' },
  { name: `Benchmark`, link: '/benchmark' }
];

// Редьюсер
const tasksReducer = (state: typeof initialState, action: Action) => {
  switch (action.type) {
    case 'SET_TASKS':
      return { ...state, tasks: action.payload, isLoading: false };
    case 'SET_TOTAL_COUNT':
      return { ...state, totalCount: action.payload };
    case 'SET_LOADING':
      return { ...state, isLoading: action.payload };
    case 'SET_MODAL_ADD_TASK':
      return { ...state, isModalAddTask: action.payload };
    case 'SET_FILTERED_TASKS':
      return { ...state, tasks: action.payload };
    case 'ADD_TO_FAVORITES':
      return {
        ...state,
        tasks: state.tasks.map(task => 
          task.id === action.payload ? { ...task, is_favorite: true } : task
        ),
      };
    case 'REMOVE_FROM_FAVORITES':
      return {
        ...state,
        tasks: state.tasks.map(task => 
          task.id === action.payload ? { ...task, is_favorite: false } : task
        ),
      };
    case 'SET_CURRENT_PAGE':
      return { ...state, currentPage: action.payload };
    default:
      return state;
  }
};

const Benchmark: React.FC = () => {
  const [state, dispatch] = useReducer(tasksReducer, initialState);
  const { uniqueSymbols } = useContext(TasksContext);  
  const { user } = useContext(UserContext); 

  const toggleModalAddTask = () => {
    dispatch({ type: 'SET_MODAL_ADD_TASK', payload: !state.isModalAddTask });
  };

  useEffect(() => {
    fetchTasks(state.currentPage);
  }, [state.currentPage]);

  const fetchTasks = async (page: number) => {
    try {
      const response = await api.get<ApiResponse<GetTasksAll>>(`/benchmark-api/tasks?page=${page + 1}&pageSize=25`);
  
      if (response.data.code !== 200) {
        throw new Error(response.data.error || 'Unknown error');
      }

      const tasks = response.data.result?.items || [];
      const totalCount = response.data.result?.totalCount || 0;
      dispatch({ type: 'SET_TASKS', payload: tasks });
      dispatch({ type: 'SET_TOTAL_COUNT', payload: totalCount });
    } catch (error) {
      console.log(error);
      toast.error('Failed to fetch tasks');
    } finally {
      dispatch({ type: 'SET_LOADING', payload: false });
    }
  };

  const handleSave = (result: boolean) => {
    if (result) {
      toast.success('Task added successfully');
      fetchTasks(state.currentPage); 
    } else {
      toast.error('Failed to add task');
    }
  };

  const handleTasksFiltered = (filteredTasks: TASK[]) => {
    dispatch({ type: 'SET_FILTERED_TASKS', payload: filteredTasks });
  };

  const addToFavorites = async (taskId: string) => {
    if (!user) return;
    try {
      await api.post('/add-favorite', { taskId });
      dispatch({ type: 'ADD_TO_FAVORITES', payload: taskId });
      toast.success('Task added to favorites');
    } catch (error) {
      toast.error('Failed to add task to favorites');
    }
  };

  const removeFromFavorites = async (taskId: string) => {
    if (!user) return;
    try {
      await api.delete('/remove-favorite', { data: { taskId } });
      dispatch({ type: 'REMOVE_FROM_FAVORITES', payload: taskId });
      toast.success('Task removed from favorites');
    } catch (error) {
      toast.error('Failed to remove task from favorites');
    }
  };

  const handlePageChange = (selectedItem: { selected: number }) => {
    dispatch({ type: 'SET_CURRENT_PAGE', payload: selectedItem.selected });
  };

  if (state.isLoading) {
    return <Loader />;
  }

  console.log("tasks: ", state.tasks)

  return (
    <TasksProvider>
      <Toaster position="bottom-center" richColors />
      <Breadcrumb items={breadcrumbItems} />

      <div className='mb-5 rounded-lg border border-stroke bg-white py-3 px-4.5 shadow-default dark:border-strokedark dark:bg-boxdark'>
        {/* <TaskFilters onTasksFiltered={handleTasksFiltered} uniqueSymbols={uniqueSymbols} /> */}
        <BtnPrimary onClick={toggleModalAddTask}> <BsPlusCircleFill /> Add task </BtnPrimary>
        <ModalSideRight
          isOpen={state.isModalAddTask}
          toggleModal={toggleModalAddTask}
          title="ADD TASK"
          content={
            <Task
              action="add"
              toggleModal={toggleModalAddTask}
              updateTasks={() => fetchTasks(state.currentPage)}
              onSave={handleSave}
            />
          }
        />
      </div>

      <TableTasks 
        tasks={state.tasks || []} 
        totalCount={state.totalCount} 
        fetchTasks={fetchTasks} 
        updateTasks={() => fetchTasks(state.currentPage)} 
        addToFavorites={addToFavorites} 
        removeFromFavorites={removeFromFavorites} 
        onPageChange={handlePageChange}
      />
    </TasksProvider>
  );
};

export default Benchmark;