import { useState, useEffect } from 'react';
import api from '../utils/api';
import { USER, GetUsersAll } from '../types/user';
import { ApiResponse } from '../types/api';
import { UserRoles } from '../types/user';
import { getCurrentUser } from '../utils/getCurrentUser'
import { toast } from 'sonner';
import { messages } from '../data/messages';

export const useUsers = () => {
  const [users, setUsers] = useState<USER[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [selectedUser, setSelectedUser] = useState<USER | null>(null);
  const [isModalEditOpen, setIsModalEditOpen] = useState(false);
  const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);
  const [isModalHistoryOpen, setIsModalHistoryOpen] = useState(false);
  const [isModalAddUserOpen, setIsModalAddUserOpen] = useState(false);

  useEffect(() => {
    getUsers();
  }, []);

  const getUsers = async () => {
    try {
      const response = await api.get<ApiResponse<GetUsersAll>>('/api/admin/user');
      if (response.data.code === 200 && response.data.result) {
        const currentUser = getCurrentUser();
        if (currentUser) {
          const filteredUsers = filterUsersByRole(response.data.result.items, currentUser);
          setUsers(filteredUsers);
        } else {
          setUsers(response.data.result.items);
        }
      } else {
        setError(messages.user.fetch.failed);
      }
    } catch (error) {
      setError(messages.user.fetch.error);
    } finally {
      setIsLoading(false);
    }
  };

  const createUser = async (user: Partial<USER>) => {
    try {
      const response = await api.post('/api/admin/user', user);
      if (response.data.code === 200 && response.data.result) {
        setUsers([...users, response.data.result]);
        return true;
      } else {
        setError(messages.user.create.failed);
        return false;
      }
    } catch (error) {
      setError(messages.user.create.error);
      return false;
    }
  };

  const updateUser = async (user: Partial<USER>) => {
    try {
      const response = await api.put(`/api/admin/user/${user.id}`, user);
      if (response.data.code === 200 && response.data.error === null) {
        setUsers(users.map(u => u.id === user.id ? response.data.result : u));
        return true;
      } else {
        setError(messages.user.update.failed);
        return false;
      }
    } catch (error) {
      setError(messages.user.update.error);
      return false;
    }
  };

  const deleteUser = async (userId: string) => {
    try {
      const response = await api.delete(`/api/admin/user/${userId}`);
      if (response.data.code === 200 && response.data.result) {
        setUsers(users.filter(u => u.id !== userId));
        return true;
      } else {
        setError(messages.user.delete.failed);
        return false;
      }
    } catch (error) {
      setError(messages.user.delete.error);
      return false;
    }
  };

  const toggleModalEdit = () => {
    setIsModalEditOpen(!isModalEditOpen);
  };

  const toggleModalDelete = () => {
    setIsModalDeleteOpen(!isModalDeleteOpen);
  };

  const toggleModalHistory = (user: USER) => {
    setSelectedUser(user);
    setIsModalHistoryOpen(!isModalHistoryOpen);
  };

  const toggleModalAddUser = () => {
    setIsModalAddUserOpen(!isModalAddUserOpen);
  };

  const handleEditUser = (user: USER) => {
    if (!isAdmin()) return;
    setSelectedUser(user);
    toggleModalEdit();
  };

  const handleDeleteUser = (user: USER) => {
    if (!isAdmin()) return;
    setSelectedUser(user);
    toggleModalDelete();
  };

  const handleDeleteConfirm = async (result: boolean) => {
    if (result && selectedUser) {
      try {
        const success = await deleteUser(selectedUser.id);
        if (success) {
          toast.success(messages.user.delete.successDeteted);
          getUsers(); 
        } else {
          toast.error(messages.user.delete.failedDeleted);
        }
      } catch (error) {
        setError(messages.user.delete.failedDeleted);
      }
    }
    toggleModalDelete();
  };

  const isAdmin = () => {
    const currentUser = getCurrentUser();
    return currentUser?.role === UserRoles.Admin;
  };

  return {
    users,
    isLoading,
    error,
    getUsers,
    createUser,
    updateUser,
    deleteUser,
    selectedUser,
    isModalEditOpen,
    isModalDeleteOpen,
    isModalHistoryOpen,
    isModalAddUserOpen,
    toggleModalEdit,
    toggleModalDelete,
    toggleModalHistory,
    toggleModalAddUser,
    handleEditUser,
    handleDeleteUser,
    handleDeleteConfirm,
    isAdmin,
  };
};

const filterUsersByRole = (users: USER[], currentUser: USER): USER[] => {
  switch (currentUser.role) {
    case UserRoles.Admin:
      return users;
    case UserRoles.Moderator:
      return users.filter(user => user.role === UserRoles.Moderator || user.role === UserRoles.User);
    case UserRoles.User:
      return users.filter(user => user.role === UserRoles.User);
    default:
      return [];
  }
};