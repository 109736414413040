export type USER = {
  id: string
  name: string
  description: string
  email: string
  password: string
  role: string
  created_at: string
  updated_at: string
}

export enum UserRoles {
  Admin = 'admin',
  Moderator = 'moderator',
  User = 'user',
}

export type GetUsersAll = {
  totalCount: number
  items: USER[]
}