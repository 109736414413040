import React, { useState } from 'react';
import { BsFillTrashFill, BsFillPencilFill, BsChevronDown, BsChevronUp, BsPlusCircleFill, BsClockHistory, BsThreeDotsVertical } from "react-icons/bs";
import { IoSettings } from "react-icons/io5";
import { GrDetach } from "react-icons/gr";
import { Toaster, toast } from 'sonner';
import NotFound from '../../../common/NotFound';
import Switcher from "../../../components/Switchers/Switcher";
import { Db_Bots } from '../../../types/botsAll';
import ModalSideRight from '../../../components/Modals/ModalSideRight';
import Bot from './Bot';
import { useNavigate } from 'react-router-dom';
import Pagination from '../../../components/Pagination/Pagination';
import CountPagination from './CountPagination';
import ModalCenter from '../../../components/Modals/ModalCenter';
import ContentConfirm from '../../../components/Modals/ContentConfirm';
import { formatCurrency } from '../../../utils/formatCurrency';
import BtnPrimary from '../../../components/Buttons/BtnPrimary';
import AttachBot from './AttachDetachBot';
import HistoryOrdersModal from '../../HistoryOrders/HistoryOrdersModal'
import { RiRadioButtonLine } from "react-icons/ri";
import { PiTrademarkFill } from "react-icons/pi";
import { formatAmount } from '../../../utils/formatAmount'
import BotTooltip from '../../../components/CustomTooltip/CustomTooltip'
import { FaComment } from "react-icons/fa";
import CommentForBot from './CommentForBot';
import { useBotsContext } from '../../../context/BotsAllContext';
import { InfoBingX } from "../../../types/key";
import ModalBottom from '../../../components/Modals/ModalBottom';


interface BotsAllProps {
  bots: Db_Bots[];
  updateBots: (page: number, pageSize: number, filters: {}) => void;
  totalCount: number;
  onPageChange: (selectedItem: { selected: number }) => void;
  currentPage: number;
  // pageSize?: number;
  // setPageSize: () => void;
}

const TableBotsAll: React.FC<BotsAllProps> = ({ bots, updateBots, totalCount, onPageChange, currentPage/*, pageSize, setPageSize*/ }) => {
  const {
    pageSize,
    handleActiveChange,
    isModalAttachKeyOpen,
    toggleModalAttachKey,
    handleAttachKey,
    botsAll,
    isModalEditOpen,
    toggleModalEdit,
    selectedBot,
    handleDetach,
    selectedKey,
    orderHistory,
    toggleModalDetach,
    handleOrderHistoryPageChange,
    handleHistoryOrdersKey,
    orderHistoryTotalCount,
    isModalDetach,
    handleEditBot,
    handleSaveUpdate,
    isModalConfirmOpen,
    isModalConfirmOpenEmergy,
    handleApiKeyDetach,
    toggleModalConfirm,
    toggleModalConfirmEmergy,
    handleActivateConfirm,
    handleActivateConfirmEmergy,
    setConfirmBotUuid,
    switcherStates,
    switcherStatesEmergy,
    handleCommentBot,
    orderHistoryPage,
    setSwitcherStates,
    setSwitcherStatesEmergy,
    setSelectedBot,
    isModalHistoryOrders,
    toggleModalHistoryOrders,
    isModalCommentOpen,
    toggleModalComment,
    handleActionsBot,
    toggleModalActions,
    isModalActionsOpen,
    handleFavoriteBot,
    toggleModalFavorite,
    isModalFavoriteOpen,
    handleFavoriteConfirm,
  } = useBotsContext();

  const [expandedRows, setExpandedRows] = useState<string[]>([]);
  const navigate = useNavigate();

  const handleNavigateToBotSettings = (bot: Db_Bots) => {
    if (bot?.api?.name) {
      navigate(`/bot/${bot?.uuid}`);
    } else {
      toast.error('First, bind the API key to the bot')
    }
  };

  const handleNavigateToManualTrading = (keyId: string, symbol: string, exchange_name: string) => {
    if (!symbol || symbol == "") {
      let sym = "BTC-USDT"
      if (exchange_name.toUpperCase() == "BINGX") {
        sym = "BTC-USDT"
      } else if (exchange_name.toUpperCase() == "BYBIT") {
        sym = "BTCUSDT"
      } else if (exchange_name.toUpperCase() == "GATE") {
        sym = "BTC_USDT"
      } else if (exchange_name.toUpperCase() == "MEXC") {
        sym = "BTCUSDT"
      } else if (exchange_name.toUpperCase() == "BITGET") {
        sym = "BTCUSDT"
      } else if (exchange_name.toUpperCase() == "BINANCE") {
        sym = "BTCUSDT"
      }
      navigate(`/manual-trading/${keyId}/${sym}`);
    } else {
      navigate(`/manual-trading/${keyId}/${symbol}`);
    }


  };

  // const [pageSize, setPageSize] = useState(() => {
  //   const savedPageSize = localStorage.getItem('botsPageSize');
  //   return savedPageSize ? parseInt(savedPageSize, 10) : 5;
  // })

  const pageCount = Math.ceil((totalCount || 0) / pageSize); // количество страниц

  const toggleRowExpansion = (id: string) => {
    setExpandedRows(prevExpandedRows =>
      prevExpandedRows.includes(id)
        ? prevExpandedRows.filter(rowId => rowId !== id)
        : [...prevExpandedRows, id]
    );
  };

  const handleSwitcherChange = async (bot: Db_Bots, isActive: boolean) => {
    if (!bot.api.name) {
      toast.error('First, bind the key to the bot')
      return
    }

    const botResult = botsAll.find(bot => bot.uuid === bot.uuid);
    if (botResult) {
      setSelectedBot(botResult);
    }
    setConfirmBotUuid(bot.uuid);
    toggleModalConfirm();
  };

  const handleSwitcherChangeEmergy = async (bot: Db_Bots, isActive: boolean) => {
    if (!bot.api.name) {
      toast.error('First, bind the key to the bot')
      return
    }

    const botResult = botsAll.find(bot => bot.uuid === bot.uuid);
    if (botResult) {
      setSelectedBot(botResult);
    }
    setConfirmBotUuid(bot.uuid);
    toggleModalConfirmEmergy();
  };

  const handleDetachConfirm = (botId: string) => {
    setSelectedBot(botsAll.find(bot => bot._id === botId) || null);
    toggleModalConfirm();
  };

  // Функция для расчета суммы позиций и количества ордеров для выбранного символа
  const calculatePositionsAndOrders = (bot: Db_Bots) => {
    // if (!bot || !bot?.settings?.symbol) {
    //   return { totalPositionValue: 0, openOrdersCount: 0 };
    // }

    // let positions = []
    // let openOrder = []
    // if (bot?.api?.exchange_name.toUpperCase() == "BINGX" && !bot?.api?.infoBingX) {
    //   return { totalPositionValue: 0, openOrdersCount: 0 };
    // } else if (bot?.api?.exchange_name.toUpperCase() == "BYBIT" && !bot?.api?.infoBybit) {
    //   return { totalPositionValue: 0, openOrdersCount: 0 };
    // } else if (bot?.api?.exchange_name.toUpperCase() == "GATE" && !bot?.api?.infoGate) {
    //   return { totalPositionValue: 0, openOrdersCount: 0 };
    // } else if (bot?.api?.exchange_name.toUpperCase() == "MEXC" && !bot?.api?.infoMexc) {
    //   return { totalPositionValue: 0, openOrdersCount: 0 };
    // } else if (bot?.api?.exchange_name.toUpperCase() == "BITGET" && !bot?.api?.infoBitget) {
    //   return { totalPositionValue: 0, openOrdersCount: 0 };
    // } else if (bot?.api?.exchange_name.toUpperCase() == "BINANCE" && !bot?.api?.infoBinance) {
    //   return { totalPositionValue: 0, openOrdersCount: 0 };
    // }

    // if (bot?.api?.exchange_name.toUpperCase() == "BYBIT") {
    //   // positions = bot?.api?.infoBybit.positions || [];
    //   openOrder = bot?.api?.infoBybit.openOrder || [];
    // } else if (bot?.api?.exchange_name.toUpperCase() == "GATE") {
    //   // positions = bot?.api?.infoGate.positions || [];
    //   openOrder = bot?.api?.infoGate.openOrder || [];
    // } else if (bot?.api?.exchange_name.toUpperCase() == "MEXC") {
    //   // positions = bot?.api?.infoMexc.positions || [];
    //   openOrder = bot?.api?.infoMexc.openOrder || [];
    // } else if (bot?.api?.exchange_name.toUpperCase() == "BITGET") {
    //   // positions = bot?.api?.infoBitget.positions || [];
    //   openOrder = bot?.api?.infoBitget.openOrder || [];
    // } else if (bot?.api?.exchange_name.toUpperCase() == "BINANCE") {
    //   // positions = bot?.api?.infoBinance.positions || [];
    //   openOrder = bot?.api?.infoBinance.openOrder || [];
    // } else {
    //   // positions = bot?.api?.infoBingX.positions || [];
    //   openOrder = bot?.api?.infoBingX.openOrder || [];
    // }

    let positions = bot?.api?.account?.futurePositions || [];
    let openOrder = bot?.api?.account?.futureOpenOrder || [];

    // const filteredPositions = positions.filter(position => position.symbol === bot?.settings?.symbol);
    // const filteredOpenOrders = openOrder.filter(order => order.symbol === bot?.settings?.symbol);

    // const totalPositionValue = filteredPositions.reduce((sum, position) => sum + parseFloat(position.positionValue || '0'), 0);
    const totalPositionValue = positions.reduce((sum, position) => sum + position.notional, 0);
    const openOrdersCount = openOrder.length;
    const positionsCount = positions.length;

    return { totalPositionValue, openOrdersCount, positionsCount };
  };



  return (
    <>
      <Toaster position="bottom-center" richColors />
      <div className="overflow-hidden rounded-[10px] mb-4">
        <div className="max-w-full overflow-x-auto">
          <div className="w-full sm:min-w-[1170px]">

            <div className="hidden sm:grid grid-cols-11 gap-2 bg-[#F9FAFB] px-1 py-5 dark:bg-meta-4 lg:px-7.5 2xl:px-11">
              <div className="col-span-3 flex justify-center items-center">
                <h5 className="font-medium text-[#637381] dark:text-bodydark">NAME</h5>
              </div>
              {/* <div className="col-span-1">
                <h5 className="font-medium text-[#637381] dark:text-bodydark">ACTIVE</h5>
              </div> */}
              {/* <div className="col-span-1">
                <h5 className="font-medium text-[#637381] dark:text-bodydark">DATE</h5>
              </div> */}
              <div className="col-span-3 flex justify-center">
                <h5 className="font-medium text-[#637381] dark:text-bodydark">TYPE</h5>
              </div>
              <div className="col-span-2">
                <h5 className="font-medium text-[#637381] dark:text-bodydark">API</h5>
              </div>
              <div className="col-span-2">
                <h5 className="font-medium text-[#637381] dark:text-bodydark">POS/ORD</h5>
              </div>
              <div className="col-span-1 flex justify-center items-center">
                <h5 className="font-medium text-[#637381] dark:text-bodydark">ACTIONS</h5>
              </div>
            </div>

            <div className="bg-white dark:bg-boxdark">

              {bots && bots.length === 0 ? (
                <NotFound />
              ) : (bots && bots.map((bot, key) => {
                const isExpanded = expandedRows.includes(bot._id);
                const switcherState = switcherStates[bot.uuid] !== undefined ? switcherStates[bot.uuid] : bot.active;
                const switcherStateEmergy = switcherStatesEmergy[bot.uuid] !== undefined ? switcherStatesEmergy[bot.uuid] : bot.activeEmergy;

                const currentTime = Date.now();
                const timeDifference = currentTime - new Date(bot.dateConnect).getTime();
                const isOnline = timeDifference <= 10000;

                const { totalPositionValue, openOrdersCount, positionsCount } = calculatePositionsAndOrders(bot);

                const botName = {
                  name: bot.name,
                };

                let accountInfoOld: InfoBingX
                if (bot?.api?.exchange_name.toUpperCase() == "BYBIT") {
                  accountInfoOld = bot?.api?.infoBybit;
                } else if (bot?.api?.exchange_name.toUpperCase() == "GATE") {
                  accountInfoOld = bot?.api?.infoGate;
                } else if (bot?.api?.exchange_name.toUpperCase() == "MEXC") {
                  accountInfoOld = bot?.api?.infoMexc;
                } else if (bot?.api?.exchange_name.toUpperCase() == "BITGET") {
                  accountInfoOld = bot?.api?.infoBitget;
                } else if (bot?.api?.exchange_name.toUpperCase() == "BINANCE") {
                  accountInfoOld = bot?.api?.infoBinance;
                } else {
                  accountInfoOld = bot?.api?.infoBingX;
                }

                let accountInfo = bot?.api?.account

                return (
                  <div className="border-t border-[#EEEEEE] dark:border-strokedark" key={key}>
                    <div className="grid grid-cols-2 sm:grid-cols-11 gap-4 px-5 py-4 lg:px-7.5">
                      <div className="col-span-1 flex sm:hidden items-center">
                        <h5 className="font-medium text-[#637381] dark:text-bodydark">NAME</h5>
                      </div>
                      <div className="col-span-1 sm:col-span-3">
                        {/* <div className='flex items-left gap-1 relative'> */}
                        <div className="flex gap-2 items-center relative flex-wrap sm:flex-nowrap">
                          <p className="text-[#637381] dark:text-bodydark">{currentPage * pageSize + key + 1}</p>
                          <RiRadioButtonLine
                            className={`${isOnline ? 'text-green-500' : 'text-red-500'}`}
                            title="Status"
                          />
                          {
                            bot?.favorite ?
                              <svg className="h-5 w-5 text-primary" fill="currentColor" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z" />
                              </svg>
                              :
                              <svg className="h-5 w-5 text-slate-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z" />
                              </svg>
                          }
                          <div className="grow w-max">
                            <BotTooltip bot={botName} />
                          </div>
                          <Switcher
                            isActive={switcherState}
                            onChange={() => handleSwitcherChange(bot, switcherState)}
                            id={`isActiveBot-${bot.uuid}`}
                          />

                          <div className="w-full sm:w-min shrink">
                            {bot?.active ?
                              <span className={`${bot?.dateActive === 0 ? "whitespace-nowrap" : ""}`}>{bot?.dateActive === 0 ? "------" : new Date(bot.dateActive).toLocaleString()}</span>
                              :
                              <span className={`${bot?.dateStop === 0 ? "whitespace-nowrap" : ""}`}>{bot?.dateStop === 0 ? "------" : new Date(bot.dateStop).toLocaleString()}</span>
                            }
                          </div>
                        </div>
                      </div>

                      {/* <div className="col-span-1 flex items-start gap-2">
                        <Switcher
                          isActive={switcherState}
                          onChange={() => handleSwitcherChange(bot, switcherState)}
                          id={`isActiveBot-${bot.uuid}`}
                        />

                        <ModalCenter
                          isOpen={isModalConfirmOpen}
                          toggleModal={toggleModalConfirm}
                          content={
                            <ContentConfirm
                              toggleModal={toggleModalConfirm}
                              text="Are you sure you want to change the bot status?"
                              onHandle={handleActivateConfirm}
                              updateItemsPagination={() => updateBots(currentPage)}
                            />
                          }
                        />
                      </div> */}

                      {/* <div className="col-span-1 flex items-start">
                        {bot?.active ?
                          bot?.dateActive === 0 ? "------" : new Date(bot.dateActive).toLocaleString()
                          :
                          bot?.dateStop === 0 ? "------" : new Date(bot.dateStop).toLocaleString()
                        }
                      </div> */}

                      <div className="col-span-1 flex sm:hidden">
                        <h5 className="font-medium text-[#637381] dark:text-bodydark">TYPE</h5>
                      </div>
                      <div className='col-span-1 sm:col-span-3 flex items-start gap-2'>
                        <div>
                          <FaComment
                            className={`${bot?.comment && 'text-primary'} cursor-pointer duration-300 ease-in-out hover:text-primary text-xl`}
                            onClick={() => { handleCommentBot(bot) }}
                          />
                        </div>

                        {bot.settings?.strategy ?
                          <div className={`flex flex-col items-start justify-start overflow-hidden transition-all duration-300 ${isExpanded ? 'max-h-96' : 'max-h-18'}`}>
                            <p className='text-[#637381] dark:text-bodydark'>{`${bot.settings?.strategy} ${bot.settings?.symbol}`}</p>
                            {/* <p className='text-[#637381] dark:text-bodydark'>{`${bot.settings?.symbol}`}</p> */}
                            <p className={`text-[#637381] dark:text-bodydark whitespace-normal`}>{`${bot?.comment}`}</p>

                          </div>
                          :
                          <div className={`flex flex-col items-start justify-start overflow-hidden transition-all duration-300 ${isExpanded ? 'max-h-96' : 'max-h-18'}`}>
                            <p className='text-[#637381] dark:text-bodydark'>--------</p>
                            <p className={`text-[#637381] dark:text-bodydark whitespace-normal`}>{`${bot?.comment}`}</p>
                          </div>

                        }
                      </div>

                      <div className="col-span-1 sm:hidden">
                        <h5 className="font-medium text-[#637381] dark:text-bodydark">API</h5>
                      </div>
                      <div className={`col-span-1 sm:col-span-2 flex flex-col items-start justify-start overflow-hidden transition-all duration-300`}>
                        {bot?.api?.name ?
                          <>
                            <div className='flex items-center gap-2'>
                              {bot.api.name &&
                                <button
                                  className='flex items-center gap-2 transition duration-300 ease-in-out rounded-lg py-1 px-1 font-medium hover:bg-opacity-90 bg-primary text-white w-auto'
                                  onClick={() => handleDetach(bot)}
                                >
                                  <GrDetach />
                                </button>
                              }
                              <p className="text-[#637381] dark:text-bodydark font-bold">{bot?.api?.name}</p>
                            </div>
                            {
                              accountInfo?.futureBalance?.balance > 0
                                ?
                                <p className="text-[#637381] dark:text-bodydark">Wallet balance:{bot?.api?.asset == "BTC" ? formatAmount(accountInfo?.futureBalance?.balance) + " BTC" : formatCurrency(accountInfo?.futureBalance?.balance)}</p>
                                :
                                null
                            }

                            <div className={` flex flex-col items-start justify-start overflow-hidden transition-all duration-300 ${isExpanded ? 'max-h-96' : 'max-h-6'}`}>
                              <p className="text-[#637381] dark:text-bodydark">Available Balance: {bot?.api?.asset == "BTC" ? formatAmount(accountInfo?.futureBalance?.availableBalance) + " BTC" : formatCurrency(accountInfo?.futureBalance?.availableBalance)} </p>
                              {/* <p className="text-[#637381] dark:text-bodydark">Used Margin: {formatCurrency(accountInfo?.accountFuture?.usedMargin)}</p> */}
                              <p className="text-[#637381] dark:text-bodydark">Unrealized Profit: {bot?.api?.asset == "BTC" ? formatAmount(accountInfo?.futureBalance?.unrealizedProfit) + " BTC" : formatCurrency(accountInfo?.futureBalance?.unrealizedProfit)} </p>
                            </div>
                            {/* {isExpanded && (
                              <div>
                                <p className="text-[#637381] dark:text-bodydark">Available Balance: {formatCurrency(parseFloat(bot.api?.infoBingX?.accountFuture?.balance) - parseFloat(bot.api?.infoBingX?.accountFuture?.usedMargin))}</p>
                                <p className="text-[#637381] dark:text-bodydark">Used Margin: {formatCurrency(bot.api?.infoBingX?.accountFuture?.usedMargin)}</p>
                                <p className="text-[#637381] dark:text-bodydark">Unrealized Profit: {formatCurrency(bot.api?.infoBingX?.accountFuture?.unrealizedProfit)}</p>
                              </div>
                            )} */}
                          </> :
                          <>
                            <BtnPrimary
                              className='w-auto'
                              onClick={() => {
                                setSelectedBot(bot);
                                toggleModalAttachKey();
                              }}
                            >
                              <BsPlusCircleFill /> Attach API Key
                            </BtnPrimary>
                          </>
                        }
                      </div>

                      <div className="col-span-1 sm:hidden">
                        <h5 className="font-medium text-[#637381] dark:text-bodydark">POS/ORD</h5>
                      </div>
                      <div className={`col-span-1 sm:col-span-2 flex flex-col items-start overflow-hidden transition-all duration-300 ${isExpanded ? 'max-h-96' : 'max-h-12'}`}>
                        {bot?.apiID != "000000000000000000000000" ?
                          <>
                            <p className="text-[#637381] dark:text-bodydark">{`${formatCurrency(totalPositionValue)} (${positionsCount}) / ${openOrdersCount}`} </p>
                            {isExpanded &&
                              ((bot?.api?.account?.futurePositions || []).map((pos, key) => {
                                // if (pos.symbol != bot?.settings?.symbol) {
                                //   return null
                                // }
                                return (
                                  <div className='mt-2'>
                                    <p className="text-[#637381] dark:text-bodydark">{pos.positionSide}: {formatAmount(pos.positionAmt)} {pos.symbol} ({formatCurrency(pos.notional)})</p>
                                    <p className="text-[#637381] dark:text-bodydark">Unrealized Profit:
                                      {bot?.api?.asset == "BTC" ? formatAmount(pos.unRealizedProfit) + " BTC" : formatCurrency(pos.unRealizedProfit)}
                                    </p>
                                    <p className="text-[#637381] dark:text-bodydark">AVG Price: {formatCurrency(pos.entryPrice)}</p>
                                    {/* <p className="text-[#637381] dark:text-bodydark">Risk Rate: {pos.riskRate}</p> */}
                                  </div>
                                )
                              }))}
                          </> :
                          <p className='text-[#637381] dark:text-bodydark'>--------</p>
                        }
                      </div>

                      <div className="col-span-2 sm:col-span-1 flex justify-end items-start gap-1">
                        {
                          (bot?.api?.subAccountId || bot?.api?.public_key) &&
                          <>
                            {/* <BsClockHistory
                              className="cursor-pointer duration-300 ease-in-out hover:text-primary text-xl"
                              onClick={() => {
                                let sID = bot.api.subAccountId
                                if (!bot.api.subAccountId || bot.api.subAccountId == "") {
                                  sID = bot?.api?.public_key
                                }
                                handleHistoryOrdersKey(sID)
                              }}
                            /> */}
                          </>
                        }

                        {/* {bot?.apiID != "000000000000000000000000" &&
                          <PiTrademarkFill
                            className="cursor-pointer duration-300 ease-in-out hover:text-primary text-xl"
                            onClick={() => handleNavigateToManualTrading(bot?.apiID, bot?.settings?.symbol, bot?.api?.exchange_name)}
                          />
                        } */}

                        {/* <BsFillPencilFill
                          className="cursor-pointer duration-300 ease-in-out hover:text-primary text-xl"
                          onClick={() => handleEditBot(bot)}
                        /> */}

                        {/* <IoSettings
                          className="cursor-pointer duration-300 ease-in-out hover:text-primary text-xl"
                          onClick={() => handleNavigateToBotSettings(bot)}
                        /> */}
                        {
                          bot.settings?.strategy == "FullLimitV3" || bot.settings?.strategy == "AutoLimit" || bot.settings?.strategy == "AutoLimitBTC"
                            ?
                            <Switcher
                              isActive={switcherStateEmergy}
                              onChange={() => handleSwitcherChangeEmergy(bot, switcherStateEmergy)}
                              id={`isActiveBotEmergy-${bot.uuid}`}
                              classNameWrap="w-full max-w-[46px] h-min transform -translate-y-1/4 scale-[0.8] mr-[10px]"
                              textTitle="Emergy"
                            />
                            :
                            null
                        }


                        <BsThreeDotsVertical
                          className="shrink-0 cursor-pointer duration-300 ease-in-out hover:text-primary text-xl"
                          onClick={() => { handleActionsBot(bot) }}
                          title="Actions"
                        />

                        {isExpanded ? (
                          <BsChevronUp
                            className="shrink-0 cursor-pointer duration-300 ease-in-out hover:text-primary text-xl"
                            onClick={() => toggleRowExpansion(bot._id)}
                            title="Collapse line"
                          />
                        ) : (
                          <BsChevronDown
                            className="shrink-0 cursor-pointer duration-300 ease-in-out hover:text-primary text-xl"
                            onClick={() => toggleRowExpansion(bot._id)}
                            title="Expand line"
                          />
                        )}
                      </div>
                    </div>
                  </div>
                );
              }))}

            </div>

            <ModalCenter
              isOpen={isModalConfirmOpen}
              toggleModal={toggleModalConfirm}
              content={
                <ContentConfirm
                  toggleModal={toggleModalConfirm}
                  text="Are you sure you want to change the bot status?"
                  onHandle={handleActivateConfirm}
                  updateItems={() => updateBots(currentPage, pageSize, {})}
                />
              }
            />

            <ModalCenter
              isOpen={isModalConfirmOpenEmergy}
              toggleModal={toggleModalConfirmEmergy}
              content={
                <ContentConfirm
                  toggleModal={toggleModalConfirmEmergy}
                  text="Are you sure you want to change Emergy status?"
                  onHandle={handleActivateConfirmEmergy}
                  updateItems={() => updateBots(currentPage, pageSize, {})}
                />
              }
            />

            <ModalSideRight
              isOpen={isModalCommentOpen}
              toggleModal={toggleModalComment}
              title="COMMENT FOR BOT"
              content={
                selectedBot && (
                  <CommentForBot
                    updateBots={() => updateBots(currentPage, pageSize, {})}
                    toggleModal={toggleModalComment}
                    uuidBot={selectedBot.uuid}
                    currentPage={currentPage}
                    commentDefault={selectedBot.comment}
                  />
                )
              }
            />

            <ModalCenter
              isOpen={isModalDetach}
              toggleModal={toggleModalDetach}
              content={
                selectedBot && (
                  <ContentConfirm
                    toggleModal={toggleModalDetach}
                    text={`Are you sure you want to disable the ${selectedBot?.name} bot?`}
                    onHandle={handleApiKeyDetach}
                    updateItemsPagination={() => updateBots(currentPage, pageSize, {})}
                  />
                )
              }
            />

            <ModalCenter
              isOpen={isModalAttachKeyOpen}
              toggleModal={toggleModalAttachKey}
              content={
                selectedBot && (
                  <AttachBot
                    bot={selectedBot}
                    onAttach={(result) => {
                      handleAttachKey(result);
                      updateBots(currentPage, pageSize, {});
                    }}
                    onClose={toggleModalAttachKey}
                  />
                )
              }
            />

            <ModalSideRight
              isOpen={isModalHistoryOrders}
              toggleModal={toggleModalHistoryOrders}
              title="History Orders"
              content={
                <HistoryOrdersModal
                  isOpen={isModalHistoryOrders}
                  toggleModal={toggleModalHistoryOrders}
                  orderHistory={orderHistory}
                  orderHistoryPage={orderHistoryPage}
                  orderHistoryTotalCount={orderHistoryTotalCount}
                  handleOrderHistoryPageChange={handleOrderHistoryPageChange}
                />
              }
            />

            <ModalSideRight
              isOpen={isModalEditOpen}
              toggleModal={toggleModalEdit}
              title="EDIT BOT"
              content={
                selectedBot && (
                  <Bot
                    toggleModal={toggleModalEdit}
                    item={selectedBot}
                    onSave={handleSaveUpdate}
                    updateBots={() => updateBots(currentPage, pageSize, {})} />
                )
              }
            />

            <ModalCenter
              isOpen={isModalFavoriteOpen}
              toggleModal={toggleModalFavorite}
              content={
                selectedBot && (
                  <ContentConfirm
                    toggleModal={toggleModalFavorite}
                    text="Are you sure you want to toggle this bot in Favorites?"
                    onHandle={handleFavoriteConfirm}
                  // updateItems={updateBots(currentPage, {})}
                  />
                )
              }
            />

            <ModalBottom
              isOpen={isModalActionsOpen}
              toggleModal={toggleModalActions}
              content={
                <>
                  {selectedBot ?
                    <>
                      {
                        (selectedBot?.api?.subAccountId || selectedBot?.api?.public_key) &&
                        <>
                          <li className="w-full border-b border-solid bg-white dark:bg-[#5A616B]  outline-none dark:text-white hover:text-primary hover:bg-gray-2 dark:border-strokedark dark:hover:bg-primary cursor-pointer">
                            <span
                              className="flex w-full p-4 justify-center"
                              onClick={() => {
                                toggleModalActions();
                                let sID = selectedBot.api.subAccountId
                                if (!selectedBot.api.subAccountId || selectedBot.api.subAccountId == "") {
                                  sID = selectedBot?.api?.public_key
                                }
                                handleHistoryOrdersKey(sID)
                              }}
                            >History Orders</span>
                          </li>
                        </>
                      }
                      {selectedBot?.apiID != "000000000000000000000000" &&
                        <li className="w-full border-b border-solid bg-white dark:bg-[#5A616B]  outline-none dark:text-white hover:text-primary hover:bg-gray-2 dark:border-strokedark dark:hover:bg-primary cursor-pointer">
                          <span
                            className="flex w-full p-4 justify-center"
                            onClick={() => { toggleModalActions(); handleNavigateToManualTrading(selectedBot?.apiID, selectedBot?.settings?.symbol, selectedBot?.api?.exchange_name) }}
                          >Manual trading</span>
                        </li>
                      }
                      <li className="w-full border-b border-solid bg-white dark:bg-[#5A616B]  outline-none dark:text-white hover:text-primary hover:bg-gray-2 dark:border-strokedark dark:hover:bg-primary cursor-pointer">
                        <span
                          className="flex w-full p-4 justify-center"
                          onClick={() => { toggleModalActions(); handleEditBot(selectedBot) }}
                        >Edit bot</span>
                      </li>
                      <li
                        className="w-full border-b border-solid bg-white dark:bg-[#5A616B]  outline-none dark:text-white hover:text-primary hover:bg-gray-2 dark:border-strokedark dark:hover:bg-primary cursor-pointer"
                        onClick={() => { toggleModalActions(); handleNavigateToBotSettings(selectedBot) }}
                      >
                        <span className="flex w-full p-4 justify-center">Bot settings</span>
                      </li>
                      <li
                        className="w-full border-b border-solid bg-white dark:bg-[#5A616B]  outline-none dark:text-white hover:text-primary hover:bg-gray-2 dark:border-strokedark dark:hover:bg-primary cursor-pointer"
                        onClick={() => { toggleModalActions(); handleFavoriteBot(selectedBot) }}
                      >
                        <span className="flex w-full p-4 justify-center">to Favorites</span>
                      </li>
                    </>
                    : null
                  }
                </>
              }
              data={{ type: "bot", value: selectedBot }}
            />

            <ModalSideRight
              isOpen={isModalEditOpen}
              toggleModal={toggleModalEdit}
              title="EDIT BOT"
              content={
                selectedBot && (
                  <Bot
                    toggleModal={toggleModalEdit}
                    item={selectedBot}
                    onSave={handleSaveUpdate}
                    updateBots={() => updateBots(currentPage, pageSize, {})}
                  />
                )
              }
            />

          </div>
        </div>
      </div>

      {bots && bots.length === 0 ? <></> :
        <div className='flex flex-wrap items-center gap-4 mt-4'>
          <Pagination
            pageCount={pageCount}
            onPageChange={onPageChange}
          />
          <CountPagination />
        </div>
      }

    </>
  );
};

export default TableBotsAll;