import React, { createContext, useContext, useState, useEffect, useCallback, Dispatch, SetStateAction } from 'react';
import api from '../utils/api';
import { KEY, GetApiKeysAll, OrderHistory, GetOrderHistoryAll, AccountSpot } from '../types/key';
import { ApiResponse } from '../types/api';
import { toast } from 'sonner';

interface ApiKeysContextProps {
  keys: KEY[];
  keysAll: KEY[];
  keysPerPage: number;
  pageSize: number;
  totalCount: number;
  currentPage: number;
  isModalDeleteOpen: boolean;
  isModalCloseOrders: boolean;
  isModalFavoriteOpen: boolean;
  isModalActionsOpen: boolean;
  isModalEditOpen: boolean;
  isModalHistoryOrders: boolean;
  selectedKey: KEY | null;
  orderHistory: OrderHistory[];
  orderHistoryPage: number;
  orderHistoryTotalCount: number;
  isModalTransfer: boolean;
  isModalMoreBalance: boolean;
  setKeys: Dispatch<SetStateAction<KEY[]>>; // Обновленный тип
  fetchKeys: (page: number, pageSize: number, filters: {}) => void;
  // fetchKeysFiltered: (filters: {}) => Promise<void>;
  handlePageChange: (selectedItem: { selected: number }) => void;
  toggleModalDelete: () => void;
  toggleModalCloseOrders: () => void;
  toggleModalActions: () => void;
  toggleModalEdit: () => void;
  toggleModalFavorite: () => void;
  toggleModalHistoryOrders: () => void;
  handleDeleteKey: (key: KEY) => void;
  handleCloseOrders: (key: KEY) => void;
  handleActionsKey: (key: KEY) => void;
  deleteKey: (id: string) => Promise<boolean>;
  handleDeleteConfirm: (result: boolean) => void;
  handleCloseOrdersConfirm: (result: boolean) => void;
  handleFavoriteConfirm: (result: boolean) => void;
  handleSaveUpdate: (result: boolean) => void;
  handleEditKey: (key: KEY) => void;
  handleFavoriteKey: (key: KEY) => void;
  fetchOrderHistory: (subAccountId: string, page: number) => void;
  handleHistoryOrdersKey: (key: KEY) => void;
  handleTransferOpen: (key: KEY) => void;
  handleTransfer: (result: boolean) => void;
  toggleModalTransfer: () => void;
  handleOrderHistoryPageChange: (selectedItem: { selected: number }) => void;
  setPageSize: React.Dispatch<React.SetStateAction<number>>;
  handleMoreOpen: (key: KEY, spot: AccountSpot[]) => void;
  handleCommentKey: (key: KEY) => void;
  toggleModalMoreBalance: () => void;
  setIsModalCommentOpen: Dispatch<SetStateAction<boolean>>;
  isModalCommentOpen: boolean;
  toggleModalComment: () => void;
  resetFilter: () => void;
  selectedApiKeys: string[],
  setSelectedApiKeys: any;
  selectedMarket: string,
  setSelectedMarket: React.Dispatch<React.SetStateAction<string>>;
  selectedFavorite: boolean;
  setSelectedFavorite: React.Dispatch<React.SetStateAction<boolean>>;
  selectedBalance: boolean;
  setSelectedBalance: React.Dispatch<React.SetStateAction<boolean>>;
  selectedPositions: boolean;
  setSelectedPositions: React.Dispatch<React.SetStateAction<boolean>>;
  selectedName: string;
  setSelectedName: React.Dispatch<React.SetStateAction<string>>;
  isVisibleResetFilter: boolean;
  setIsVisibleResetFilter: React.Dispatch<React.SetStateAction<boolean>>;
}

const ApiKeysContext = createContext<ApiKeysContextProps | undefined>(undefined);

export const useApiKeysContext = () => {
  const context = useContext(ApiKeysContext);
  if (!context) {
    throw new Error('useApiKeysContext must be used within an ApiKeysProvider');
  }
  return context;
};

export const ApiKeysProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [keys, setKeys] = useState<KEY[]>([]);
  const [keysAll, setKeysAll] = useState<KEY[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);
  const [isModalCloseOrders, setIsModalCloseOrders] = useState(false);
  const [isModalFavoriteOpen, setIsModalFavoriteOpen] = useState(false);
  const [isModalActionsOpen, setIsModalActionsOpen] = useState(false);
  const [isModalEditOpen, setIsModalEditOpen] = useState(false);
  const [isModalHistoryOrders, setIsModalHistoryOrders] = useState(false);
  const [selectedKey, setSelectedKey] = useState<KEY | null>(null);
  const [orderHistory, setOrderHistory] = useState<OrderHistory[]>([]);
  const [orderHistoryPage, setOrderHistoryPage] = useState(0);
  const [orderHistoryTotalCount, setOrderHistoryTotalCount] = useState(0);
  const [isModalTransfer, setIsModalTransfer] = useState(false);
  const [isModalMoreBalance, setIsModalMoreBalance] = useState(false);
  const [isModalCommentOpen, setIsModalCommentOpen] = useState(false);
  const [selectedApiKeys, setSelectedApiKeys] = useState<string[]>([]);
  const [selectedMarket, setSelectedMarket] = useState<string>('');
  const [selectedFavorite, setSelectedFavorite] = useState<boolean>(false);
  const [selectedBalance, setSelectedBalance] = useState<boolean>(false);
  const [selectedPositions, setSelectedPositions] = useState<boolean>(false);
  const [selectedName, setSelectedName] = useState<string>('');
  const [isVisibleResetFilter, setIsVisibleResetFilter] = useState<boolean>(false)

  const toggleModalTransfer = () => setIsModalTransfer(!isModalTransfer);
  const toggleModalMoreBalance = () => setIsModalMoreBalance(!isModalMoreBalance);

  const orderHistoryPerPage = 10;
  const keysPerPage = 3; /** == pageSize */
  const keysPerPageDropdown = 100;

  const [pageSize, setPageSize] = useState(() => {
    const savedPageSize = localStorage.getItem('keysPageSize');
    return savedPageSize ? parseInt(savedPageSize, 10) : 5;
  });

  useEffect(() => {
    fetchKeys(currentPage, pageSize, {}); //** keysPerPage */
  }, [currentPage]);

  useEffect(() => {
    fetchKeysALL(currentPage)
  }, []);

  useEffect(() => {
    localStorage.setItem('keysPageSize', pageSize.toString());
  }, [pageSize]);

  const fetchKeys = useCallback(async (page: number, pageSize: number, filters: {}) => {
    Object.keys(filters).length === 0 ? setIsVisibleResetFilter(true) : setIsVisibleResetFilter(false);
    try {
      const accessToken = localStorage.getItem('accessToken');
      const response = await api.post<ApiResponse<GetApiKeysAll>>(`/api/api_keys/search`, {
        "filters": filters,
        "pagination": {
          "page": page + 1,
          "limit": pageSize
        }
      }
      );
      if (response.data && response.data.result) {
        setKeys(response.data.result.items);
        setTotalCount(response.data.result.totalCount);
      } else {
        setKeys([]);
        setTotalCount(0);
      }
    } catch (error) {
      console.error('Error fetching keys:', error);
    }
  }, [api, pageSize, setKeys, setTotalCount]);

  const fetchKeysALL = useCallback(async (page: number) => {
    try {
      const response = await api.post<ApiResponse<GetApiKeysAll>>(`/api/api_keys/search`, {
        "pagination": {
          "page": page + 1,
          "limit": 100
        }
      }
      );

      if (response.data && response.data.result) {
        setKeysAll(response.data.result.items);
      } else {
        setKeys([]);
      }
    } catch (error) {
      console.error('Error fetching keys:', error);
    }
  }, [api, pageSize, setKeys, setTotalCount]);

  // const fetchKeysFiltered = useCallback(async (filters: {}) => {
  //   try {
  //     const response = await api.post<ApiResponse<GetApiKeysAll>>(`/api/api_keys/search`, {
  //       "filters": filters,
  //       // "sort": {
  //       //   "field": "created_at",
  //       //   "order": "asc"
  //       // },
  //       "pagination": {
  //         "page": 1,
  //         "limit": 100
  //       }
  //     }
  //     );
  //     if (response.data && response.data.result) {
  //       debugger
  //       setKeysAll(response.data.result.items);
  //     } else {
  //       debugger
  //       setKeys([]);
  //     }
  //   } catch (error) {
  //     console.error('Error fetching keys:', error);
  //   }
  // }, [api, pageSize, setKeys, setTotalCount]);

  const handlePageChange = (selectedItem: { selected: number }) => {
    setCurrentPage(selectedItem.selected);
    fetchKeys(selectedItem.selected, pageSize, {});
  };

  const toggleModalDelete = () => setIsModalDeleteOpen(!isModalDeleteOpen);
  const toggleModalCloseOrders = () => setIsModalCloseOrders(!isModalCloseOrders);
  const toggleModalActions = () => setIsModalActionsOpen(!isModalActionsOpen);
  const toggleModalEdit = () => setIsModalEditOpen(!isModalEditOpen);
  const toggleModalHistoryOrders = () => setIsModalHistoryOrders(!isModalHistoryOrders);
  const toggleModalComment = () => setIsModalCommentOpen(!isModalCommentOpen);
  const toggleModalFavorite = () => setIsModalFavoriteOpen(!isModalFavoriteOpen);

  const handleActionsKey = (key: KEY) => {
    setSelectedKey(key);
    toggleModalActions();
  };

  const handleDeleteKey = (key: KEY) => {
    setSelectedKey(key);
    toggleModalDelete();
  };

  const handleCloseOrders = (key: KEY) => {
    setSelectedKey(key);
    toggleModalCloseOrders();
  };

  const deleteKey = async (id: string) => {
    try {
      const response = await api.delete(`/api/api_keys/${id}`);
      if (response.data.code === 200 && response.data.error === null) {
        console.log("Key deleted successfully");
        return true;
      } else {
        toast.error('Failed to delete key');
        return false;
      }
    } catch (error) {
      toast.error('An error occurred while deleting user');
      return false;
    }
  };

  const handleDeleteConfirm = async (result: boolean) => {
    if (result && selectedKey?.id && (selectedKey?.bot_id === "000000000000000000000000")) {
      try {
        const success = await deleteKey(selectedKey.id);
        if (success) {
          toast.success('Key deleted successfully');
          await fetchKeys(currentPage, pageSize, {});
        } else {
          toast.error('Failed to delete key');
        }
      } catch (error) {
        toast.error('Failed to delete key');
      }
    } else {
      //** сообщить, что ключ привязан к боту */
      toast.error('Sorry, but it is impossible to delete the key, since there is a binding to the bot');
    }
    toggleModalDelete();
  };


  const closeOrders = async (id: string) => {
    try {
      const response = await api.delete(`/api/api_keys/closeOrders/${id}`);
      if (response.data.code === 200 && response.data.error === null) {
        console.log("closeOrders successfully");
        return true;
      } else {
        toast.error('Failed to closeOrders');
        return false;
      }
    } catch (error) {
      toast.error('An error occurred while closeOrders');
      return false;
    }
  };

  const handleCloseOrdersConfirm = async (result: boolean) => {
    console.log("handleCloseOrdersConfirm", result)
    if (result && selectedKey?.id) {
      try {
        const success = await closeOrders(selectedKey.id);
        if (success) {
          toast.success('All close successfully');
          await fetchKeys(currentPage, pageSize, {});
        } else {
          toast.error('Failed to close all');
        }
      } catch (error) {
        toast.error('Failed to close all');
      }
    }
    toggleModalCloseOrders();
  };

  const favoriteKey = async (key: KEY) => {
    try {
      const keyData = {
        favorite: !key.favorite,
      }
      const response = await api.put(`/api/api_keys/${key?.id}`, keyData);
      if (response.data.code === 200 && response.data.error === null) {
        toast.success(response.data.msg);
        return true;
      } else {
        toast.error('Key not added to Favorites');
        return false;
      }
    } catch (error) {
      toast.error('An error occurred while adding the key to Favorites');
      return false;
    }
  };

  const handleFavoriteConfirm = async (result: boolean) => {
    if (result && selectedKey?.id) {
      try {
        const success = await favoriteKey(selectedKey);
        if (success) {
          // toast.success('Key added to Favorites successfully');
          await fetchKeys(currentPage, pageSize, {});
        } else {
          toast.error('Key not added to Favorites');
        }
      } catch (error) {
        toast.error('Key not added to Favorites');
      }
    }
    toggleModalFavorite();
  };

  const handleSaveUpdate = (result: boolean) => {
    if (result) {
      // fetchKeys(currentPage);
      toast.success('Key edited successfully');
    } else {
      toast.error('Failed to edit key');
    }
  };

  const handleEditKey = (key: KEY) => {
    setSelectedKey(key);
    toggleModalEdit();
  };

  const handleFavoriteKey = (key: KEY) => {
    setSelectedKey(key);
    toggleModalFavorite();
  };

  const fetchOrderHistory = useCallback(async (subAccountId: string, page: number) => {
    try {
      const response = await api.get<ApiResponse<GetOrderHistoryAll>>(`/api/api_keys/orders/${subAccountId}?page=${page + 1}&pageSize=${orderHistoryPerPage}`);
      if (response.data && response.data.result) {
        setOrderHistory(response.data.result.items);
        setOrderHistoryTotalCount(response.data.result.totalCount);
      } else {
        setOrderHistory([]);
        setOrderHistoryTotalCount(0);
      }
    } catch (error) {
      console.error('Error fetching order history:', error);
    }
  }, [api, orderHistoryPerPage, setOrderHistory, setOrderHistoryTotalCount]);

  const handleHistoryOrdersKey = (key: KEY) => {
    setSelectedKey(key);
    toggleModalHistoryOrders();
    if (key.subAccountId) {
      fetchOrderHistory(key.subAccountId, orderHistoryPage);
    } else {
      fetchOrderHistory(key.public_key, orderHistoryPage);
    }
  };

  const handleTransferOpen = (key: KEY) => {
    setSelectedKey(key);
    toggleModalTransfer();
  };

  const handleOrderHistoryPageChange = (selectedItem: { selected: number }) => {
    setOrderHistoryPage(selectedItem.selected);
    if (selectedKey?.subAccountId) {
      fetchOrderHistory(selectedKey.subAccountId, selectedItem.selected);
    }
  };

  const handleTransfer = async (result: boolean) => {
    if (result && selectedKey) {
      await fetchKeys(currentPage, pageSize, {});
    }
    if (!result) return;
    toggleModalTransfer();
  };

  const handleMoreOpen = (key: KEY, spot: AccountSpot[]) => {
    setSelectedKey(key);
    toggleModalMoreBalance();
  };

  const handleCommentKey = (key: KEY) => {
    setSelectedKey(key);
    console.log(key);
    toggleModalComment();
  };

  const resetFilter = useCallback(() => {
    setSelectedName('');
    setSelectedApiKeys([]);
    setSelectedMarket('');
    setSelectedFavorite(false);
    setSelectedBalance(false);
    setSelectedPositions(false);
    setCurrentPage(0)
    fetchKeys(currentPage, pageSize, {});
  }, [currentPage, pageSize]);

  const contextValue = {
    keys,
    totalCount,
    setKeys,
    currentPage,
    isModalDeleteOpen,
    isModalCloseOrders,
    isModalFavoriteOpen,
    isModalActionsOpen,
    isModalEditOpen,
    isModalHistoryOrders,
    selectedKey,
    keysPerPage,
    pageSize,
    orderHistory,
    orderHistoryPage,
    orderHistoryTotalCount,
    isModalTransfer,
    isModalMoreBalance,
    fetchKeys,
    // fetchKeysFiltered,
    handlePageChange,
    toggleModalDelete,
    toggleModalCloseOrders,
    toggleModalFavorite,
    toggleModalActions,
    toggleModalEdit,
    keysAll,
    toggleModalHistoryOrders,
    handleDeleteKey,
    handleCloseOrders,
    handleActionsKey,
    deleteKey,
    handleDeleteConfirm,
    handleCloseOrdersConfirm,
    handleFavoriteConfirm,
    handleSaveUpdate,
    handleEditKey,
    handleFavoriteKey,
    fetchOrderHistory,
    handleHistoryOrdersKey,
    handleTransferOpen,
    handleTransfer,
    toggleModalTransfer,
    handleOrderHistoryPageChange,
    setPageSize,
    handleMoreOpen,
    handleCommentKey,
    toggleModalMoreBalance,
    setIsModalCommentOpen,
    isModalCommentOpen,
    toggleModalComment,
    resetFilter,
    setSelectedApiKeys,
    selectedApiKeys,
    selectedMarket,
    setSelectedMarket,
    selectedFavorite,
    setSelectedFavorite,
    selectedBalance,
    setSelectedBalance,
    selectedPositions,
    setSelectedPositions,
    selectedName,
    setSelectedName,
    isVisibleResetFilter,
    setIsVisibleResetFilter,
  };

  return (
    <ApiKeysContext.Provider value={contextValue}>
      {children}
    </ApiKeysContext.Provider>
  );
};