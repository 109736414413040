import React, { useState } from 'react';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import TableOrdersHistory from './TableOrdersHistory'
import { useOrdersHistoryContext } from '../../context/OrdersHistoryContext';
import Loader from '../../common/Loader/index'
import { HiOutlineRefresh } from "react-icons/hi";
import BtnPrimary from '../../components/Buttons/BtnPrimary';
import { BsFunnelFill } from "react-icons/bs";
import { FaDownload, FaUndo } from "react-icons/fa";
import ModalSideRight from '../../components/Modals/ModalSideRight'

const breadcrumbItems = [
  { name: 'Dashboard', link: '/dashboard' },
  { name: 'Orders History', link: '/orders-history' }
];

const TransactionStatistics: React.FC = () => {
  const {
    statistics,
    isLoading,
    getTransactionStatistics,
    totalCount,
    handlePageChange,
    currentPage,
    pageSize,
    setStatistics,
    resetFilter,
    startDate,
    endDate,
    selectedApiKeys,
    selectedBots,
    selectedSymbols,
  } = useOrdersHistoryContext();

  const [isModalFilter, setIsModalFilter] = useState(false);

  const toggleModalFilter = () => {
    setIsModalFilter(!isModalFilter);
  };

  const handleRefresh = () => {
    getTransactionStatistics(currentPage, pageSize, startDate, endDate, selectedApiKeys, selectedBots, selectedSymbols);
  };

  const handleResetFilter = () => {
    resetFilter();
  };



  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <Breadcrumb items={breadcrumbItems} />

      <div className='flex flex-wrap gap-4 mb-4'>
        <BtnPrimary onClick={handleRefresh}>
          <HiOutlineRefresh /> Refresh
        </BtnPrimary>
        {/* <BtnPrimary onClick={toggleModalFilter}>
          <BsFunnelFill /> Filter
        </BtnPrimary> */}
        {/* <BtnPrimary onClick={handleResetFilter}>
          <FaUndo /> Reset Filter
        </BtnPrimary> */}
        {/* <BtnPrimary onClick={downloadFile}>
          <FaDownload /> Download data
        </BtnPrimary> */}
      </div>


      <div className="grid grid-cols-12 gap-4 md:gap-6 2xl:gap-7.5 mt-2">
        <div className="col-span-12">
          <TableOrdersHistory
            statistics={statistics}
            updateStatistics={getTransactionStatistics}
            totalCount={totalCount}
            onPageChange={handlePageChange}
            currentPage={currentPage}
            setStatistics={setStatistics} />
        </div>
      </div>
    </>
  );
};

export default TransactionStatistics;