// ManualTradingContext.tsx
import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { Db_Symbols } from '../types/symbols'
import api from '../utils/api';
import { AccountInfo } from '../types/manualTrade';
import { toast } from 'sonner';
import { ApiResponse } from '../types/api';

interface ManualTradingContextProps {
  symbol: string;
  type: string;
  leverage: number | '';
  amount: number | '';
  takeProfit: number | '';
  stopLoss: number | '';
  price: number | '';
  position: string;
  accountInfo: AccountInfo | null;
  isSubmittingLong: boolean;
  isSubmittingShort: boolean;
  handleLeverageChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleSelectChange: (value: string[]) => void;
  handleTypeChange: (value: string) => void;
  handleAmountChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleTakeProfitChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleStopLossChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handlePriceChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleSubmit: (position: string) => Promise<boolean>;
  fetchAccountInfo: () => Promise<void>;
  handleCloseOrderSubmit: (orderId: string, symb: string) => Promise<boolean>;
  handleClosePosition: (position: string, amount: number, symbol: string) => Promise<boolean>;
}

const ManualTradingContext = createContext<ManualTradingContextProps | undefined>(undefined);

export const useManualTradingContext = () => {
  const context = useContext(ManualTradingContext);
  if (!context) {
    throw new Error('useManualTradingContext must be used within a ManualTradingProvider');
  }
  return context;
};

interface ManualTradingProviderProps {
  children: React.ReactNode;
  keyId: string | undefined;
  sym: string | undefined;
}

export const ManualTradingProvider: React.FC<ManualTradingProviderProps> = ({ children, keyId, sym }) => {
  if (!sym || sym == "" || sym == "unknown") {
    sym = "BTC-USDT"
  }

  const [symbol, setSymbol] = useState(sym);
  const [type, setType] = useState('Market');
  const [leverage, setLeverage] = useState<number | ''>(50);
  const [amount, setAmount] = useState<number | ''>(0);
  const [takeProfit, setTakeProfit] = useState<number | ''>(0);
  const [stopLoss, setStopLoss] = useState<number | ''>(0);
  const [price, setPrice] = useState<number | ''>(0);
  const [position, setPosition] = useState('');
  const [accountInfo, setAccountInfo] = useState<AccountInfo | null>(null);
  const [isInitialFetch, setIsInitialFetch] = useState(true);
  const [isSubmittingLong, setIsSubmittingLong] = useState(false);
  const [isSubmittingShort, setIsSubmittingShort] = useState(false);

  const handleLeverageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setLeverage(value === '' ? '' : parseInt(value, 10));
  };

  const handleSelectChange = (value: string[]) => {
    setSymbol(value[0]);
    setIsInitialFetch(true);
  };

  const handleTypeChange = (value: string) => {
    setType(value);
  };

  const handlePositionChange = (value: string) => {
    setPosition(value);
  };

  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setAmount(value === '' ? '' : parseFloat(value));
  };

  const handleTakeProfitChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setTakeProfit(value === '' ? '' : parseFloat(value));
  };

  const handleStopLossChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setStopLoss(value === '' ? '' : parseFloat(value));
  };

  const handlePriceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setPrice(value === '' ? '' : parseFloat(value));
  };

  const transformSymbolsToOptions = (symbols: Db_Symbols[]) => {
    return symbols.map(symbols => ({
      value: symbols?.symbol,
      text: symbols.symbol
    }));
  };

  let symbolData = localStorage.getItem('symbols') ? transformSymbolsToOptions(JSON.parse(localStorage.getItem('symbols') || "")) : []

  const fetchAccountInfo = useCallback(async () => {
    console.log("Fetching account info...");
    if (!isInitialFetch) return;

    let data = { symbol };
    try {
      const response = await api.post<ApiResponse<AccountInfo>>(`/trade-api/account_info/${keyId}`, data);
      // const response = await api.post<ApiResponse<AccountInfo>>(`http://127.0.0.1:8080/trade-api/account_info/${keyId}`, data);
      console.log("Fetched account info:", response.data.result);
      setAccountInfo(response.data.result);
      setIsInitialFetch(false);

      if (response.data.result?.exchange_name.toUpperCase() == "BINANCE") {
        if (!localStorage.getItem('binanceFutures_symbols')) {
          const symbolsResponse = await api.get<ApiResponse<Db_Symbols>>('/api/symbols?exchange=binance');
          await new Promise(resolve => setTimeout(resolve, 500));
          localStorage.setItem('binanceFutures_symbols', JSON.stringify(symbolsResponse.data.result));
        }
      } else if (response.data.result?.exchange_name.toUpperCase() == "BYBIT") {
        if (!localStorage.getItem('bybitFutures_symbols')) {
          const symbolsResponse = await api.get<ApiResponse<Db_Symbols>>('/api/symbols?exchange=bybit');
          await new Promise(resolve => setTimeout(resolve, 500));
          localStorage.setItem('bybitFutures_symbols', JSON.stringify(symbolsResponse.data.result));
        }
      } else if (response.data.result?.exchange_name.toUpperCase() == "GATE") {
        if (!localStorage.getItem('gateSpot_symbols')) {
          const symbolsResponse = await api.get<ApiResponse<Db_Symbols>>('/api/symbols?exchange=gate');
          await new Promise(resolve => setTimeout(resolve, 500));
          localStorage.setItem('gateSpot_symbols', JSON.stringify(symbolsResponse.data.result));
        }
      } else if (response.data.result?.exchange_name.toUpperCase() == "MEXC") {
        // if (!localStorage.getItem('mexcSpot_symbols')) {
        //   const symbolsResponse = await api.get<ApiResponse<Db_Symbols>>('/api/symbols?exchange=mexc');
        //   await new Promise(resolve => setTimeout(resolve, 500));
        //   localStorage.setItem('mexcSpot_symbols', JSON.stringify(symbolsResponse.data.result));
        // }

        if (!localStorage.getItem('mexcFutures_symbols')) {
          const symbolsResponse = await api.get<ApiResponse<Db_Symbols>>('/api/symbols?exchange=mexc');
          await new Promise(resolve => setTimeout(resolve, 500));
          localStorage.setItem('mexcFutures_symbols', JSON.stringify(symbolsResponse.data.result));
        }

      } else if (response.data.result?.exchange_name.toUpperCase() == "BITGET") {
        if (!localStorage.getItem('bitgetSpot_symbols')) {
          const symbolsResponse = await api.get<ApiResponse<Db_Symbols>>('/api/symbols?exchange=bitget');
          await new Promise(resolve => setTimeout(resolve, 500));
          localStorage.setItem('bitgetSpot_symbols', JSON.stringify(symbolsResponse.data.result));
        }
      } else if (response.data.result?.exchange_name.toUpperCase() == "BINGX") {
        if (!localStorage.getItem('symbols')) {
          const symbolsResponse = await api.get<ApiResponse<Db_Symbols>>('/api/symbols?exchange=bingx');
          await new Promise(resolve => setTimeout(resolve, 500));
          localStorage.setItem('symbols', JSON.stringify(symbolsResponse.data.result));
        }
      }


      console.log(response.data.result)
    } catch (error) {
      console.error('Error fetching account info:', error);
    }
  }, [keyId, isInitialFetch, symbol]);

  useEffect(() => {
    fetchAccountInfo();

    const intervalId = setInterval(fetchAccountInfo, 15000);

    return () => clearInterval(intervalId);
  }, [fetchAccountInfo]);

  useEffect(() => {
    console.log("Account info updated:", accountInfo);
  }, [accountInfo]);

  const handleCloseOrderSubmit = async (orderId: string, symb: string) => {
    if (symb == "") {
      symb = symbol
    }
    let data = { action: "cancel", symbol: symb, orderId };
    try {
      const response = await api.post(`/trade-api/order/${keyId}`, data);
      // const response = await api.post(`http://127.0.0.1:8080/trade-api/order/${keyId}`, data);
      if (response.data.code === 200 && response.data.result) {
        console.log('success');
        return true;
      } else {
        console.log('Failed request');
        return false;
      }
    } catch (error) {
      console.log(`error catch ${error}`);
      return false;
    }
  };

  const handleSubmit = async (position: string) => {
    handlePositionChange(position);
    if (position === 'LONG') {
      setIsSubmittingLong(true);
    } else if (position === 'SHORT') {
      setIsSubmittingShort(true);
    }

    let orderData = {};
    switch (type) {
      case 'Market':
        orderData = {
          symbol: symbol,
          leverage: leverage,
          action: "open",
          keyId: keyId,
          position: position,
          amount: amount,
          takeProfit: takeProfit,
          stopLoss: stopLoss,
          orderType: type,
        };
        break;
      case 'Limit':
        orderData = {
          symbol: symbol,
          leverage: leverage,
          action: "open",
          keyId: keyId,
          position: position,
          amount: amount,
          price: price,
          takeProfit: takeProfit,
          stopLoss: stopLoss,
          orderType: type,
        };
        break;
      case 'Take Profit':
        orderData = {
          symbol: symbol,
          leverage: leverage,
          action: "open",
          keyId: keyId,
          position: position,
          amount: amount,
          takeProfit: takeProfit,
          orderType: type,
        };
        break;
      case 'Stop Loss':
        orderData = {
          symbol: symbol,
          leverage: leverage,
          action: "open",
          keyId: keyId,
          position: position,
          amount: amount,
          stopLoss: stopLoss,
          orderType: type,
        };
        break;
      default:
        break;
    }

    console.log('Order Data:', orderData);

    try {
      const response = await api.post(`/trade-api/order/${keyId}`, orderData);
      // const response = await api.post(`http://127.0.0.1:8080/trade-api/order/${keyId}`, orderData);
      if (response.data.code === 200 && response.data.result) {
        console.log('success');
        toast.success('Position added successfully');
        setIsInitialFetch(true);
        await fetchAccountInfo(); // Вызываем fetchAccountInfo после успешного создания позиции

        // Очищаем поля после успешного отправления запроса
        setSymbol(symbolData[0].value);
        setType('Market');
        setLeverage(50);
        setAmount(0);
        setTakeProfit(0);
        setStopLoss(0);
        setPrice(0);
        setPosition('');

        setTimeout(() => {
          if (position === 'LONG') {
            setIsSubmittingLong(false);
          } else if (position === 'SHORT') {
            setIsSubmittingShort(false);
          }
        }, 500); // Задержка в 500 мс
        return true;
      } else {
        console.log('Failed request');
        toast.error('Error adding position');
        setTimeout(() => {
          if (position === 'LONG') {
            setIsSubmittingLong(false);
          } else if (position === 'SHORT') {
            setIsSubmittingShort(false);
          }
        }, 500); // Задержка в 500 мс
        return false;
      }
    } catch (error) {
      console.log(`error catch ${error}`);
      setTimeout(() => {
        if (position === 'LONG') {
          setIsSubmittingLong(false);
        } else if (position === 'SHORT') {
          setIsSubmittingShort(false);
        }
      }, 500); // Задержка в 500 мс
      return false;
    }
  };

  const handleClosePosition = async (position: string, amount: number, symbol: string) => {
    const orderData = {
      action: "close",
      symbol: symbol,
      position: position,
      amount: amount,
    };

    try {
      const response = await api.post(`/trade-api/order/${keyId}`, orderData);
      // const response = await api.post(`http://127.0.0.1:8080/trade-api/order/${keyId}`, orderData);
      if (response.data.code === 200 && response.data.result) {
        console.log('success');
        // toast.success('Position closed successfully');
        setIsInitialFetch(true);
        await fetchAccountInfo(); // Вызываем fetchAccountInfo после успешного закрытия позиции
        return true;
      } else {
        console.log('Failed request');
        // toast.error('Error closing position');
        return false;
      }
    } catch (error) {
      console.log(`error catch ${error}`);
      // toast.error('Error closing position');
      return false;
    }
  };

  const contextValue: ManualTradingContextProps = {
    symbol,
    type,
    leverage,
    amount,
    takeProfit,
    stopLoss,
    price,
    position,
    accountInfo,
    isSubmittingLong,
    isSubmittingShort,
    handleLeverageChange,
    handleSelectChange,
    handleTypeChange,
    handleAmountChange,
    handleTakeProfitChange,
    handleStopLossChange,
    handlePriceChange,
    handleSubmit,
    fetchAccountInfo,
    handleCloseOrderSubmit,
    handleClosePosition,
  };

  return (
    <ManualTradingContext.Provider value={contextValue}>
      {children}
    </ManualTradingContext.Provider>
  );
};