import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import api from '../../utils/api'
import { ReportDetails_struct, BotSettingsDetails_struct, BotSettings_struct, Report_struct, ReportDetails_struct_tmp } from '../../types/report';
import { formatCurrency } from '../../utils/formatCurrency';
import Loader from '../../common/Loader';

const formatToProc = (num: any) => {
  return (num * 100).toFixed(2)
}

const secondsToHms = function (d: any) {
  d = Number(d);
  var day = Math.floor(d / 86400);
  var h = Math.floor(d % 86400 / 3600);
  var m = Math.floor(d % 3600 / 60);
  var s = Math.floor(d % 3600 % 60);

  var dDisplay = day > 0 ? day + (day == 1 ? " day, " : " days, ") : "";
  var hDisplay = h > 0 ? h + (h == 1 ? " h, " : " h, ") : "";
  var mDisplay = m > 0 ? m + (m == 1 ? " min, " : " min, ") : "";
  var sDisplay = s > 0 ? s + (s == 1 ? " sec" : " sec") : "";
  return dDisplay + hDisplay + mDisplay + sDisplay;
}

const ReportPage: React.FC = () => {
  const { idReport } = useParams<{ idReport: string }>();
  const [reportData, setReportData] = useState<ReportDetails_struct | null>(null);
  const [periodsList, setPeriodsList] = useState<Report_struct[] | undefined>([])
  const [activePeriod, setActivePeriod] = useState<string>("all")
  const [visiblePeriodData, setVisiblePeriodData] = useState<Report_struct>()

  const [settings, setSettings] = useState<BotSettingsDetails_struct>()
  const [info, setInfo] = useState<Report_struct>()


  useEffect(() => {
    const fetchReportData = async () => {
      try {
        const response = await api.get<ReportDetails_struct_tmp>(`/benchmark-api/reports/full/${idReport}`);
        // console.log(response.data.result);

        setReportData(response.data.result);
        setPeriodsList(reportData?.report?.bots[0]?.reports)
        setVisiblePeriodData(reportData?.report?.total)
        setSettings(response.data.result?.report.bots[0].settings)
        setInfo(response.data.result?.report.total)
        // // Extract settings, periods, and bots
        // const settingsArray: BotSettings_struct[] = [];
        // const periodsArray: Report_struct[] = [];
        // const botsArray: BotsForReport_struct[] = response.data.report.bots || [];

        // botsArray.forEach(bot => {
        //   settingsArray.push(bot.settings);
        //   periodsArray.push(...bot.reports);
        // });

        // setSettings(settingsArray);
        // setPeriods(periodsArray);
        // setBots(botsArray);

      } catch (error) {
        console.error('Error fetching report data:', error);
      }
    };

    fetchReportData();
  }, [idReport]);

  if (!reportData) {
    return <Loader />;
  }

  // const { report } = reportData;
  // const { symbol, total } = report;
  // console.log(reportData, reportData.length)

  console.log("reportData", reportData)
  console.log("info", info)
  return (
    <div>
      <div className='grid gap-4 grid-cols-1 md:grid-cols-3'>
        {/* <Settings /> */}
        <section className="col-span-1 md:col-span-3 bg-white dark:bg-boxdark p-4 rounded-[10px]">
          <h3 className="text-title-md font-semibold text-black dark:text-white mb-4">Periods List</h3>
          <ul className="flex items-center flex-wrap gap-5">
            <li
              key="periods-all"
              className={`px-2 py-1 border rounded-[8px]  ${activePeriod === "all" ? 'border-primary bg-primary cursor-default' : 'cursor-pointer'}`}
              onClick={(e) => {
                setActivePeriod("all");
                setInfo(reportData?.report.total)
                setVisiblePeriodData(reportData?.report?.total)
              }}
            >
              <div className={`text-xs text-black dark:text-white ${activePeriod === "all" ? "text-white dark:text-white" : null}`}>All</div>
            </li>
            {
              reportData !== undefined &&
              reportData?.report.bots.map((bot, index) => {
                return (
                  bot.reports?.map((item, i) => (
                    activePeriod === i.toString() ?
                      <li
                        key={"periods" + i}
                        className={`px-2 py-1 border rounded-[8px]  ${activePeriod === i.toString() ? 'border-primary bg-primary cursor-default' : 'cursor-pointer'}`}
                      >
                        <div className={`text-xs text-black dark:text-white ${activePeriod === "all" ? "text-white dark:text-white" : null}`}>{item.period}</div>
                      </li>
                      :
                      <li
                        key={"periods" + i}
                        className={`px-2 py-1 border rounded-[8px]  ${activePeriod === i.toString() ? 'border-primary bg-primary cursor-default' : 'cursor-pointer'}`}
                        onClick={(e) => {
                          setActivePeriod(i.toString());
                          if (periodsList !== undefined) {
                            setVisiblePeriodData(periodsList[i])
                          }
                          setInfo(item)
                          // console.log(activePeriod)
                        }}
                      >
                        <div className={`text-xs text-black dark:text-white ${activePeriod === "all" ? "text-white dark:text-white" : null}`}>{item.period}</div>
                      </li>
                  ))
                )
              })
            }

          </ul>
        </section>
        <section className="bg-white dark:bg-boxdark p-4 rounded-[10px]">
          <h3 className="text-title-md font-semibold text-black dark:text-white mb-4">Settings</h3>
          <div className="">
            <div className="" >
              <div className={`px-2 py-1`}>
                <p className={`text-md text-black dark:text-white`}>StartBalance: {formatCurrency(settings?.startBalance)}</p>
              </div>
              <div className={`px-2 py-1`}>
                <p className={`text-md text-black dark:text-white`}>Init: {settings?.init} {settings?.isInitProcent ? "%" : "$"}</p>
              </div>
              <div className={`px-2 py-1`}>
                <p className={`text-md text-black dark:text-white`}>InitAVG: {settings?.initAVG} {settings?.isInitAVGProcent ? "%" : "$"}</p>
              </div>
              <div className={`px-2 py-1`}>
                <p className={`text-md text-black dark:text-white`}>CountAVG: {settings?.countAVG}</p>
              </div>
              <div className={`px-2 py-1`}>
                <p className={`text-md text-black dark:text-white`}>FirstProcentAVG: {settings?.firstProcentAVG}</p>
              </div>
              <div className={`px-2 py-1`}>
                <p className={`text-md text-black dark:text-white`}>MultimetrStep: {settings?.multimetrStep}</p>
              </div>
              <div className={`px-2 py-1`}>
                <p className={`text-md text-black dark:text-white`}>MultimetrVolume: {settings?.multimetrVolume}</p>
              </div>
              <div className={`px-2 py-1`}>
                <p className={`text-md text-black dark:text-white`}>TakeProfit: {settings?.takeProfit}</p>
              </div>
              <div className={`px-2 py-1`}>
                <p className={`text-md text-black dark:text-white`}>LessRSI: {settings?.lessRSI}</p>
              </div>
              <div className={`px-2 py-1`}>
                <p className={`text-md text-black dark:text-white`}>AboveRSI: {settings?.aboveRSI}</p>
              </div>
              <div className={`px-2 py-1`}>
                <p className={`text-md text-black dark:text-white`}>TimeFrame: {settings?.tFString}</p>
              </div>
              <div className={`px-2 py-1`}>
                <p className={`text-md text-black dark:text-white`}>LengthRSI: {settings?.lengthRSI}</p>
              </div>
            </div>
            {/* {
              reportData !== undefined &&
              reportData?.report.bots.map((bot, index) => {
                return (
                  <div className="" key={"settings-" + index}>
                    <div className={`px-2 py-1`}>
                      <p className={`text-md text-black dark:text-white`}>StartBalance: {formatCurrency(bot.settings.startBalance)}</p>
                    </div>
                    <div className={`px-2 py-1`}>
                      <p className={`text-md text-black dark:text-white`}>Init: {bot.settings.init} {bot.settings.isInitProcent ? "%" : "$"}</p>
                    </div>
                    <div className={`px-2 py-1`}>
                      <p className={`text-md text-black dark:text-white`}>InitAVG: {bot.settings.initAVG} {bot.settings.isInitAVGProcent ? "%" : "$"}</p>
                    </div>
                    <div className={`px-2 py-1`}>
                      <p className={`text-md text-black dark:text-white`}>CountAVG: {bot.settings.countAVG}</p>
                    </div>
                    <div className={`px-2 py-1`}>
                      <p className={`text-md text-black dark:text-white`}>FirstProcentAVG: {bot.settings.firstProcentAVG}</p>
                    </div>
                    <div className={`px-2 py-1`}>
                      <p className={`text-md text-black dark:text-white`}>MultimetrStep: {bot.settings.multimetrStep}</p>
                    </div>
                    <div className={`px-2 py-1`}>
                      <p className={`text-md text-black dark:text-white`}>MultimetrVolume: {bot.settings.multimetrVolume}</p>
                    </div>
                    <div className={`px-2 py-1`}>
                      <p className={`text-md text-black dark:text-white`}>TakeProfit: {bot.settings.takeProfit}</p>
                    </div>
                    <div className={`px-2 py-1`}>
                      <p className={`text-md text-black dark:text-white`}>LessRSI: {bot.settings.lessRSI}</p>
                    </div>
                    <div className={`px-2 py-1`}>
                      <p className={`text-md text-black dark:text-white`}>AboveRSI: {bot.settings.aboveRSI}</p>
                    </div>
                    <div className={`px-2 py-1`}>
                      <p className={`text-md text-black dark:text-white`}>TimeFrame: {bot.settings.tFString}</p>
                    </div>
                    <div className={`px-2 py-1`}>
                      <p className={`text-md text-black dark:text-white`}>LengthRSI: {bot.settings.lengthRSI}</p>
                    </div>
                  </div>
                )
              })
            } */}
          </div>
        </section>
        <section className="bg-white dark:bg-boxdark p-4 rounded-[10px]">
          <h3 className="text-title-md font-semibold text-black dark:text-white mb-4">Account Results</h3>
          <div className="888">
            <h3>
              <div className={`px-2 py-1`}>
                <p className={`text-md text-black dark:text-white`}>Account Balance: {formatCurrency(info?.balance)}</p>
              </div>
              <div className={`px-2 py-1`}>
                <p className={`text-md text-black dark:text-white`}>RealizedProfit: {formatCurrency(info?.realizedProfit)}</p>
              </div>
              <div className={`px-2 py-1`}>
                <p className={`text-md text-black dark:text-white`}>UnrealizedProfit NOW: {formatCurrency(info?.unrealizedProfit)}</p>
              </div>

              <div className={`px-2 py-1`}>
                <p className={`text-md text-black dark:text-white`}>MaxDrawDown: {formatToProc(info?.maxDrawDown)}%</p>
              </div>

              <div className={`px-2 py-1`}>
                <p className={`text-md text-black dark:text-white`}>Max AVG LONG: {info?.maxAVGLONG}</p>
              </div>
              <div className={`px-2 py-1`}>
                <p className={`text-md text-black dark:text-white`}>Min Time in Positions: {secondsToHms(reportData?.report?.total?.timeInPositionsMin)}</p>
              </div>
              <div className={`px-2 py-1`}>
                <p className={`text-md text-black dark:text-white`}>Max Time in Positions: {secondsToHms(reportData?.report?.total?.timeInPositionsMax)} </p>
              </div>
              <div className={`px-2 py-1`}>
                <p className={`text-md text-black dark:text-white`}>AVG Time in Positions:{secondsToHms(reportData?.report?.total?.timeInPositions)}</p>
              </div>
              {/* {
                activePeriod === "all" ?
                  (
                    <>
                      <div className={`px-2 py-1`}>
                        <p className={`text-md text-black dark:text-white`}>Period: {reportData?.report?.total.period || "ALL"}</p>
                      </div>
                      <div className={`px-2 py-1`}>
                        <p className={`text-md text-black dark:text-white`}>CountLoseDouble: {reportData?.report?.total.countLoseDouble}</p>
                      </div>
                      <div className={`px-2 py-1`}>
                        <p className={`text-md text-black dark:text-white`}>CountLoseLong: {reportData?.report?.total.countLoseLong}</p>
                      </div>
                      <div className={`px-2 py-1`}>
                        <p className={`text-md text-black dark:text-white`}>CountLoseShort: {reportData?.report?.total.countLoseShort}</p>
                      </div>
                      <div className={`px-2 py-1`}>
                        <p className={`text-md text-black dark:text-white`}>CountOrdersLong: {reportData?.report?.total.countOrdersLong}</p>
                      </div>
                      <div className={`px-2 py-1`}>
                        <p className={`text-md text-black dark:text-white`}>countOrdersShort: {reportData?.report?.total.countOrdersShort}</p>
                      </div>
                      <div className={`px-2 py-1`}>
                        <p className={`text-md text-black dark:text-white`}>countProfitDouble: {reportData?.report?.total.countProfitDouble}</p>
                      </div>
                      <div className={`px-2 py-1`}>
                        <p className={`text-md text-black dark:text-white`}>countProfitLong: {reportData?.report?.total.countProfitLong}</p>
                      </div>
                      <div className={`px-2 py-1`}>
                        <p className={`text-md text-black dark:text-white`}>countProfitShort: {reportData?.report?.total.countProfitShort}</p>
                      </div>
                      <div className={`px-2 py-1`}>
                        <p className={`text-md text-black dark:text-white`}>maxDrawDown: {reportData?.report?.total.maxDrawDown}</p>
                      </div>
                      <div className={`px-2 py-1`}>
                        <p className={`text-md text-black dark:text-white`}>maxLosePnlLong: {reportData?.report?.total.maxLosePnlLong}</p>
                      </div>
                      <div className={`px-2 py-1`}>
                        <p className={`text-md text-black dark:text-white`}>maxLosePnlShort: {reportData?.report?.total.maxLosePnlShort}</p>
                      </div>
                      <div className={`px-2 py-1`}>
                        <p className={`text-md text-black dark:text-white`}>maxPositionLong: {reportData?.report?.total.maxPositionLong}</p>
                      </div>
                      <div className={`px-2 py-1`}>
                        <p className={`text-md text-black dark:text-white`}>maxPositionShort: {reportData?.report?.total.maxPositionShort}</p>
                      </div>
                      <div className={`px-2 py-1`}>
                        <p className={`text-md text-black dark:text-white`}>maxProfitPnlLong: {reportData?.report?.total.maxProfitPnlLong}</p>
                      </div>
                      <div className={`px-2 py-1`}>
                        <p className={`text-md text-black dark:text-white`}>maxProfitPnlShort: {reportData?.report?.total.maxProfitPnlShort}</p>
                      </div>
                      <div className={`px-2 py-1`}>
                        <p className={`text-md text-black dark:text-white`}>realizedProfit: {reportData?.report?.total.realizedProfit}</p>
                      </div>
                      <div className={`px-2 py-1`}>
                        <p className={`text-md text-black dark:text-white`}>unrealizedProfit: {reportData?.report?.total.unrealizedProfit}</p>
                      </div>
                      <div className={`px-2 py-1`}>
                        <p className={`text-md text-black dark:text-white`}>volumeLong: {reportData?.report?.total.volumeLong}</p>
                      </div>
                      <div className={`px-2 py-1`}>
                        <p className={`text-md text-black dark:text-white`}>volumeShort: {reportData?.report?.total.volumeShort}</p>
                      </div>
                      <div className={`px-2 py-1`}>
                        <p className={`text-md text-black dark:text-white`}>withdraw: {reportData?.report?.total.withdraw}</p>
                      </div>
                    </>
                  )
                  :
                  (
                    <>
                      <div className={`px-2 py-1`}>
                        <p className={`text-md text-black dark:text-white`}>Period: {periodsList !== undefined ? periodsList[Number(activePeriod)]?.period : null}</p>
                      </div>
                      <div className={`px-2 py-1`}>
                        <p className={`text-md text-black dark:text-white`}>CountLoseDouble: {periodsList !== undefined ? periodsList[Number(activePeriod)]?.countLoseDouble : null}</p>
                      </div>
                      <div className={`px-2 py-1`}>
                        <p className={`text-md text-black dark:text-white`}>CountLoseLong: {periodsList !== undefined ? periodsList[Number(activePeriod)]?.countLoseLong : null}</p>
                      </div>
                      <div className={`px-2 py-1`}>
                        <p className={`text-md text-black dark:text-white`}>CountLoseShort: {periodsList !== undefined ? periodsList[Number(activePeriod)]?.countLoseShort : null}</p>
                      </div>
                      <div className={`px-2 py-1`}>
                        <p className={`text-md text-black dark:text-white`}>CountOrdersLong: {periodsList !== undefined ? periodsList[Number(activePeriod)]?.countOrdersLong : null}</p>
                      </div>
                      <div className={`px-2 py-1`}>
                        <p className={`text-md text-black dark:text-white`}>countOrdersShort: {periodsList !== undefined ? periodsList[Number(activePeriod)]?.countOrdersShort : null}</p>
                      </div>
                      <div className={`px-2 py-1`}>
                        <p className={`text-md text-black dark:text-white`}>countProfitDouble: {periodsList !== undefined ? periodsList[Number(activePeriod)]?.countProfitDouble : null}</p>
                      </div>
                      <div className={`px-2 py-1`}>
                        <p className={`text-md text-black dark:text-white`}>countProfitLong: {periodsList !== undefined ? periodsList[Number(activePeriod)]?.countProfitLong : null}</p>
                      </div>
                      <div className={`px-2 py-1`}>
                        <p className={`text-md text-black dark:text-white`}>countProfitShort: {periodsList !== undefined ? periodsList[Number(activePeriod)]?.countProfitShort : null}</p>
                      </div>
                      <div className={`px-2 py-1`}>
                        <p className={`text-md text-black dark:text-white`}>maxDrawDown: {periodsList !== undefined ? periodsList[Number(activePeriod)]?.maxDrawDown : null}</p>
                      </div>
                      <div className={`px-2 py-1`}>
                        <p className={`text-md text-black dark:text-white`}>maxLosePnlLong: {periodsList !== undefined ? periodsList[Number(activePeriod)]?.maxLosePnlLong : null}</p>
                      </div>
                      <div className={`px-2 py-1`}>
                        <p className={`text-md text-black dark:text-white`}>maxLosePnlShort: {periodsList !== undefined ? periodsList[Number(activePeriod)]?.maxLosePnlShort : null}</p>
                      </div>
                      <div className={`px-2 py-1`}>
                        <p className={`text-md text-black dark:text-white`}>maxPositionLong: {periodsList !== undefined ? periodsList[Number(activePeriod)]?.maxPositionLong : null}</p>
                      </div>
                      <div className={`px-2 py-1`}>
                        <p className={`text-md text-black dark:text-white`}>maxPositionShort: {periodsList !== undefined ? periodsList[Number(activePeriod)]?.maxPositionShort : null}</p>
                      </div>
                      <div className={`px-2 py-1`}>
                        <p className={`text-md text-black dark:text-white`}>maxProfitPnlLong: {periodsList !== undefined ? periodsList[Number(activePeriod)]?.maxProfitPnlLong : null}</p>
                      </div>
                      <div className={`px-2 py-1`}>
                        <p className={`text-md text-black dark:text-white`}>maxProfitPnlShort: {periodsList !== undefined ? periodsList[Number(activePeriod)]?.maxProfitPnlShort : null}</p>
                      </div>
                      <div className={`px-2 py-1`}>
                        <p className={`text-md text-black dark:text-white`}>realizedProfit: {periodsList !== undefined ? periodsList[Number(activePeriod)]?.realizedProfit : null}</p>
                      </div>
                      <div className={`px-2 py-1`}>
                        <p className={`text-md text-black dark:text-white`}>unrealizedProfit: {periodsList !== undefined ? periodsList[Number(activePeriod)]?.unrealizedProfit : null}</p>
                      </div>
                      <div className={`px-2 py-1`}>
                        <p className={`text-md text-black dark:text-white`}>volumeLong: {periodsList !== undefined ? periodsList[Number(activePeriod)]?.volumeLong : null}</p>
                      </div>
                      <div className={`px-2 py-1`}>
                        <p className={`text-md text-black dark:text-white`}>volumeShort: {periodsList !== undefined ? periodsList[Number(activePeriod)]?.volumeShort : null}</p>
                      </div>
                      <div className={`px-2 py-1`}>
                        <p className={`text-md text-black dark:text-white`}>withdraw: {periodsList !== undefined ? periodsList[Number(activePeriod)]?.withdraw : null}</p>
                      </div>
                    </>
                  )
              } */}
            </h3>
          </div>
        </section>
        <section className="bg-white dark:bg-boxdark p-4 rounded-[10px]">
          <h3 className="text-title-md font-semibold text-black dark:text-white mb-4">Statistics</h3>
          <div className={`px-2 py-1`}>
            <p className={`text-md text-black dark:text-white`}>Count Orders: {info?.countOrdersLONG}</p>
          </div>
          <div className={`px-2 py-1`}>
            <p className={`text-md text-black dark:text-white`}>Volume: {formatCurrency(info?.volume)}</p>
          </div>
          <div className={`px-2 py-1`}>
            <p className={`text-md text-black dark:text-white`}>Max. Position LONG: {formatCurrency(info?.maxPositionLONG)}</p>
          </div>
          <div className={`px-2 py-1`}>
            <p className={`text-md text-black dark:text-white`}>Max. Plus PnL LONG: {formatCurrency(info?.maxProfitPnlLONG)}</p>
          </div>
          <div className={`px-2 py-1`}>
            <p className={`text-md text-black dark:text-white`}>Max. Minus PnL LONG: {formatCurrency(info?.maxLosePnlLONG)}</p>
          </div>
        </section>

      </div>
    </div>
  );
};

export default ReportPage;