import React, { useState, useEffect, useRef } from 'react';
import { usePnlStatisticsContext } from '../../context/PnlStatisticsContext';
import MultipleSelect from '../../components/Forms/Select/MultipleSelect';
import SimpleSelect from '../../components/Forms/Select/SimpleSelect';
import { botsExample } from '../../data/botsExample';
import { toast } from 'sonner';
import DatePicker from '../../components/Forms/DatePicker';
import { BsFunnelFill, BsXCircleFill } from "react-icons/bs";
import BtnPrimary from '../../components/Buttons/BtnPrimary';
import { KEY, GetApiKeysAll } from '../../types/key'
import api from '../../utils/api';
import { ApiResponse } from '../../types/api'
import { Db_Bots } from '../../types/botsAll'
import { Db_Symbols } from '../../types/symbols'
import { transformBotsToOptions } from '../../utils/transformBotsToOption';
import { transformSymbolsToOptions } from '../../utils/transformSymbolsToOption';

interface PnlFilterProps {
  toggleModal: () => void;
}

const PnlFilterNew: React.FC<PnlFilterProps> = ({ toggleModal }) => {
  const { startDate, endDate, fetchData, selectedSymbols, selectedBots, setStartDate, setEndDate, selectedApiKeys, setSelectedSymbols, setSelectedBots, setSelectedApiKeys } = usePnlStatisticsContext();
  const [selectedDates, setSelectedDates] = useState<Date[]>([new Date(startDate), new Date(endDate)]);
  const [symbolsSelect, setSymbolsSelect] = useState<string[]>(selectedSymbols);

  const [apiKeys, setApiKeys] = useState<string[]>(selectedApiKeys);
  const [arrApiKeys, setArrApiKeys] = useState<KEY[]>([]);
  const [apiKeysFetched, setApiKeysFetched] = useState(false);

  const [botsAll, setBotsAll] = useState<string[]>(selectedBots);
  const [arrBotsAll, setArrBotsAll] = useState<Db_Bots[]>([]);
  const [botsAllFetched, setBotsAllFetched] = useState(false);



  const dateRangeRef = useRef<HTMLInputElement>(null);

  const handleSymbolChange = (selectedValues: string[]) => {
    setSymbolsSelect(selectedValues)
  };

  const handleBotChange = (selectedValues: string[]) => {
    setBotsAll(selectedValues);
  };

  const handleApiKeysChange = (selectedValues: string[]) => {
    setApiKeys(selectedValues);
  };

  useEffect(() => {
    if (startDate && endDate) {
      setSelectedDates([new Date(startDate), new Date(endDate)]);
    }
  }, [startDate, endDate]);

  const handleDateChange = (selectedDates: Date[]) => {
    if (selectedDates.length === 2) {
      setSelectedDates(selectedDates);
      localStorage.setItem('pnlSelectedDates', JSON.stringify(selectedDates));
    }
  };

  const handleApplyFilter = () => {
    const sDate = selectedDates[0].setUTCHours(23, 59, 59, 999) + 1000;
    const eDate = selectedDates[1].setUTCHours(23, 59, 59, 999);
    setStartDate(sDate)
    setEndDate(eDate)
    setSelectedSymbols(symbolsSelect);
    setSelectedApiKeys(apiKeys);
    setSelectedBots(botsAll);

    fetchData(sDate, eDate, apiKeys, botsAll, symbolsSelect);
    toggleModal();
  };

  useEffect(() => {
    if (!apiKeysFetched) {
      if (arrApiKeys.length == 0) {
        getApiKeys();
      }
      setApiKeysFetched(true);
    }
  }, [apiKeysFetched]);

  const getApiKeys = async () => {
    try {
      const response = await api.post<ApiResponse<GetApiKeysAll>>(`/api/api_keys/search`, {
        "pagination": {
          "page": 1,
          "limit": 100
        }
      }
      );
      if (response.data.code === 200 && response.data.result) {
        setArrApiKeys(response.data.result.items);
      } else {
        toast.error(`Error loading api keys`);
      }
    } catch (error) {
      toast.error(`Error loading api keys`);
    }
  };

  useEffect(() => {
    if (!botsAllFetched) {
      getBotsAll();
      setBotsAllFetched(true);
    }
  }, [botsAllFetched]);

  const getBotsAll = async () => {
    try {
      const response = await api.post(`/bot-api/bots/`, {
        "pagination": {
          "page": 1,
          "limit": 100
        }
      });
      if (response.data.code === 200 && response.data.result) {
        setArrBotsAll(response.data.result.items);
      } else {
        toast.error(`Error loading bots`);
      }
    } catch (error) {
      toast.error(`Error loading api keys`);
    }
  };

  const transformKeysToOptions = (keys: KEY[]) => {
    return keys.map(key => ({
      value: key.id,
      text: key.name
    }));
  };

  const apiKeyOptions = transformKeysToOptions(arrApiKeys);
  const botsAllOptions = transformBotsToOptions(arrBotsAll);
  const symbolData = localStorage.getItem('symbols') ? transformSymbolsToOptions(JSON.parse(localStorage.getItem('symbols') || "")) : []

  return (
    <div className="flex flex-col h-full">
      <div className="flex-grow mb-4">
        <MultipleSelect
          label="Choose symbols"
          options={symbolData || []}
          defaultValue={symbolsSelect}
          onChange={handleSymbolChange}
          className='w-full mb-4'
        />

        <div className='flex flex-col gap-2 mb-4'>
          <p className='text-black dark:text-white'>Choose period data</p>
          <DatePicker
            options={{
              mode: 'range',
              static: true,
              monthSelectorType: 'static',
              dateFormat: 'M j, Y',
              onClose: handleDateChange,
              defaultDate: selectedDates,
            }}
          // ref={dateRangeRef}
          />
        </div>

        <MultipleSelect
          label="Choose bots"
          options={botsAllOptions.map(bot => ({ value: bot.value, text: bot.text, selected: botsAll.includes(bot.value) }))}
          defaultValue={botsAll}
          onChange={handleBotChange}
          className='w-full mb-4'
          disabled={apiKeys.length > 0}
        />

        <MultipleSelect
          label="Choose api keys"
          options={apiKeyOptions.map(key => ({ value: key.value, text: key.text, selected: apiKeys.includes(key.value) }))}
          defaultValue={apiKeys}
          onChange={handleApiKeysChange}
          className='w-full mb-4'
          disabled={botsAll.length > 0}
        />
      </div>

      <div className='flex items-center justify-between gap-4 mt-auto'>
        <BtnPrimary
          className='w-full'
          onClick={handleApplyFilter}>
          <BsFunnelFill /> Apply
        </BtnPrimary>
        <BtnPrimary
          className='w-full'
          style="outline"
          onClick={toggleModal}>
          <BsXCircleFill /> Close
        </BtnPrimary>
      </div>

    </div>
  );
};

export default PnlFilterNew;