import React, { useState, useEffect, useRef } from 'react';
import SimpleSelect from "../../components/Forms/Select/SimpleSelect";
import InputField from '../../components/Forms/Inputs/InputField';
import BtnPrimary from '../../components/Buttons/BtnPrimary';
import { FaMoneyBillTransfer } from "react-icons/fa6";
import { KEY, GetApiKeysAll, InfoBingX } from '../../types/key';
import { ApiResponse } from '../../types/api';
import api from '../../utils/api';
import { Toaster, toast } from 'sonner';
import { formatCurrency } from '../../utils/formatCurrency';
import { useApiKeysContext } from '../../context/ApiKeysContext';
import { ManagementAccounts, Account } from './interface';
import { BiTransfer } from "react-icons/bi";
import { TbPointFilled } from "react-icons/tb";
import { ContentModalCenterProps, requestTrasferManagement } from './interface';
import { messages } from '../../data/messages';
import MultiSelect from '../../components/Forms/Select/MultiSelect';

const ContentModalAddTask: React.FC<ContentModalCenterProps> = ({ toggleModal, accFrom, onHandle, accounts }) => {

  const [type, setType] = useState<string>('transfer');
  const [fromSubs, setFromSubs] = useState<string[]>([]);
  const [fromSubsName, setFromSubsName] = useState<string[]>([]);
  const [toMainID, setToMainID] = useState<string>(accFrom.id);
  const [toMainName, setToMainName] = useState<string>(accFrom.name);
  const [startBalance, setStartBalance] = useState<number>(0);
  const [amount, setAmount] = useState<number>(0);
  const [error, setError] = useState<string | null>(null);

  const handleFromSubsChange = (selected: string[]) => {
    setFromSubs(selected);
    let subs: {uid: string, name: string}[] = [];
    accFrom.subAccounts.map((item, i) => {
      subs.push({
        uid: item.account.uid,
        name: item.account.name,
      });
    });
    let selectedSubs = subs.filter((item,i) => {
      return selected.includes(item.uid)
    });
    let namesSelectedSubs: string[] = [];
    selectedSubs.map((item) => namesSelectedSubs.push(item.name));
    setFromSubsName(namesSelectedSubs);
};

  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setAmount(parseFloat(value));
  };

  const handleStartBalanceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setStartBalance(parseFloat(value));
  };

  const handleAddTask = async () => {
    try {
      const response = await api.post(`/api/management/tasks`, {
        type: type,
        fromSubs: fromSubs,
        fromSubsName: fromSubsName,
        toMainID: toMainID,
        toMainName: toMainName,
        startBalance: startBalance,
        amount: parseFloat(amount.toString())
      });

      if (response.data.code === 404) {
        return;
      }

      if (response.data.code === 200 && response.data.error === null) {
        toast.success(messages.transfers.success);
        onHandle(true);
        toggleModal();
      } else {
        toast.error(response.data.error);
        setError(messages.transfers.failed);
        onHandle(false);
      }
    } catch (error) {
      setError(messages.transfers.failed);
      onHandle(false);
    }
  };

  return (
    <>
      <p className="mb-6 text-black dark:text-white">Add task</p>

      <div className="flex flex-row items-center gap-3">
        <MultiSelect
          label="Choose from Subs"
          options={accFrom.subAccounts.map(acc => ({ value: acc.account.uid, text: acc.account.name, selected: [] }))}
          defaultValue={fromSubs}
          onChange={handleFromSubsChange}
          className='z-50 w-full text-left'
          // disabled={botsAll.length > 0}
          multiple={true}
        />
      </div>

      <div className="flex flex-row items-center gap-3">
        <InputField
          label="Start balance"
          value={startBalance}
          onChange={handleStartBalanceChange}
          placeholder="Start balance"
          className="w-full mb-4 text-left"
          type="number"
          step="any"
        />

        <InputField
          label="Amount"
          value={amount}
          onChange={handleAmountChange}
          placeholder="Amount"
          className="w-full mb-4 text-left"
          type="number"
          step="any"
        />
      </div>

      <BtnPrimary
        className='justify-center w-full'
        onClick={handleAddTask}
        disabled={!fromSubs || !startBalance || !amount}
      >
        <FaMoneyBillTransfer /> Add Task
      </BtnPrimary>
    </>
  );
};

export default ContentModalAddTask;