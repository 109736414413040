import React, { useEffect, useState } from 'react';
import api from '../../utils/api';
import Loader from '../../common/Loader/index';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import { Toaster, toast } from 'sonner';
import BtnPrimary from "../../components/Buttons/BtnPrimary";
import { BsFillLockFill, BsPlusCircleFill, BsFunnelFill, BsThreeDotsVertical } from "react-icons/bs";
import { FaUndo } from "react-icons/fa";
import { HiOutlineRefresh } from "react-icons/hi";
import { GrDetach } from "react-icons/gr";
import DarkModeSwitcher from '../../components/Header/DarkModeSwitcher';
import Switcher from "../../components/Switchers/Switcher";
import { UserRoles } from '../../types/user';
import InputField from '../../components/Forms/Inputs/InputField';
import CheckboxInput from '../../components/Forms/Inputs/CheckboxInput';
import DatePicker from '../../components/Forms/DatePicker';
import DropdownUser from '../../components/Header/DropdownUser';
import ModalBottom from '../../components/Modals/ModalBottom';
import { KEY } from "../../types/key";

const breadcrumbItems = [
  { name: 'Dashboard', link: '/dashboard' },
  { name: `UI-Elements`, link: '/ui-elements' }
];

const UiElements: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isSwither, setIsSwitcher] = useState<boolean>(false)
  const [isProcentExample, setIsProcentExample] = useState<boolean>(false)
  const [isLoadingBtnExample, setIsLoadingBtnExample] = useState<boolean>(true)
  const [textExample, setTextExample] = useState<string>('Some text');
  const [floatExample, setFloatExample] = useState<number>(0.001);

  const currentDateStart = new Date(new Date().setUTCHours(0, 0, 0, 0));
  const currentDateEnd = new Date(new Date().setUTCHours(23, 59, 59, 999));
  const sDate = currentDateStart.setUTCDate(currentDateStart.getUTCDate() - 6);
  const eDate = currentDateEnd.getTime();
  const [startDate, setStartDate] = useState<number>(sDate);
  const [endDate, setEndDate] = useState<number>(eDate);
  const [selectedDates, setSelectedDates] = useState<Date[]>([new Date(startDate), new Date(endDate)]);

  const [isModalActionsOpen, setIsModalActionsOpen] = useState(false);
  const toggleModalActions = () => setIsModalActionsOpen(!isModalActionsOpen);

  const exampleKey = {
    id: "67c2e2a67eca78020b1e62cc",
    user_id: "6720bfd1756ef702b4a718f6",
    bot_id: "000000000000000000000000",
    invalid: false,
    subAccountId: "",
    name: "Betarost OKX",
    exchange_name: "okx",
    category: "",
    asset: "",
    market: "futures",
    public_key: "54108838-4297-4c3d-892e-4b253e53609c",
    private_key: "",
    memo: "Qazwsx21!",
    comment: "",
    favorite: false,
    account: {
      futureBalance: {
        asset: "USDT",
        balance: 0,
        availableBalance: 0,
        crossWalletBalance: 0,
        unrealizedProfit: 0
      },
      futurePositions: [
        {
          symbol: "",
          positionSide: "",
          positionAmt: 0,
          entryPrice: 0,
          markPrice: 0,
          unRealizedProfit: 0,
          realizedProfit: 0,
          notional: 0,
          initialMargin: 0,
          updateTime: 0,
        }
      ],
      futureOpenOrder: [{
        symbol: "",
        orderId: "",
        side: "",
        positionSide: "",
        positionAmt: 0,
        price: 0,
        notional: 0,
        type: "",
        status: "",
        time: 0,
        updateTime: 0,
      }],
    },
    infoBinance: {
      accountFuture: {
        userId: "",
        asset: "",
        balance: "",
        equity: "",
        unrealizedProfit: "",
        realisedProfit: "",
        availableMargin: "",
        usedMargin: "",
        freezedMargin: ""
      },
      accountSpot: [
        {
          asset: "",
          free: "",
          locked: "",
        }
      ],
      openOrder: [
        {
          symbol: "",
          orderId: 0,
          side: "",
          positionSide: "",
          type: "",
          origQty: "",
          price: "",
          executedQty: "",
          avgPrice: "",
          cumQuote: "",
          stopPrice: "",
          profit: "",
          commission: "",
          status: "",
          time: 0,
          updateTime: 0,
          clientOrderId: "",
          leverage: "",
          takeProfit: {
            type: "",
            quantity: 0,
            stopPrice: 0,
            price: 0,
            workingType: "",
            StopGuaranteed: "",
          },
          stopLoss: {
            type: "",
            quantity: 0,
            stopPrice: 0,
            price: 0,
            workingType: "",
            StopGuaranteed: "",
          },
          advanceAttr: 0,
          positionID: 0,
          takeProfitEntrustPrice: 0,
          stopLossEntrustPrice: 0,
          orderType: "",
          workingType: "",
          stopGuaranteed: "",
          triggerOrderId: 0,
          trailingStopRate: 0,
          trailingStopDistance: 0,
          postOnly: false,
        }
      ],
      positions: [
        {
          symbol: "",
          positionId: "",
          positionSide: "",
          isolated: false,
          positionAmt: "",
          availableAmt: "",
          unrealizedProfit: "",
          realisedProfit: "",
          initialMargin: "",
          margin: "",
          avgPrice: "",
          liquidationPrice: 0,
          leverage: 0,
          positionValue: "",
          markPrice: "",
          riskRate: "",
          maxMarginReduction: "",
          pnlRatio: "",
          updateTime: 0,
        }
      ]
    },
    infoBingX: {
      accountFuture: {
        userId: "",
        asset: "",
        balance: "",
        equity: "",
        unrealizedProfit: "",
        realisedProfit: "",
        availableMargin: "",
        usedMargin: "",
        freezedMargin: ""
      },
      accountSpot: [
        {
          asset: "",
          free: "",
          locked: "",
        }
      ],
      openOrder: [
        {
          symbol: "",
          orderId: 0,
          side: "",
          positionSide: "",
          type: "",
          origQty: "",
          price: "",
          executedQty: "",
          avgPrice: "",
          cumQuote: "",
          stopPrice: "",
          profit: "",
          commission: "",
          status: "",
          time: 0,
          updateTime: 0,
          clientOrderId: "",
          leverage: "",
          takeProfit: {
            type: "",
            quantity: 0,
            stopPrice: 0,
            price: 0,
            workingType: "",
            StopGuaranteed: "",
          },
          stopLoss: {
            type: "",
            quantity: 0,
            stopPrice: 0,
            price: 0,
            workingType: "",
            StopGuaranteed: "",
          },
          advanceAttr: 0,
          positionID: 0,
          takeProfitEntrustPrice: 0,
          stopLossEntrustPrice: 0,
          orderType: "",
          workingType: "",
          stopGuaranteed: "",
          triggerOrderId: 0,
          trailingStopRate: 0,
          trailingStopDistance: 0,
          postOnly: false,
        }
      ],
      positions: [
        {
          symbol: "",
          positionId: "",
          positionSide: "",
          isolated: false,
          positionAmt: "",
          availableAmt: "",
          unrealizedProfit: "",
          realisedProfit: "",
          initialMargin: "",
          margin: "",
          avgPrice: "",
          liquidationPrice: 0,
          leverage: 0,
          positionValue: "",
          markPrice: "",
          riskRate: "",
          maxMarginReduction: "",
          pnlRatio: "",
          updateTime: 0,
        }
      ]
    },
    infoBybit: {
      accountFuture: {
        userId: "",
        asset: "",
        balance: "",
        equity: "",
        unrealizedProfit: "",
        realisedProfit: "",
        availableMargin: "",
        usedMargin: "",
        freezedMargin: ""
      },
      accountSpot: [
        {
          asset: "",
          free: "",
          locked: "",
        }
      ],
      openOrder: [
        {
          symbol: "",
          orderId: 0,
          side: "",
          positionSide: "",
          type: "",
          origQty: "",
          price: "",
          executedQty: "",
          avgPrice: "",
          cumQuote: "",
          stopPrice: "",
          profit: "",
          commission: "",
          status: "",
          time: 0,
          updateTime: 0,
          clientOrderId: "",
          leverage: "",
          takeProfit: {
            type: "",
            quantity: 0,
            stopPrice: 0,
            price: 0,
            workingType: "",
            StopGuaranteed: "",
          },
          stopLoss: {
            type: "",
            quantity: 0,
            stopPrice: 0,
            price: 0,
            workingType: "",
            StopGuaranteed: "",
          },
          advanceAttr: 0,
          positionID: 0,
          takeProfitEntrustPrice: 0,
          stopLossEntrustPrice: 0,
          orderType: "",
          workingType: "",
          stopGuaranteed: "",
          triggerOrderId: 0,
          trailingStopRate: 0,
          trailingStopDistance: 0,
          postOnly: false,
        }
      ],
      positions: [
        {
          symbol: "",
          positionId: "",
          positionSide: "",
          isolated: false,
          positionAmt: "",
          availableAmt: "",
          unrealizedProfit: "",
          realisedProfit: "",
          initialMargin: "",
          margin: "",
          avgPrice: "",
          liquidationPrice: 0,
          leverage: 0,
          positionValue: "",
          markPrice: "",
          riskRate: "",
          maxMarginReduction: "",
          pnlRatio: "",
          updateTime: 0,
        }
      ]
    },
    infoGate: {
      accountFuture: {
        userId: "",
        asset: "",
        balance: "",
        equity: "",
        unrealizedProfit: "",
        realisedProfit: "",
        availableMargin: "",
        usedMargin: "",
        freezedMargin: ""
      },
      accountSpot: [
        {
          asset: "",
          free: "",
          locked: "",
        }
      ],
      openOrder: [
        {
          symbol: "",
          orderId: 0,
          side: "",
          positionSide: "",
          type: "",
          origQty: "",
          price: "",
          executedQty: "",
          avgPrice: "",
          cumQuote: "",
          stopPrice: "",
          profit: "",
          commission: "",
          status: "",
          time: 0,
          updateTime: 0,
          clientOrderId: "",
          leverage: "",
          takeProfit: {
            type: "",
            quantity: 0,
            stopPrice: 0,
            price: 0,
            workingType: "",
            StopGuaranteed: "",
          },
          stopLoss: {
            type: "",
            quantity: 0,
            stopPrice: 0,
            price: 0,
            workingType: "",
            StopGuaranteed: "",
          },
          advanceAttr: 0,
          positionID: 0,
          takeProfitEntrustPrice: 0,
          stopLossEntrustPrice: 0,
          orderType: "",
          workingType: "",
          stopGuaranteed: "",
          triggerOrderId: 0,
          trailingStopRate: 0,
          trailingStopDistance: 0,
          postOnly: false,
        }
      ],
      positions: [
        {
          symbol: "",
          positionId: "",
          positionSide: "",
          isolated: false,
          positionAmt: "",
          availableAmt: "",
          unrealizedProfit: "",
          realisedProfit: "",
          initialMargin: "",
          margin: "",
          avgPrice: "",
          liquidationPrice: 0,
          leverage: 0,
          positionValue: "",
          markPrice: "",
          riskRate: "",
          maxMarginReduction: "",
          pnlRatio: "",
          updateTime: 0,
        }
      ]
    },
    infoMexc: {
      accountFuture: {
        userId: "",
        asset: "",
        balance: "",
        equity: "",
        unrealizedProfit: "",
        realisedProfit: "",
        availableMargin: "",
        usedMargin: "",
        freezedMargin: ""
      },
      accountSpot: [
        {
          asset: "",
          free: "",
          locked: "",
        }
      ],
      openOrder: [
        {
          symbol: "",
          orderId: 0,
          side: "",
          positionSide: "",
          type: "",
          origQty: "",
          price: "",
          executedQty: "",
          avgPrice: "",
          cumQuote: "",
          stopPrice: "",
          profit: "",
          commission: "",
          status: "",
          time: 0,
          updateTime: 0,
          clientOrderId: "",
          leverage: "",
          takeProfit: {
            type: "",
            quantity: 0,
            stopPrice: 0,
            price: 0,
            workingType: "",
            StopGuaranteed: "",
          },
          stopLoss: {
            type: "",
            quantity: 0,
            stopPrice: 0,
            price: 0,
            workingType: "",
            StopGuaranteed: "",
          },
          advanceAttr: 0,
          positionID: 0,
          takeProfitEntrustPrice: 0,
          stopLossEntrustPrice: 0,
          orderType: "",
          workingType: "",
          stopGuaranteed: "",
          triggerOrderId: 0,
          trailingStopRate: 0,
          trailingStopDistance: 0,
          postOnly: false,
        }
      ],
      positions: [
        {
          symbol: "",
          positionId: "",
          positionSide: "",
          isolated: false,
          positionAmt: "",
          availableAmt: "",
          unrealizedProfit: "",
          realisedProfit: "",
          initialMargin: "",
          margin: "",
          avgPrice: "",
          liquidationPrice: 0,
          leverage: 0,
          positionValue: "",
          markPrice: "",
          riskRate: "",
          maxMarginReduction: "",
          pnlRatio: "",
          updateTime: 0,
        }
      ]
    },
    infoBitget: {
      accountFuture: {
        userId: "",
        asset: "",
        balance: "",
        equity: "",
        unrealizedProfit: "",
        realisedProfit: "",
        availableMargin: "",
        usedMargin: "",
        freezedMargin: ""
      },
      accountSpot: [
        {
          asset: "",
          free: "",
          locked: "",
        }
      ],
      openOrder: [
        {
          symbol: "",
          orderId: 0,
          side: "",
          positionSide: "",
          type: "",
          origQty: "",
          price: "",
          executedQty: "",
          avgPrice: "",
          cumQuote: "",
          stopPrice: "",
          profit: "",
          commission: "",
          status: "",
          time: 0,
          updateTime: 0,
          clientOrderId: "",
          leverage: "",
          takeProfit: {
            type: "",
            quantity: 0,
            stopPrice: 0,
            price: 0,
            workingType: "",
            StopGuaranteed: "",
          },
          stopLoss: {
            type: "",
            quantity: 0,
            stopPrice: 0,
            price: 0,
            workingType: "",
            StopGuaranteed: "",
          },
          advanceAttr: 0,
          positionID: 0,
          takeProfitEntrustPrice: 0,
          stopLossEntrustPrice: 0,
          orderType: "",
          workingType: "",
          stopGuaranteed: "",
          triggerOrderId: 0,
          trailingStopRate: 0,
          trailingStopDistance: 0,
          postOnly: false,
        }
      ],
      positions: [
        {
          symbol: "",
          positionId: "",
          positionSide: "",
          isolated: false,
          positionAmt: "",
          availableAmt: "",
          unrealizedProfit: "",
          realisedProfit: "",
          initialMargin: "",
          margin: "",
          avgPrice: "",
          liquidationPrice: 0,
          leverage: 0,
          positionValue: "",
          markPrice: "",
          riskRate: "",
          maxMarginReduction: "",
          pnlRatio: "",
          updateTime: 0,
        }
      ]
    },
    infoOKX: {
      accountFuture: {
        userId: "",
        asset: "",
        balance: "",
        equity: "",
        unrealizedProfit: "",
        realisedProfit: "",
        availableMargin: "",
        usedMargin: "",
        freezedMargin: ""
      },
      accountSpot: [
        {
          asset: "",
          free: "",
          locked: "",
        }
      ],
      openOrder: [
        {
          symbol: "",
          orderId: 0,
          side: "",
          positionSide: "",
          type: "",
          origQty: "",
          price: "",
          executedQty: "",
          avgPrice: "",
          cumQuote: "",
          stopPrice: "",
          profit: "",
          commission: "",
          status: "",
          time: 0,
          updateTime: 0,
          clientOrderId: "",
          leverage: "",
          takeProfit: {
            type: "",
            quantity: 0,
            stopPrice: 0,
            price: 0,
            workingType: "",
            StopGuaranteed: "",
          },
          stopLoss: {
            type: "",
            quantity: 0,
            stopPrice: 0,
            price: 0,
            workingType: "",
            StopGuaranteed: "",
          },
          advanceAttr: 0,
          positionID: 0,
          takeProfitEntrustPrice: 0,
          stopLossEntrustPrice: 0,
          orderType: "",
          workingType: "",
          stopGuaranteed: "",
          triggerOrderId: 0,
          trailingStopRate: 0,
          trailingStopDistance: 0,
          postOnly: false,
        }
      ],
      positions: [
        {
          symbol: "",
          positionId: "",
          positionSide: "",
          isolated: false,
          positionAmt: "",
          availableAmt: "",
          unrealizedProfit: "",
          realisedProfit: "",
          initialMargin: "",
          margin: "",
          avgPrice: "",
          liquidationPrice: 0,
          leverage: 0,
          positionValue: "",
          markPrice: "",
          riskRate: "",
          maxMarginReduction: "",
          pnlRatio: "",
          updateTime: 0,
        }
      ]
    },
    infoHuobi: {
      accountFuture: {
        userId: "",
        asset: "",
        balance: "",
        equity: "",
        unrealizedProfit: "",
        realisedProfit: "",
        availableMargin: "",
        usedMargin: "",
        freezedMargin: ""
      },
      accountSpot: [
        {
          asset: "",
          free: "",
          locked: "",
        }
      ],
      openOrder: [
        {
          symbol: "",
          orderId: 0,
          side: "",
          positionSide: "",
          type: "",
          origQty: "",
          price: "",
          executedQty: "",
          avgPrice: "",
          cumQuote: "",
          stopPrice: "",
          profit: "",
          commission: "",
          status: "",
          time: 0,
          updateTime: 0,
          clientOrderId: "",
          leverage: "",
          takeProfit: {
            type: "",
            quantity: 0,
            stopPrice: 0,
            price: 0,
            workingType: "",
            StopGuaranteed: "",
          },
          stopLoss: {
            type: "",
            quantity: 0,
            stopPrice: 0,
            price: 0,
            workingType: "",
            StopGuaranteed: "",
          },
          advanceAttr: 0,
          positionID: 0,
          takeProfitEntrustPrice: 0,
          stopLossEntrustPrice: 0,
          orderType: "",
          workingType: "",
          stopGuaranteed: "",
          triggerOrderId: 0,
          trailingStopRate: 0,
          trailingStopDistance: 0,
          postOnly: false,
        }
      ],
      positions: [
        {
          symbol: "",
          positionId: "",
          positionSide: "",
          isolated: false,
          positionAmt: "",
          availableAmt: "",
          unrealizedProfit: "",
          realisedProfit: "",
          initialMargin: "",
          margin: "",
          avgPrice: "",
          liquidationPrice: 0,
          leverage: 0,
          positionValue: "",
          markPrice: "",
          riskRate: "",
          maxMarginReduction: "",
          pnlRatio: "",
          updateTime: 0,
        }
      ]
    },
    bot: {
      _id: "000000000000000000000000",
      apiID: "000000000000000000000000",
      uuid: "",
      name: "",
      active: false,
      symbol: "",
      category: "",
      comment: "",
      settings: {
        symbol: "",
        strategy: "",
        leverage: 0,
        initUSDT: 0,
        timeFrame: "",
        takeProfit: 0,
        stopLose: 0,
        maxCountAVG: 0,
        averageStep: 0,
        procentStep: 0,
        delaySwap: 0,
        needOpenExtra: false,
        procentForOpen: 0,
        procentForStop: 0,
        lengthEMA: 0,
        tool: "",
        algorithmOption: "",
        indentationPlacing: 0,
        algorithm: "",
        delays: 0,
        typeDelayStart: "",
        useDelayStart: false,
        delaysStartSec: 0,
        delaysStartProc: 0,
        delaysUp: 0,
        multiplierUp: 0,
        maxUp: 0,
        procentUPRate: 0,
        koefUP: 0,
        timeUPWait: 0,
        initVolume: 0,
        takeProfitMarket: 0,
        takeProfitAverage: 0,
        useHedge: false,
        useAveraging: false,
        useAveragingProfit: false,
        useAveragingProfitProtect: false,
        useAveragingSave: false,
        useAveragingExtra: false,
        useStopAveraging: false,
        useRestartAveraging: false,
        delayRestartAveraging: 0,
        volumeAveraging: 0,
        countAveraging: 0,
        stepAveraging: 0,
        rateMultiplierAveraging: 0,
        multiplierVolumeAveraging: 0,
        multiplierStepAveraging: 0,
        rateProfitProtect: 0,
        numAveStartSave: 0,
        rateSave: 0,
        numAveStartExtra: 0,
        rateExtra: 0,
        stopWorkTradeAlg: false,
        useRestartAfterStop: false,
        delaysRestart: 0,
        averagingType: "",
        liquidityVolume: 0
      },
      dateActive: 0,
      dateStop: 0,
      dateConnect: 0,
      dateCreate: 0,
      dateUpdate: 0
    },
    created_at: 1740825254141,
    updated_at: 1742389889147
  };
  const [selectedKey, setSelectedKey] = useState<KEY | null>(); //exampleKey


  const breadcrumbItemsExample = [
    { name: 'Home', link: '/' },
    { name: 'Page', link: '/' },
    { name: `Page Details`, link: '/' }
  ];

  const handleSwitcherChange = () => {
    setIsSwitcher(!isSwither);
  }

  const handleIsProcentExample = () => {
    setIsProcentExample(!isProcentExample);
  }

  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTextExample(e.target.value);
  };

  const handleFloatChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFloatExample(parseFloat(e.target.value));
  };

  const handleDateRangeChange = (selectedDate: Date[]) => {
    if (selectedDate.length === 2) {
      setSelectedDates(selectedDate);
    }
  };

  const handleActionsBot = (/*bot: Db_Bots*/) => {
    // setSelectedBot(bot);
    toggleModalActions();
  };

  useEffect(() => {
    setIsLoading(false);
  }, []);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div>
      <Toaster position="bottom-center" richColors />
      <Breadcrumb items={breadcrumbItems} />
      <div className="">
        <div className="w-full md:w-md lg:w-150 px-5 py-5 bg-[#F9FAFB] dark:bg-meta-4 rounded-[10px] border border-dashed mb-6">
          <h3 className="text-base font-semibold leading-6 text-gray-900 mb-6">Buttons</h3>
          <div className="grid grid-cols-1 md:grid-cols-4 gap-6">
            <BtnPrimary  className='justify-center' style="green"><BsFillLockFill /> Closed</BtnPrimary>
            <BtnPrimary  className='justify-center' style="green" disabled><BsFillLockFill /> Closed</BtnPrimary>
            <BtnPrimary  className='justify-center' style="outline green"><BsFillLockFill /> Closed</BtnPrimary>
            <BtnPrimary  className='justify-center' style="outline green" disabled><BsFillLockFill /> Closed</BtnPrimary>
            
            <BtnPrimary  className='justify-center' style="red"><BsFillLockFill /> Close</BtnPrimary>
            <BtnPrimary  className='justify-center' style="red" disabled><BsFillLockFill /> Close</BtnPrimary>
            <BtnPrimary  className='justify-center' style="outline red"><BsFillLockFill /> Close</BtnPrimary>
            <BtnPrimary  className='justify-center' style="outline red" disabled><BsFillLockFill /> Close</BtnPrimary>
            
            {/* <BtnPrimary className='justify-center'>
              <BsPlusCircleFill /> Add key
            </BtnPrimary> */}
            
            <BtnPrimary className='justify-center'><BsFunnelFill /> Filter</BtnPrimary>
            <BtnPrimary className='justify-center' disabled><BsFunnelFill /> Filter</BtnPrimary>
            <BtnPrimary className='justify-center' style="outline"><BsFunnelFill /> Filter</BtnPrimary>
            <BtnPrimary className='justify-center' style="outline" disabled><BsFunnelFill /> Filter</BtnPrimary>

            {/* <BtnPrimary className='justify-center'>
              <FaUndo /> Reset Filter
            </BtnPrimary>

            <BtnPrimary className='justify-center'>
              <HiOutlineRefresh /> Refresh
            </BtnPrimary> */}

            <button className='flex items-center justify-self-start gap-2 transition duration-300 ease-in-out rounded-lg py-1 px-1 font-medium hover:bg-opacity-90 bg-primary text-white w-auto'>
              <GrDetach />
            </button>
            <button className='flex items-center justify-self-start gap-2 transition duration-300 ease-in-out rounded-lg py-1 px-1 font-medium hover:bg-opacity-90 bg-primary text-white w-auto opacity-50 cursor-not-allowed' disabled>
              <GrDetach />
            </button>
            <div className="col-span-2"></div>

            <button
              type="submit"
              className={`w-full rounded-lg border border-primary bg-primary p-4 text-white transition hover:bg-opacity-90 cursor-pointer`}
            >
              {isLoadingBtnExample ?
                <div className='flex gap-3 items-center justify-center'>
                  <span className="animate-spin">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <mask id="path-1-inside-1_1881_16183" fill="white">
                      <path d="M15.328 23.5293C17.8047 22.8144 19.9853 21.321 21.547 19.2701C23.1087 17.2193 23.9686 14.72 23.9992 12.1424C24.0297 9.56481 23.2295 7.04587 21.7169 4.95853C20.2043 2.8712 18.0597 1.32643 15.6007 0.552947C13.1417 -0.220538 10.499 -0.181621 8.0638 0.663935C5.62864 1.50949 3.53049 3.11674 2.07999 5.24771C0.629495 7.37868 -0.096238 9.92009 0.0102418 12.4957C0.116722 15.0713 1.04975 17.5441 2.6712 19.5481L4.96712 17.6904C3.74474 16.1796 3.04133 14.3154 2.96106 12.3737C2.88079 10.432 3.42791 8.51604 4.52142 6.90953C5.61493 5.30301 7.19671 4.09133 9.03255 3.45387C10.8684 2.81642 12.8607 2.78708 14.7145 3.3702C16.5683 3.95332 18.1851 5.1179 19.3254 6.69152C20.4658 8.26514 21.0691 10.1641 21.046 12.1074C21.023 14.0506 20.3748 15.9347 19.1974 17.4809C18.02 19.027 16.3761 20.1528 14.5089 20.6918L15.328 23.5293Z"></path></mask><path d="M15.328 23.5293C17.8047 22.8144 19.9853 21.321 21.547 19.2701C23.1087 17.2193 23.9686 14.72 23.9992 12.1424C24.0297 9.56481 23.2295 7.04587 21.7169 4.95853C20.2043 2.8712 18.0597 1.32643 15.6007 0.552947C13.1417 -0.220538 10.499 -0.181621 8.0638 0.663935C5.62864 1.50949 3.53049 3.11674 2.07999 5.24771C0.629495 7.37868 -0.096238 9.92009 0.0102418 12.4957C0.116722 15.0713 1.04975 17.5441 2.6712 19.5481L4.96712 17.6904C3.74474 16.1796 3.04133 14.3154 2.96106 12.3737C2.88079 10.432 3.42791 8.51604 4.52142 6.90953C5.61493 5.30301 7.19671 4.09133 9.03255 3.45387C10.8684 2.81642 12.8607 2.78708 14.7145 3.3702C16.5683 3.95332 18.1851 5.1179 19.3254 6.69152C20.4658 8.26514 21.0691 10.1641 21.046 12.1074C21.023 14.0506 20.3748 15.9347 19.1974 17.4809C18.02 19.027 16.3761 20.1528 14.5089 20.6918L15.328 23.5293Z" stroke="white" strokeWidth="14" mask="url(#path-1-inside-1_1881_16183)"></path>
                    </svg>
                  </span>
                  <p>Loading...</p>
                </div>
                : <div className="flex items-center justify-center gap-4 mt-auto"><BsPlusCircleFill /> Button</div>
              }
            </button>
            <button
              type="submit"
              className={`w-full rounded-lg border border-primary bg-primary p-4 text-white transition hover:bg-opacity-90 cursor-pointer opacity-50 cursor-not-allowed`}
              disabled
            >
              {isLoadingBtnExample ?
                <div className='flex gap-3 items-center justify-center'>
                  <span className="animate-spin">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <mask id="path-1-inside-1_1881_16183" fill="white">
                      <path d="M15.328 23.5293C17.8047 22.8144 19.9853 21.321 21.547 19.2701C23.1087 17.2193 23.9686 14.72 23.9992 12.1424C24.0297 9.56481 23.2295 7.04587 21.7169 4.95853C20.2043 2.8712 18.0597 1.32643 15.6007 0.552947C13.1417 -0.220538 10.499 -0.181621 8.0638 0.663935C5.62864 1.50949 3.53049 3.11674 2.07999 5.24771C0.629495 7.37868 -0.096238 9.92009 0.0102418 12.4957C0.116722 15.0713 1.04975 17.5441 2.6712 19.5481L4.96712 17.6904C3.74474 16.1796 3.04133 14.3154 2.96106 12.3737C2.88079 10.432 3.42791 8.51604 4.52142 6.90953C5.61493 5.30301 7.19671 4.09133 9.03255 3.45387C10.8684 2.81642 12.8607 2.78708 14.7145 3.3702C16.5683 3.95332 18.1851 5.1179 19.3254 6.69152C20.4658 8.26514 21.0691 10.1641 21.046 12.1074C21.023 14.0506 20.3748 15.9347 19.1974 17.4809C18.02 19.027 16.3761 20.1528 14.5089 20.6918L15.328 23.5293Z"></path></mask><path d="M15.328 23.5293C17.8047 22.8144 19.9853 21.321 21.547 19.2701C23.1087 17.2193 23.9686 14.72 23.9992 12.1424C24.0297 9.56481 23.2295 7.04587 21.7169 4.95853C20.2043 2.8712 18.0597 1.32643 15.6007 0.552947C13.1417 -0.220538 10.499 -0.181621 8.0638 0.663935C5.62864 1.50949 3.53049 3.11674 2.07999 5.24771C0.629495 7.37868 -0.096238 9.92009 0.0102418 12.4957C0.116722 15.0713 1.04975 17.5441 2.6712 19.5481L4.96712 17.6904C3.74474 16.1796 3.04133 14.3154 2.96106 12.3737C2.88079 10.432 3.42791 8.51604 4.52142 6.90953C5.61493 5.30301 7.19671 4.09133 9.03255 3.45387C10.8684 2.81642 12.8607 2.78708 14.7145 3.3702C16.5683 3.95332 18.1851 5.1179 19.3254 6.69152C20.4658 8.26514 21.0691 10.1641 21.046 12.1074C21.023 14.0506 20.3748 15.9347 19.1974 17.4809C18.02 19.027 16.3761 20.1528 14.5089 20.6918L15.328 23.5293Z" stroke="white" strokeWidth="14" mask="url(#path-1-inside-1_1881_16183)"></path>
                    </svg>
                  </span>
                  <p>Loading...</p>
                </div>
                : <div className="flex items-center justify-center gap-4 mt-auto"><BsPlusCircleFill /> Button</div>
              }
            </button>
            <button
              type="submit"
              className={`w-full rounded-lg border border-primary bg-primary p-4 text-white transition hover:bg-opacity-90 cursor-pointer`}
            >
              {isLoadingBtnExample ?
                <div className='flex gap-3 items-center justify-center'>
                  <span className="animate-spin">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <mask id="path-1-inside-1_1881_16183" fill="white">
                      <path d="M15.328 23.5293C17.8047 22.8144 19.9853 21.321 21.547 19.2701C23.1087 17.2193 23.9686 14.72 23.9992 12.1424C24.0297 9.56481 23.2295 7.04587 21.7169 4.95853C20.2043 2.8712 18.0597 1.32643 15.6007 0.552947C13.1417 -0.220538 10.499 -0.181621 8.0638 0.663935C5.62864 1.50949 3.53049 3.11674 2.07999 5.24771C0.629495 7.37868 -0.096238 9.92009 0.0102418 12.4957C0.116722 15.0713 1.04975 17.5441 2.6712 19.5481L4.96712 17.6904C3.74474 16.1796 3.04133 14.3154 2.96106 12.3737C2.88079 10.432 3.42791 8.51604 4.52142 6.90953C5.61493 5.30301 7.19671 4.09133 9.03255 3.45387C10.8684 2.81642 12.8607 2.78708 14.7145 3.3702C16.5683 3.95332 18.1851 5.1179 19.3254 6.69152C20.4658 8.26514 21.0691 10.1641 21.046 12.1074C21.023 14.0506 20.3748 15.9347 19.1974 17.4809C18.02 19.027 16.3761 20.1528 14.5089 20.6918L15.328 23.5293Z"></path></mask><path d="M15.328 23.5293C17.8047 22.8144 19.9853 21.321 21.547 19.2701C23.1087 17.2193 23.9686 14.72 23.9992 12.1424C24.0297 9.56481 23.2295 7.04587 21.7169 4.95853C20.2043 2.8712 18.0597 1.32643 15.6007 0.552947C13.1417 -0.220538 10.499 -0.181621 8.0638 0.663935C5.62864 1.50949 3.53049 3.11674 2.07999 5.24771C0.629495 7.37868 -0.096238 9.92009 0.0102418 12.4957C0.116722 15.0713 1.04975 17.5441 2.6712 19.5481L4.96712 17.6904C3.74474 16.1796 3.04133 14.3154 2.96106 12.3737C2.88079 10.432 3.42791 8.51604 4.52142 6.90953C5.61493 5.30301 7.19671 4.09133 9.03255 3.45387C10.8684 2.81642 12.8607 2.78708 14.7145 3.3702C16.5683 3.95332 18.1851 5.1179 19.3254 6.69152C20.4658 8.26514 21.0691 10.1641 21.046 12.1074C21.023 14.0506 20.3748 15.9347 19.1974 17.4809C18.02 19.027 16.3761 20.1528 14.5089 20.6918L15.328 23.5293Z" stroke="white" strokeWidth="14" mask="url(#path-1-inside-1_1881_16183)"></path>
                    </svg>
                  </span>
                </div>
                : <div className="flex items-center justify-center gap-4 mt-auto"><BsPlusCircleFill /> Button</div>
              }
            </button>
            <button
              type="submit"
              className={`w-full rounded-lg border border-primary bg-primary p-4 text-white transition hover:bg-opacity-90 cursor-pointer opacity-50 cursor-not-allowed`}
              disabled
            >
              {isLoadingBtnExample ?
                <div className='flex gap-3 items-center justify-center'>
                  <span className="animate-spin">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <mask id="path-1-inside-1_1881_16183" fill="white">
                      <path d="M15.328 23.5293C17.8047 22.8144 19.9853 21.321 21.547 19.2701C23.1087 17.2193 23.9686 14.72 23.9992 12.1424C24.0297 9.56481 23.2295 7.04587 21.7169 4.95853C20.2043 2.8712 18.0597 1.32643 15.6007 0.552947C13.1417 -0.220538 10.499 -0.181621 8.0638 0.663935C5.62864 1.50949 3.53049 3.11674 2.07999 5.24771C0.629495 7.37868 -0.096238 9.92009 0.0102418 12.4957C0.116722 15.0713 1.04975 17.5441 2.6712 19.5481L4.96712 17.6904C3.74474 16.1796 3.04133 14.3154 2.96106 12.3737C2.88079 10.432 3.42791 8.51604 4.52142 6.90953C5.61493 5.30301 7.19671 4.09133 9.03255 3.45387C10.8684 2.81642 12.8607 2.78708 14.7145 3.3702C16.5683 3.95332 18.1851 5.1179 19.3254 6.69152C20.4658 8.26514 21.0691 10.1641 21.046 12.1074C21.023 14.0506 20.3748 15.9347 19.1974 17.4809C18.02 19.027 16.3761 20.1528 14.5089 20.6918L15.328 23.5293Z"></path></mask><path d="M15.328 23.5293C17.8047 22.8144 19.9853 21.321 21.547 19.2701C23.1087 17.2193 23.9686 14.72 23.9992 12.1424C24.0297 9.56481 23.2295 7.04587 21.7169 4.95853C20.2043 2.8712 18.0597 1.32643 15.6007 0.552947C13.1417 -0.220538 10.499 -0.181621 8.0638 0.663935C5.62864 1.50949 3.53049 3.11674 2.07999 5.24771C0.629495 7.37868 -0.096238 9.92009 0.0102418 12.4957C0.116722 15.0713 1.04975 17.5441 2.6712 19.5481L4.96712 17.6904C3.74474 16.1796 3.04133 14.3154 2.96106 12.3737C2.88079 10.432 3.42791 8.51604 4.52142 6.90953C5.61493 5.30301 7.19671 4.09133 9.03255 3.45387C10.8684 2.81642 12.8607 2.78708 14.7145 3.3702C16.5683 3.95332 18.1851 5.1179 19.3254 6.69152C20.4658 8.26514 21.0691 10.1641 21.046 12.1074C21.023 14.0506 20.3748 15.9347 19.1974 17.4809C18.02 19.027 16.3761 20.1528 14.5089 20.6918L15.328 23.5293Z" stroke="white" strokeWidth="14" mask="url(#path-1-inside-1_1881_16183)"></path>
                    </svg>
                  </span>
                </div>
                : <div className="flex items-center justify-center gap-4 mt-auto"><BsPlusCircleFill /> Button</div>
              }
            </button>
          </div>
        </div>

        <div className="w-full md:w-md lg:w-150 px-5 py-5 bg-[#F9FAFB] dark:bg-meta-4 rounded-[10px] border border-dashed mb-6">
          <h3 className="text-base font-semibold leading-6 text-gray-900 mb-6">Breadcrumbs</h3>
          <div className="grid grid-cols-1 gap-6">
            <Breadcrumb items={breadcrumbItemsExample} />
          </div>
        </div>

        <div className="w-full md:w-md lg:w-150 px-5 py-5 bg-[#F9FAFB] dark:bg-meta-4 rounded-[10px] border border-dashed mb-6">
          <h3 className="text-base font-semibold leading-6 text-gray-900 mb-6">Switchers</h3>
          <div className="grid grid-cols-3 gap-6">
            <ul className="list-none"><DarkModeSwitcher /></ul>
            <Switcher
              isActive={isSwither}
              onChange={() => { handleSwitcherChange() }}
              id="toombler"
              title="Переключатель"
              classNameWrap=""
            />

            <div className='flex gap-3 items-center'>
              <Switcher
                key="isProcentExample"
                isActive={isProcentExample}
                onChange={handleIsProcentExample}
                id={`isProcentExample`}
              />
              <p className='text-md text-black dark:text-white font-medium'>{`${isProcentExample ? "%" : "USDT"}`}</p>
            </div>
          </div>
        </div>

        <div className="w-full md:w-md lg:w-150 px-5 py-5 bg-[#F9FAFB] dark:bg-meta-4 rounded-[10px] border border-dashed mb-6">
          <h3 className="text-base font-semibold leading-6 text-gray-900 mb-6">Badge</h3>
          <div className="flex gap-6">
            <p className='inline-flex rounded-full bg-primary py-1 px-3 text-sm font-medium text-white'>admin</p>
            <p className="inline-flex rounded-full bg-[#637381] py-1 px-3 text-sm font-medium text-white">moderator</p>
            <p className="inline-flex rounded-full bg-[#EFEFEF] py-1 px-3 text-sm font-medium text-[#212B36]">user</p>
          </div>
        </div>

        <div className="w-full md:w-md lg:w-150 px-5 py-5 bg-[#F9FAFB] dark:bg-meta-4 rounded-[10px] border border-dashed mb-6">
          <h3 className="text-base font-semibold leading-6 text-gray-900 mb-6">Form fields</h3>
          <div className="grid grid-cols-1 gap-6">
            <InputField
              label="Input text"
              value={textExample}
              onChange={handleTextChange}
              placeholder="text"
              // className="mb-4"
            />

            <InputField
              label="Input text (disabled)"
              value={''}
              onChange={()=>{}}
              placeholder="text"
              className="mb-4"
              disabled={true}
            />

            <InputField
              label="Input number (float)"
              value={floatExample}
              onChange={(e)=>{handleFloatChange(e)}}
              placeholder="number"
              type="number"
              className="w-full"
            />

            <InputField
              label="Input number (disabled)"
              value={''}
              onChange={()=>{}}
              placeholder="number"
              type="number"
              className="w-full"
              disabled={true}
            />

            <CheckboxInput
              label="Text checkbox (checked)"
              value=""
              id="checkExample"
              checked
              onChange={()=>{}}
              className=""
            />

            <CheckboxInput
              label="Text checkbox (no-checked)"
              value=""
              id="checkExample2"
              onChange={()=>{}}
              className=""
            />

            <div className='flex flex-col gap-2 mb-4'>
              <p className='text-black dark:text-white'>Choose period data</p>
              <DatePicker
                options={{
                  mode: 'range',
                  static: true,
                  monthSelectorType: 'static',
                  dateFormat: 'M j, Y',
                  altFormat: "F j, Y",
                  onClose: handleDateRangeChange,
                  defaultDate: selectedDates,
                }}
              />
            </div>

            <div className='flex flex-col gap-2 mb-4'>
              <p className='text-black dark:text-white'>Choose data</p>
              <DatePicker
                options={{
                  // mode: 'range',
                  static: true,
                  monthSelectorType: 'static',
                  dateFormat: 'M j, Y',
                  altFormat: "F j, Y",
                  onClose: handleDateRangeChange,
                  // defaultDate: selectedDates,
                  defaultDate: new Date(Date.now()),
                }}
              />
            </div>
            
            <div className='flex flex-col  gap-2 mb-4'>
              <p className="text-black dark:text-white">Dropdown</p>
              <div className="flex flex-row justify-end">
                <DropdownUser />
              </div>
            </div>

            <div className='flex flex-col  gap-2 mb-4'>
              <p className="text-black dark:text-white">Mobile dropdown menu</p>
              <div className="flex flex-row justify-end">
                <BsThreeDotsVertical
                  className="cursor-pointer duration-300 ease-in-out hover:text-primary text-xl"
                  onClick={() => { handleActionsBot(/*bot*/) }}
                  title="Actions"
                />
              </div>

              <ModalBottom
                isOpen={isModalActionsOpen}
                toggleModal={toggleModalActions}
                content={
                  <>
                    {selectedKey ?
                      <>
                        <li className="w-full border-b border-solid bg-white dark:bg-[#5A616B]  outline-none dark:text-white hover:text-primary hover:bg-gray-2 dark:border-strokedark dark:hover:bg-primary cursor-pointer">
                          <span
                            className="flex w-full p-4 justify-center"
                            onClick={() => { toggleModalActions(); }}
                          >Edit key</span>
                        </li>
                        <li
                          className="w-full border-b border-solid bg-white dark:bg-[#5A616B]  outline-none dark:text-white hover:text-primary hover:bg-gray-2 dark:border-strokedark dark:hover:bg-primary cursor-pointer"
                          onClick={() => { toggleModalActions(); }}
                        >
                          <span className="flex w-full p-4 justify-center">to Favorites</span>
                        </li>
                        <li className="w-full border-b border-solid bg-white dark:bg-[#5A616B]  outline-none dark:text-white hover:text-primary hover:bg-gray-2 dark:border-strokedark dark:hover:bg-primary cursor-pointer">
                          <span
                            className="flex w-full p-4 justify-center"
                            onClick={() => { toggleModalActions(); }}
                          >Transfer balance</span>
                        </li>
                        <li className="w-full border-b border-solid bg-white dark:bg-[#5A616B]  outline-none dark:text-white hover:text-primary hover:bg-gray-2 dark:border-strokedark dark:hover:bg-primary cursor-pointer">
                          <span
                            className="flex w-full p-4 justify-center"
                            onClick={() => { toggleModalActions(); }}
                          >History Orders</span>
                        </li>
                        <li className="w-full border-b border-solid bg-white dark:bg-[#5A616B]  outline-none dark:text-white hover:text-primary hover:bg-gray-2 dark:border-strokedark dark:hover:bg-primary cursor-pointer">
                          <span
                            className="flex w-full p-4 justify-center"
                            onClick={() => { toggleModalActions(); }}
                          >Delete key</span>
                        </li>
                        <li className="w-full border-b border-solid bg-white dark:bg-[#5A616B]  outline-none dark:text-white hover:text-primary hover:bg-gray-2 dark:border-strokedark dark:hover:bg-primary cursor-pointer">
                          <span
                            className="flex w-full p-4 justify-center"
                            onClick={() => { toggleModalActions(); }}
                          >Manual trading</span>
                        </li>
                        <li
                          className="w-full border-b border-solid bg-white dark:bg-[#a93f3fa8]  outline-none dark:text-white hover:text-primary hover:bg-gray-2 dark:border-strokedark dark:hover:bg-primary cursor-pointer"
                          onClick={() => { toggleModalActions(); }}
                        >
                          <span className="flex w-full p-4 justify-center">Close all Orders/Positions</span>
                        </li>
                      </>
                      : null
                    }
                  </>
                }
                data={{
                  type: "key",
                  value: selectedKey,
                }}
              />
            </div>
          </div>
        </div>

        <div className="w-full md:w-md lg:w-150 px-5 py-5 bg-[#F9FAFB] dark:bg-meta-4 rounded-[10px] border border-dashed mb-6">
          <h3 className="text-base font-semibold leading-6 text-gray-900 mb-6">...</h3>
          <div className="grid grid-cols-1 gap-6">
          
          </div>
        </div>
      </div>
    </div>
  );
};

export default UiElements;