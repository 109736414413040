import React from 'react';
import { TasksProvider } from '../../context/TasksManagementContext';
import TasksContent from './TasksContent';

const TasksManagement: React.FC = () => {
  return (
    <TasksProvider>
      <TasksContent />
    </TasksProvider>
  );
};
  
export default TasksManagement;