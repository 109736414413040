import React, { useState, useEffect } from 'react';
import api from '../../../utils/api';
import { Toaster, toast } from 'sonner';
import { BsRobot, BsTrash, BsPencil, BsPlusCircleFill, BsXCircleFill } from "react-icons/bs";
import InputField from '../../../components/Forms/Inputs/InputField';
import { Bot, TASK } from '../../../types/task';
import BtnPrimary from '../../../components/Buttons/BtnPrimary';
import { ApiResponse } from '../../../types/api';

interface CommentProps {
  toggleModal: () => void,
  onSave?: (result: boolean) => void,
  id: string,
  commentDefault?: string,
  updateTasks?: () => void,
}

const Comment: React.FC<CommentProps> = ({ toggleModal, onSave, id, commentDefault, updateTasks }) => {
  const [comment, setComment] = useState(commentDefault || '');
  const [isEditing, setIsEditing] = useState(!commentDefault);
  
  console.log("comment", comment);
  console.log("id", id);

  useEffect(() => {
    setComment(commentDefault || '');
    setIsEditing(!commentDefault);
  }, [commentDefault]);

  const handleSaveComment = async () => {
    try {
      const updateData = {
        status: "Completed",
        comment: comment
      };

      await api.put<ApiResponse<string>>(`/benchmark-api/tasks/${id}`, updateData);

      toast.success('Comment updated successfully');
      onSave?.(true);
      updateTasks?.();
      toggleModal();
    } catch (error) {
      toast.error('Failed to update comment');
      onSave?.(false);
    }
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  return (
    <>
      <Toaster position="bottom-center" richColors />
      <form onSubmit={(e) => { e.preventDefault(); handleSaveComment(); }}>
        <div className="flex-grow mb-2">
          <div>
            <label className="mb-3 block text-black dark:text-white">
              Comment
            </label>
            <div className="relative">
              <textarea 
                rows={6}
                placeholder="Enter comment..." 
                className="w-full rounded-lg border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                // disabled={!isEditing}
              />
              {/* {!isEditing && (
                <button 
                  type="button" 
                  className="absolute top-2 right-2 text-blue-500" 
                  onClick={handleEditClick}
                >
                  <BsPencil />
                </button>
              )} */}
            </div>
          </div>
        </div>

        <div className='flex items-center justify-between gap-4 mt-auto'>
          <BtnPrimary className='w-full' type='submit' disabled={comment.length < 2}>
            <BsPlusCircleFill /> {!isEditing ? 'Save comment' : 'Add comment'}
          </BtnPrimary>
          <BtnPrimary
            className='w-full'
            style="outline"
            onClick={() => {
              toggleModal();
            }}
          >
            <BsXCircleFill /> Close
          </BtnPrimary>
        </div>
      </form>
    </>
  );
};

export default Comment;