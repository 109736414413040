import React, { useState, useEffect } from 'react';
import { Strategies } from '../../../data/strateges';
import { TimeFrame } from '../../../data/timeFrame';
import SimpleSelect from '../../../components/Forms/Select/SimpleSelect';
import InputField from '../../../components/Forms/Inputs/InputField';
import { toast } from 'sonner';
import { Bot } from '../../../types/task';
import Switcher from '../../../components/Switchers/Switcher';

interface SettingBotProps {
  toggleModal: () => void;
  setBots: (bots: (prevBots: Bot[]) => Bot[]) => void;
  bot?: Bot;
}

const SettingBot: React.FC<SettingBotProps> = ({ toggleModal, setBots, bot }) => {
  const [step, setStep] = useState(1);
  const [strategy, setStrategy] = useState(bot?.strategy || '');
  const [initFrom, setInitFrom] = useState<string>(bot?.init?.from.toString() || "0");
  const [initTo, setInitTo] = useState<string>(bot?.init?.to.toString() || "0");
  const [initStep, setInitStep] = useState<string>(bot?.init?.step.toString() || "0");
  const [initFromAVG, setInitFromAVG] = useState<string>(bot?.initAVG?.from.toString() || "0");
  const [initToAVG, setInitToAVG] = useState<string>(bot?.initAVG?.to.toString() || "0");
  const [initStepAVG, setInitStepAVG] = useState<string>(bot?.initAVG?.step.toString() || "0");
  const [countFromAVG, setCountFromAVG] = useState<string>(bot?.countAVG?.from.toString() || "0");
  const [countToAVG, setCountToAVG] = useState<string>(bot?.countAVG?.to.toString() || "0");
  const [countStepAVG, setCountStepAVG] = useState<string>(bot?.countAVG?.step.toString() || "0");
  const [firstProcentAVGFrom, setFirstProcentAVGFrom] = useState<string>(bot?.firstProcentAVG?.from.toString() || "0");
  const [firstProcentAVGTo, setFirstProcentAVGTo] = useState<string>(bot?.firstProcentAVG?.to.toString() || "0");
  const [firstProcentAVGStep, setFirstProcentAVGStep] = useState<string>(bot?.firstProcentAVG?.step.toString() || "0");
  const [multimetrStepFrom, setMultimetrStepFrom] = useState<string>(bot?.multimetrStep?.from.toString() || '0');
  const [multimetrStepTo, setMultimetrStepTo] = useState<string>(bot?.multimetrStep?.to.toString() || '0');
  const [multimetrStepStep, setMultimetrStepStep] = useState<string>(bot?.multimetrStep?.step.toString() || '0');
  const [multimetrVolumeFrom, setMultimetrVolumeFrom] = useState<string>(bot?.multimetrVolume?.from.toString() || '0');
  const [multimetrVolumeTo, setMultimetrVolumeTo] = useState<string>(bot?.multimetrVolume?.to.toString() || '0');
  const [multimetrVolumeStep, setMultimetrVolumeStep] = useState<string>(bot?.multimetrVolume?.step.toString() || '0');
  const [takeProfitFrom, setTakeProfitFrom] = useState<string>(bot?.takeProfit?.from.toString() || '0');
  const [takeProfitTo, setTakeProfitTo] = useState<string>(bot?.takeProfit?.to.toString() || '0');
  const [takeProfitStep, setTakeProfitStep] = useState<string>(bot?.takeProfit?.step.toString() || '0');
  const [lessRSIFrom, setLessRSIFrom] = useState<string>(bot?.lessRSI?.from.toString() || '0');
  const [lessRSITo, setLessRSITo] = useState<string>(bot?.lessRSI?.to.toString() || '0');
  const [lessRSIStep, setLessRSIStep] = useState<string>(bot?.lessRSI?.step.toString() || '0');
  const [aboveRSIFrom, setAboveRSIFrom] = useState<string>(bot?.aboveRSI?.from.toString() || '0');
  const [aboveRSITo, setAboveRSITo] = useState<string>(bot?.aboveRSI?.to.toString() || '0');
  const [aboveRSIStep, setAboveRSIStep] = useState<string>(bot?.aboveRSI?.step.toString() || '0');
  const [lengthRSI, setLengthRSI] = useState<number>(bot?.lengthRSI || 0);


  const [timeFrame, setTimeFrame] = useState(bot?.timeFrame || '');
  const [ruleOpen, setRuleOpen] = useState(''); /** bot?.ruleOpen || */
  const [ruleStop, setRuleStop] = useState(''); /** bot?.ruleStop || */
  const [openProcentMax, setOpenProcentMax] = useState<number | ''>(''); /** bot?.openProcentMax || */
  const [stopProcent, setStopProcent] = useState<number | ''>(''); /** bot?.stopProcent || */
  const [takeProfit, setTakeProfit] = useState<number | ''>('');  /** bot?.takeProfit || */
  const [stopLose, setStopLose] = useState<number | ''>(''); /** bot?.stopLose || */

  const [isInitProcent, setIsInitProcent] = useState<boolean>(bot?.isInitProcent || false);
  const [isInitAVGProcent, setIsInitAVGProcent] = useState<boolean>(bot?.isInitAVGProcent || false);
  const [isAVGPlusNext, setIsAVGPlusNext] = useState<boolean>(bot?.isAVGPlusNext || false);

  const handleStrategyChange = (value: string) => {
    setStrategy(value);
  };

  const handleTimeFrameChange = (value: string) => {
    setTimeFrame(value);
  };

  const handleNextStep = () => {
    if (!strategy) {
      toast.error('Please select a strategy');
      return;
    }
    setStep(2);
  };

  const handleBackStep = () => {
    setStep(1);
  };

  const handleInitFromChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setInitFrom(value);
  };

  const handleInitToChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setInitTo(value);
  };

  const handleInitStepChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setInitStep(value);
  };

  const handleRuleOpenChange = (value: string) => {
    setRuleOpen(value);
  };

  const handleRuleStopChange = (value: string) => {
    setRuleStop(value);
  };

  const handleProcentMaxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setOpenProcentMax(value === '' ? '' : parseInt(value, 10));
  };

  const handleStopProcentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setStopProcent(value === '' ? '' : parseInt(value, 10));
  };

  const handleTakeProfitChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setTakeProfit(value === '' ? '' : parseFloat(value));
  };

  const handleStopLoseChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setStopLose(value === '' ? '' : parseFloat(value));
  };

  const handleAddBot = () => {
    const newBot: Bot = {
      strategy,
      // initUsdt: Number(initUsdt),
      timeFrame,
      init: {
        from: Number(parseFloat(initFrom)),
        to: Number(parseFloat(initTo)),
        step: Number(parseFloat(initStep))
      },
      initAVG: {
        from: Number(parseFloat(initFromAVG)),
        to: Number(parseFloat(initToAVG)),
        step: Number(parseFloat(initStepAVG))
      },
      countAVG: {
        from: Number(parseFloat(countFromAVG)),
        to: Number(parseFloat(countToAVG)),
        step: Number(parseFloat(countStepAVG))
      },
      firstProcentAVG: {
        from: Number(parseFloat(firstProcentAVGFrom)),
        to: Number(parseFloat(firstProcentAVGTo)),
        step: Number(parseFloat(firstProcentAVGStep))
      },
      multimetrStep: {
        from: Number(parseFloat(multimetrStepFrom)),
        to: Number(parseFloat(multimetrStepTo)),
        step: Number(parseFloat(multimetrStepStep))
      },
      multimetrVolume: {
        from: Number(parseFloat(multimetrVolumeFrom)),
        to: Number(parseFloat(multimetrVolumeTo)),
        step: Number(parseFloat(multimetrVolumeStep))
      },
      takeProfit: {
        from: Number(parseFloat(takeProfitFrom)),
        to: Number(parseFloat(takeProfitTo)),
        step: Number(parseFloat(takeProfitStep))
      },
      lessRSI: {
        from: Number(parseFloat(lessRSIFrom)),
        to: Number(parseFloat(lessRSITo)),
        step: Number(parseFloat(lessRSIStep))
      },
      aboveRSI: {
        from: Number(parseFloat(aboveRSIFrom)),
        to: Number(parseFloat(aboveRSITo)),
        step: Number(parseFloat(aboveRSIStep))
      },
      isInitProcent: isInitProcent,
      isInitAVGProcent: isInitAVGProcent,
      isAVGPlusNext: isAVGPlusNext,
      lengthRSI: lengthRSI
    };

    console.log("newBot ", newBot)

    // if (strategy === 'Trend_Mashka') {
    //   newBot.ruleOpen = ruleOpen;
    //   newBot.ruleStop = ruleStop;
    //   newBot.openProcentMax = Number(openProcentMax);
    //   newBot.stopProcent = Number(stopProcent);
    // } else if (strategy === 'Wave_Squeez') {
    //   newBot.ruleOpen = ruleOpen;
    //   newBot.takeProfit = Number(takeProfit); 
    //   newBot.stopLose = Number(stopLose); 
    // }

    setBots((prevBots) => {
      if (bot) {
        const updatedBots = prevBots.map((b, index) => (b === bot ? newBot : b));
        return updatedBots;
      } else {
        return [...prevBots, newBot];
      }
    });
    toggleModal();
  };

  const handleIsInitProcent = () => {
    setIsInitProcent(!isInitProcent);
  };

  const handleIsInitAVGProcent = () => {
    setIsInitAVGProcent(!isInitAVGProcent);
  };

  const handleIsAVGPlusNext = () => {
    setIsAVGPlusNext(!isAVGPlusNext);
  };

  const handleInitFromAVGChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setInitFromAVG(value);
  };

  const handleInitToAVGChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setInitToAVG(value);
  };

  const handleInitStepAVGChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setInitStepAVG(value);
  };

  const handleCountFromAVGChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setCountFromAVG(value);
  };

  const handleCountToAVGChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setCountToAVG(value);
  };

  const handleCountStepAVGChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setCountStepAVG(value);
  };

  const handleFirstProcentAVGFromChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setFirstProcentAVGFrom(value);
  };

  const handleFirstProcentAVGToChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setFirstProcentAVGTo(value);
  };

  const handleFirstProcentAVGStepChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setFirstProcentAVGStep(value);
  };

  const handleMultimetrStepFromChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    // setMultimetrStepFrom(Number(value === '' ? '' : parseFloat(value.replace(",", "."))));
    // setMultimetrStepFrom(Number(value === '' ? '' : parseFloat(value)));
    setMultimetrStepFrom(value);
  };

  const handleMultimetrStepToChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setMultimetrStepTo(value);
  };

  const handleMultimetrStepStepChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setMultimetrStepStep(value);
  };

  const handleMultimetrVolumeFromChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setMultimetrVolumeFrom(value);
  };

  const handleMultimetrVolumeToChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setMultimetrVolumeTo(value);
  };

  const handleMultimetrVolumeStepChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setMultimetrVolumeStep(value);
  };

  const handleTakeProfitFromChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setTakeProfitFrom(value);
  };

  const handleTakeProfitToChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setTakeProfitTo(value);
  };

  const handleTakeProfitStepChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setTakeProfitStep(value);
  };

  const handleLessRSIFromChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setLessRSIFrom(value);
  };

  const handleLessRSIToChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setLessRSITo(value);
  };

  const handleLessRSIStepChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setLessRSIStep(value);
  };

  const handleAboveRSIFromChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setAboveRSIFrom(value);
  };

  const handleAboveRSIToChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setAboveRSITo(value);
  };

  const handleAboveRSIStepChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setAboveRSIStep(value);
  };

  const handleLengthRSIChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setLengthRSI(Number(value === '' ? '' : parseInt(value)));
  };

  return (
    <>
      <div className="text-start">
        <h3 className="mb-5 text-title-md2 font-semibold text-black dark:text-white">
          {bot ? 'Edit settings for bot' : 'Add settings for bot'}
        </h3>

        {step === 1 ? (
          <div>
            <div className='mb-4'>
              <SimpleSelect
                label="Choose strategy"
                options={Strategies}
                value={strategy}
                onChange={handleStrategyChange}
                className="w-full"
              />
            </div>

            <div className="max-h-[70vh] overflow-y-auto mb-4">
              {
                (strategy === "Syndicate" || strategy === "SyndicateV2") ?
                  <>
                    <div className="mb-4 flex gap-3 items-end justify-between">
                      <SimpleSelect
                        label="Time frame"
                        options={TimeFrame}
                        value={timeFrame}
                        onChange={handleTimeFrameChange}
                        className="w-full"
                      />

                      <InputField
                        label="Length RSI"
                        value={lengthRSI}
                        onChange={handleLengthRSIChange}
                        placeholder="Length RSI"
                        type="number"
                        min={0}
                      />
                    </div>

                    <div className="mb-4 flex gap-3 items-end justify-between">
                      <InputField
                        label={`Init min, ${isInitProcent ? "%" : "USDT"}`}
                        value={initFrom}
                        onChange={handleInitFromChange}
                        placeholder="Min"
                        type="number"
                        step="any"
                        max={initTo}
                      />

                      <InputField
                        label={`Init max, ${isInitProcent ? "%" : "USDT"}`}
                        value={initTo}
                        onChange={handleInitToChange}
                        placeholder="Max"
                        type="number"
                        step="any"
                        min={initFrom}
                      />

                      <div className='flex gap-3 items-center col-span-1 lg:col-span-2 mb-3'>
                        <Switcher
                          key="isInitProcent"
                          isActive={isInitProcent}
                          onChange={handleIsInitProcent}
                          id={`isInitProcent`}
                        />
                        <p className='text-md text-black dark:text-white font-medium'>{`${isInitProcent ? "%" : "USDT"}`}</p>
                      </div>

                      <InputField
                        label="Step"
                        value={initStep}
                        onChange={handleInitStepChange}
                        placeholder="Step"
                        type="number"
                        step="any"
                      />
                    </div>

                    <div className="mb-4 flex gap-3 items-end justify-between">
                      <InputField
                        label={`Init AVG, ${isInitAVGProcent ? "%" : "USDT"}`}
                        value={initFromAVG}
                        onChange={handleInitFromAVGChange}
                        placeholder="Min"
                        type="number"
                        step="any"
                        max={initToAVG}
                      />

                      <InputField
                        label={`Init AVG, ${isInitAVGProcent ? "%" : "USDT"}`}
                        value={initToAVG}
                        onChange={handleInitToAVGChange}
                        placeholder="Max"
                        type="number"
                        step="any"
                        min={initFromAVG}
                      />

                      <div className='flex gap-3 items-center col-span-1 lg:col-span-2 mb-3'>
                        <Switcher
                          title="Step Procent Plus Last AVG"
                          key="isInitAVGProcent"
                          isActive={isInitAVGProcent}
                          onChange={handleIsInitAVGProcent}
                          id={`isInitAVGProcent`}
                        />
                        <p className='text-md text-black dark:text-white font-medium'>{`${isInitAVGProcent ? "%" : "USDT"}`}</p>
                      </div>

                      <InputField
                        label="Step"
                        value={initStepAVG}
                        onChange={handleInitStepAVGChange}
                        placeholder="Step"
                        type="number"
                        step="any"
                      />
                    </div>

                    <div className="mb-4 flex gap-3 items-end justify-between">
                      <InputField
                        label="Count AVG, min"
                        value={countFromAVG}
                        onChange={handleCountFromAVGChange}
                        placeholder="Min"
                        type="number"
                        step="any"
                        max={countToAVG}
                      />

                      <InputField
                        label="Count AVG, max"
                        value={countToAVG}
                        onChange={handleCountToAVGChange}
                        placeholder="Max"
                        type="number"
                        step="any"
                        min={countFromAVG}
                      />

                      <InputField
                        label="Step"
                        value={countStepAVG}
                        onChange={handleCountStepAVGChange}
                        placeholder="Step"
                        type="number"
                        step="any"
                      />
                    </div>
                    <div className="mb-4 flex gap-3 items-end justify-between">
                      <p>Step Procent Plus Last AVG</p>
                      <Switcher

                        key="isAVGPlusNext"
                        isActive={isAVGPlusNext}
                        onChange={handleIsAVGPlusNext}
                        id={`isAVGPlusNext`}
                      />
                    </div>

                    <div className="mb-4 flex gap-3 items-end justify-between">
                      <InputField
                        label="First Procent AVG, min"
                        value={firstProcentAVGFrom}
                        onChange={handleFirstProcentAVGFromChange}
                        placeholder="Min"
                        type="number"
                        max={firstProcentAVGTo}
                      />

                      <InputField
                        label="First Procent AVG, max"
                        value={firstProcentAVGTo}
                        onChange={handleFirstProcentAVGToChange}
                        placeholder="Max"
                        type="number"
                        min={firstProcentAVGFrom}
                      />

                      <InputField
                        label="Step"
                        value={firstProcentAVGStep}
                        onChange={handleFirstProcentAVGStepChange}
                        placeholder="Step"
                        type="number"
                      />
                    </div>

                    <div className="mb-4 flex gap-3 items-end justify-between">
                      <InputField
                        label="Multimetr Step, min"
                        value={multimetrStepFrom}
                        onChange={handleMultimetrStepFromChange}
                        placeholder="Min"
                        type="number"
                        step="any"
                        max={multimetrStepTo}
                      />

                      <InputField
                        label="Multimetr Step, max"
                        value={multimetrStepTo}
                        onChange={handleMultimetrStepToChange}
                        placeholder="Max"
                        type="number"
                        step="any"
                        min={multimetrStepFrom}
                      />

                      <InputField
                        label="Step"
                        value={multimetrStepStep}
                        onChange={handleMultimetrStepStepChange}
                        placeholder="Step"
                        type="number"
                        step="any"
                      />
                    </div>

                    <div className="mb-4 flex gap-3 items-end justify-between">
                      <InputField
                        label="Multimetr Volume, min"
                        value={multimetrVolumeFrom}
                        onChange={handleMultimetrVolumeFromChange}
                        placeholder="Min"
                        type="number"
                        step="any"
                        max={multimetrVolumeTo}
                      />

                      <InputField
                        label="Multimetr Volume, max"
                        value={multimetrVolumeTo}
                        onChange={handleMultimetrVolumeToChange}
                        placeholder="Max"
                        type="number"
                        step="any"
                        min={multimetrVolumeFrom}
                      />

                      <InputField
                        label="Step"
                        value={multimetrVolumeStep}
                        onChange={handleMultimetrVolumeStepChange}
                        placeholder="Step"
                        type="number"
                        step="any"
                      />
                    </div>

                    <div className="mb-4 flex gap-3 items-end justify-between">
                      <InputField
                        label="Take Profit, min"
                        value={takeProfitFrom}
                        onChange={handleTakeProfitFromChange}
                        placeholder="Min"
                        type="number"
                        step="any"
                        max={takeProfitTo}
                      />

                      <InputField
                        label="Take Profit, max"
                        value={takeProfitTo}
                        onChange={handleTakeProfitToChange}
                        placeholder="Max"
                        type="number"
                        step="any"
                        min={takeProfitFrom}
                      />

                      <InputField
                        label="Step"
                        value={takeProfitStep}
                        onChange={handleTakeProfitStepChange}
                        placeholder="Step"
                        type="number"
                        step="any"
                      />
                    </div>

                    <div className="mb-4 flex gap-3 items-end justify-between">
                      <InputField
                        label="Less RSI, min"
                        value={lessRSIFrom}
                        onChange={handleLessRSIFromChange}
                        placeholder="Min"
                        type="number"
                        step="any"
                        max={lessRSITo}
                      />

                      <InputField
                        label="Less RSI, max"
                        value={lessRSITo}
                        onChange={handleLessRSIToChange}
                        placeholder="Max"
                        type="number"
                        step="any"
                        min={lessRSIFrom}
                      />

                      <InputField
                        label="Step"
                        value={lessRSIStep}
                        onChange={handleLessRSIStepChange}
                        placeholder="Step"
                        type="number"
                        step="any"
                      />
                    </div>

                    <div className="mb-4 flex gap-3 items-end justify-between">
                      <InputField
                        label="Above RSI, min"
                        value={aboveRSIFrom}
                        onChange={handleAboveRSIFromChange}
                        placeholder="Min"
                        type="number"
                        step="any"
                        max={aboveRSITo}
                      />

                      <InputField
                        label="Above RSI, max"
                        value={aboveRSITo}
                        onChange={handleAboveRSIToChange}
                        placeholder="Max"
                        type="number"
                        step="any"
                        min={aboveRSIFrom}
                      />

                      <InputField
                        label="Step"
                        value={aboveRSIStep}
                        onChange={handleAboveRSIStepChange}
                        placeholder="Step"
                        type="number"
                        step="any"
                      />
                    </div>
                  </>
                  : null
              }


              {
                strategy === "Kamikaze" ?
                  <>

                    <div className="mb-4 flex gap-3 items-end justify-between">
                      <SimpleSelect
                        label="Time frame"
                        options={TimeFrame}
                        value={timeFrame}
                        onChange={handleTimeFrameChange}
                        className="w-full"
                      />

                      <InputField
                        label="Length RSI"
                        value={lengthRSI}
                        onChange={handleLengthRSIChange}
                        placeholder="Length RSI"
                        type="number"
                        min={0}
                      />
                    </div>

                    <div className="mb-4 flex gap-3 items-end justify-between">
                      <InputField
                        label={`Init, ${isInitProcent ? "%" : "USDT"}`}
                        value={initFrom}
                        onChange={handleInitFromChange}
                        placeholder="From"
                        type="number"
                        max={initTo}
                      />

                      <InputField
                        label={`Init, ${isInitProcent ? "%" : "USDT"}`}
                        value={initTo}
                        onChange={handleInitToChange}
                        placeholder="To"
                        type="number"
                        min={initFrom}
                      />

                      <div className='flex gap-3 items-center col-span-1 lg:col-span-2 mb-3'>
                        <Switcher
                          key="isInitProcent"
                          isActive={isInitProcent}
                          onChange={handleIsInitProcent}
                          id={`isInitProcent`}
                        />
                        <p className='text-md text-black dark:text-white font-medium'>{`${isInitProcent ? "%" : "USDT"}`}</p>
                      </div>

                      <InputField
                        label="Step"
                        value={initStep}
                        onChange={handleInitStepChange}
                        placeholder="Step"
                        type="number"
                      />
                    </div>


                    <div className="mb-4 flex gap-3 items-end justify-between">
                      <InputField
                        label="Multimetr Volume"
                        value={multimetrVolumeFrom}
                        onChange={handleMultimetrVolumeFromChange}
                        placeholder="From"
                        type="number"
                        max={multimetrVolumeTo}
                      />

                      <InputField
                        label="Multimetr Volume"
                        value={multimetrVolumeTo}
                        onChange={handleMultimetrVolumeToChange}
                        placeholder="To"
                        type="number"
                        min={multimetrVolumeFrom}
                      />

                      <InputField
                        label="Step"
                        value={multimetrVolumeStep}
                        onChange={handleMultimetrVolumeStepChange}
                        placeholder="Step"
                        type="number"
                      />
                    </div>

                    <div className="mb-4 flex gap-3 items-end justify-between">
                      <InputField
                        label="Take Profit"
                        value={takeProfitFrom}
                        onChange={handleTakeProfitFromChange}
                        placeholder="From"
                        type="number"
                        max={takeProfitTo}
                      />

                      <InputField
                        label="Take Profit"
                        value={takeProfitTo}
                        onChange={handleTakeProfitToChange}
                        placeholder="To"
                        type="number"
                        min={takeProfitFrom}
                      />

                      <InputField
                        label="Step"
                        value={takeProfitStep}
                        onChange={handleTakeProfitStepChange}
                        placeholder="Step"
                        type="number"
                      />
                    </div>

                  </>
                  : null
              }

              {
                strategy === "Kamikaze2" ?
                  <>

                    <div className="mb-4 flex gap-3 items-end justify-between">
                      <SimpleSelect
                        label="Time frame"
                        options={TimeFrame}
                        value={timeFrame}
                        onChange={handleTimeFrameChange}
                        className="w-full"
                      />

                      <InputField
                        label="Length RSI"
                        value={lengthRSI}
                        onChange={handleLengthRSIChange}
                        placeholder="Length RSI"
                        type="number"
                        min={0}
                      />
                    </div>

                    <div className="mb-4 flex gap-3 items-end justify-between">
                      <InputField
                        label={`Init, ${isInitProcent ? "%" : "USDT"}`}
                        value={initFrom}
                        onChange={handleInitFromChange}
                        placeholder="From"
                        type="number"
                        max={initTo}
                      />

                      <InputField
                        label={`Init, ${isInitProcent ? "%" : "USDT"}`}
                        value={initTo}
                        onChange={handleInitToChange}
                        placeholder="To"
                        type="number"
                        min={initFrom}
                      />

                      <div className='flex gap-3 items-center col-span-1 lg:col-span-2 mb-3'>
                        <Switcher
                          key="isInitProcent"
                          isActive={isInitProcent}
                          onChange={handleIsInitProcent}
                          id={`isInitProcent`}
                        />
                        <p className='text-md text-black dark:text-white font-medium'>{`${isInitProcent ? "%" : "USDT"}`}</p>
                      </div>

                      <InputField
                        label="Step"
                        value={initStep}
                        onChange={handleInitStepChange}
                        placeholder="Step"
                        type="number"
                      />
                    </div>

                    <div className="mb-4 flex gap-3 items-end justify-between">
                      <InputField
                        label="Count AVG, min"
                        value={countFromAVG}
                        onChange={handleCountFromAVGChange}
                        placeholder="Min"
                        type="number"
                        max={countToAVG}
                      />

                      <InputField
                        label="Count AVG, max"
                        value={countToAVG}
                        onChange={handleCountToAVGChange}
                        placeholder="Max"
                        type="number"
                        min={countFromAVG}
                      />

                      <InputField
                        label="Step"
                        value={countStepAVG}
                        onChange={handleCountStepAVGChange}
                        placeholder="Step"
                        type="number"
                      />
                    </div>

                    <div className="mb-4 flex gap-3 items-end justify-between">
                      <InputField
                        label="First Procent AVG"
                        value={firstProcentAVGFrom}
                        onChange={handleFirstProcentAVGFromChange}
                        placeholder="To"
                        type="number"
                        max={firstProcentAVGTo}
                      />

                      <InputField
                        label="First Procent AVG"
                        value={firstProcentAVGTo}
                        onChange={handleFirstProcentAVGToChange}
                        placeholder="From"
                        type="number"
                        min={firstProcentAVGFrom}
                      />

                      <InputField
                        label="Step"
                        value={firstProcentAVGStep}
                        onChange={handleFirstProcentAVGStepChange}
                        placeholder="Step"
                        type="number"
                      />
                    </div>

                    <div className="mb-4 flex gap-3 items-end justify-between">
                      <InputField
                        label="Multimetr Step"
                        value={multimetrStepFrom}
                        onChange={handleMultimetrStepFromChange}
                        placeholder="From"
                        type="number"
                        max={multimetrStepTo}
                      />

                      <InputField
                        label="Multimetr Step"
                        value={multimetrStepTo}
                        onChange={handleMultimetrStepToChange}
                        placeholder="To"
                        type="number"
                        min={multimetrStepFrom}
                      />

                      <InputField
                        label="Step"
                        value={multimetrStepStep}
                        onChange={handleMultimetrStepStepChange}
                        placeholder="Step"
                        type="number"
                      />
                    </div>

                    <div className="mb-4 flex gap-3 items-end justify-between">
                      <InputField
                        label="Multimetr Volume"
                        value={multimetrVolumeFrom}
                        onChange={handleMultimetrVolumeFromChange}
                        placeholder="From"
                        type="number"
                        max={multimetrVolumeTo}
                      />

                      <InputField
                        label="Multimetr Volume"
                        value={multimetrVolumeTo}
                        onChange={handleMultimetrVolumeToChange}
                        placeholder="To"
                        type="number"
                        min={multimetrVolumeFrom}
                      />

                      <InputField
                        label="Step"
                        value={multimetrVolumeStep}
                        onChange={handleMultimetrVolumeStepChange}
                        placeholder="Step"
                        type="number"
                      />
                    </div>

                    <div className="mb-4 flex gap-3 items-end justify-between">
                      <InputField
                        label="Take Profit"
                        value={takeProfitFrom}
                        onChange={handleTakeProfitFromChange}
                        placeholder="From"
                        type="number"
                        max={takeProfitTo}
                      />

                      <InputField
                        label="Take Profit"
                        value={takeProfitTo}
                        onChange={handleTakeProfitToChange}
                        placeholder="To"
                        type="number"
                        min={takeProfitFrom}
                      />

                      <InputField
                        label="Step"
                        value={takeProfitStep}
                        onChange={handleTakeProfitStepChange}
                        placeholder="Step"
                        type="number"
                      />
                    </div>

                  </>
                  : null
              }

              {
                strategy === "LimitMan" ?
                  <>
                    <div className="mb-4 flex gap-3 items-end justify-between">
                      <InputField
                        label="Init min, USDT"
                        value={initFrom}
                        onChange={handleInitFromChange}
                        placeholder="Min"
                        type="number"
                        step="any"
                        max={initTo}
                      />

                      <InputField
                        label="Init max, USDT"
                        value={initTo}
                        onChange={handleInitToChange}
                        placeholder="Max"
                        type="number"
                        step="any"
                        min={initFrom}
                      />

                      <InputField
                        label="Step"
                        value={initStep}
                        onChange={handleInitStepChange}
                        placeholder="Step"
                        type="number"
                        step="any"
                      />
                    </div>

                    <div className="mb-4 flex gap-3 items-end justify-between">
                      <InputField
                        label="Multimetr Step, min"
                        value={multimetrStepFrom}
                        onChange={handleMultimetrStepFromChange}
                        placeholder="Min"
                        type="number"
                        step="any"
                        max={multimetrStepTo}
                      />

                      <InputField
                        label="Multimetr Step, max"
                        value={multimetrStepTo}
                        onChange={handleMultimetrStepToChange}
                        placeholder="Max"
                        type="number"
                        step="any"
                        min={multimetrStepFrom}
                      />

                      <InputField
                        label="Step"
                        value={multimetrStepStep}
                        onChange={handleMultimetrStepStepChange}
                        placeholder="Step"
                        type="number"
                        step="any"
                      />
                    </div>

                    <div className="mb-4 flex gap-3 items-end justify-between">
                      <InputField
                        label="Take Profit, min"
                        value={takeProfitFrom}
                        onChange={handleTakeProfitFromChange}
                        placeholder="Min"
                        type="number"
                        step="any"
                        max={takeProfitTo}
                      />

                      <InputField
                        label="Take Profit, max"
                        value={takeProfitTo}
                        onChange={handleTakeProfitToChange}
                        placeholder="Max"
                        type="number"
                        step="any"
                        min={takeProfitFrom}
                      />

                      <InputField
                        label="Step"
                        value={takeProfitStep}
                        onChange={handleTakeProfitStepChange}
                        placeholder="Step"
                        type="number"
                        step="any"
                      />
                    </div>
                  </>
                  : null
              }

              {
                strategy === "HighLow" ?
                  <>
                    <div className="mb-4 flex gap-3 items-end justify-between">
                      <SimpleSelect
                        label="Time frame"
                        options={TimeFrame}
                        value={timeFrame}
                        onChange={handleTimeFrameChange}
                        className="w-full"
                      />

                      <InputField
                        label="Length RSI"
                        value={lengthRSI}
                        onChange={handleLengthRSIChange}
                        placeholder="Length RSI"
                        type="number"
                        min={0}
                      />
                    </div>

                    <div className="mb-4 flex gap-3 items-end justify-between">
                      <InputField
                        label={`Init min, ${isInitProcent ? "%" : "USDT"}`}
                        value={initFrom}
                        onChange={handleInitFromChange}
                        placeholder="Min"
                        type="number"
                        step="any"
                        max={initTo}
                      />

                      <InputField
                        label={`Init max, ${isInitProcent ? "%" : "USDT"}`}
                        value={initTo}
                        onChange={handleInitToChange}
                        placeholder="Max"
                        type="number"
                        step="any"
                        min={initFrom}
                      />

                      <div className='flex gap-3 items-center col-span-1 lg:col-span-2 mb-3'>
                        <Switcher
                          key="isInitProcent"
                          isActive={isInitProcent}
                          onChange={handleIsInitProcent}
                          id={`isInitProcent`}
                        />
                        <p className='text-md text-black dark:text-white font-medium'>{`${isInitProcent ? "%" : "USDT"}`}</p>
                      </div>

                      <InputField
                        label="Step"
                        value={initStep}
                        onChange={handleInitStepChange}
                        placeholder="Step"
                        type="number"
                        step="any"
                      />
                    </div>

                    <div className="mb-4 flex gap-3 items-end justify-between">
                      <InputField
                        label="Multimetr Volume"
                        value={multimetrVolumeFrom}
                        onChange={handleMultimetrVolumeFromChange}
                        placeholder="From"
                        type="number"
                        max={multimetrVolumeTo}
                      />

                      <InputField
                        label="Multimetr Volume"
                        value={multimetrVolumeTo}
                        onChange={handleMultimetrVolumeToChange}
                        placeholder="To"
                        type="number"
                        min={multimetrVolumeFrom}
                      />

                      <InputField
                        label="Step"
                        value={multimetrVolumeStep}
                        onChange={handleMultimetrVolumeStepChange}
                        placeholder="Step"
                        type="number"
                      />
                    </div>

                    <div className="mb-4 flex gap-3 items-end justify-between">
                      <InputField
                        label="Take Profit, min"
                        value={takeProfitFrom}
                        onChange={handleTakeProfitFromChange}
                        placeholder="Min"
                        type="number"
                        step="any"
                        max={takeProfitTo}
                      />

                      <InputField
                        label="Take Profit, max"
                        value={takeProfitTo}
                        onChange={handleTakeProfitToChange}
                        placeholder="Max"
                        type="number"
                        step="any"
                        min={takeProfitFrom}
                      />

                      <InputField
                        label="Step"
                        value={takeProfitStep}
                        onChange={handleTakeProfitStepChange}
                        placeholder="Step"
                        type="number"
                        step="any"
                      />
                    </div>

                    <div className="mb-4 flex gap-3 items-end justify-between">
                      <InputField
                        label="Less RSI, min"
                        value={lessRSIFrom}
                        onChange={handleLessRSIFromChange}
                        placeholder="Min"
                        type="number"
                        step="any"
                        max={lessRSITo}
                      />

                      <InputField
                        label="Less RSI, max"
                        value={lessRSITo}
                        onChange={handleLessRSIToChange}
                        placeholder="Max"
                        type="number"
                        step="any"
                        min={lessRSIFrom}
                      />

                      <InputField
                        label="Step"
                        value={lessRSIStep}
                        onChange={handleLessRSIStepChange}
                        placeholder="Step"
                        type="number"
                        step="any"
                      />
                    </div>

                    <div className="mb-4 flex gap-3 items-end justify-between">
                      <InputField
                        label="Above RSI, min"
                        value={aboveRSIFrom}
                        onChange={handleAboveRSIFromChange}
                        placeholder="Min"
                        type="number"
                        step="any"
                        max={aboveRSITo}
                      />

                      <InputField
                        label="Above RSI, max"
                        value={aboveRSITo}
                        onChange={handleAboveRSIToChange}
                        placeholder="Max"
                        type="number"
                        step="any"
                        min={aboveRSIFrom}
                      />

                      <InputField
                        label="Step"
                        value={aboveRSIStep}
                        onChange={handleAboveRSIStepChange}
                        placeholder="Step"
                        type="number"
                        step="any"
                      />
                    </div>
                  </>
                  : null
              }

            </div>

            <div className='w-full flex items-center justify-center'>
              <button
                onClick={handleNextStep}
                type="button"
                className='w-full inline-flex items-center justify-center rounded-md bg-primary py-4 px-10 text-center font-medium text-white hover:bg-opacity-90 lg:px-8 xl:px-10'
              >
                Next
              </button>
            </div>
          </div>
        ) : (
          <div>
            <h4 className="mb-4 text-lg font-normal text-gray-500 dark:text-gray-400">
              Settings for {strategy}
            </h4>

            {/* {strategy === 'Trend_Mashka' && (
              <>
                <div className="mb-4 flex gap-3 items-end justify-between">
                  <SimpleSelect
                    label="Rule open"
                    options={[{ value: "EMA_4Bars", text: "EMA_4Bars" }]}
                    value={ruleOpen}
                    onChange={handleRuleOpenChange}
                    className="w-full"
                  />

                  <SimpleSelect
                    label="Rule stop"
                    options={[{ value: "EMA_Procent", text: "EMA_Procent" }]}
                    value={ruleStop}
                    onChange={handleRuleStopChange}
                    className="w-full"
                  />
                </div>

                <div className="mb-4 flex gap-3 items-center justify-between">
                  <InputField
                    label="Open procent max"
                    value={openProcentMax}
                    onChange={handleProcentMaxChange}
                    placeholder="50%"
                    type="number"
                  />

                  <InputField
                    label="Stop procent"
                    value={stopProcent}
                    onChange={handleStopProcentChange}
                    placeholder="85%"
                    type="number"
                  />
                </div>
              </>
            )}

            {strategy === 'Wave_Squeez' && (
              <>
                <div className="mb-4 flex gap-3 items-end justify-between">
                  <SimpleSelect
                    label="Rule open"
                    options={[{ value: "ChangeWay", text: "ChangeWay" }]}
                    value={ruleOpen}
                    onChange={handleRuleOpenChange}
                    className="w-full"
                  />
                </div>

                <div className="mb-4 flex gap-3 items-center justify-between">
                  <InputField
                    label="Take profit"
                    value={takeProfit}
                    onChange={handleTakeProfitChange}
                    placeholder="%"
                    type="number"
                  />

                  <InputField
                    label="Stop lose"
                    value={stopLose}
                    onChange={handleStopLoseChange}
                    placeholder="%"
                    type="number"
                  />
                </div>
              </>
            )} */}

            <div className='w-full flex items-center justify-center gap-4'>
              <button
                onClick={handleBackStep}
                type="button"
                className='w-full inline-flex items-center justify-center rounded-md border border-primary py-4 px-10 text-center font-medium text-primary hover:bg-opacity-90 lg:px-8 xl:px-10'
              >
                Back
              </button>
              <button
                onClick={handleAddBot}
                type="button"
                className='w-full inline-flex items-center justify-center rounded-md bg-primary py-4 px-10 text-center font-medium text-white hover:bg-opacity-90 lg:px-8 xl:px-10'
              >
                {bot ? 'Save changes' : 'Add bot'}
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default SettingBot;