import { error } from "console";

export const messages = {
  user: {
    edited: {
      success: "User updated successfully",
      failing: "Failed to edit user",
    },
    fetch: {
      failed: "Failed to fetch users",
      error: "An error occurred while fetching users",
    },
    create: {
      failed: "Failed to create user",
      error: "An error occurred while creating user",
    },
    update: {
      failed: "Failed to update user",
      error: "An error occurred while updating user",
    },
    delete: {
      failed: "Failed to delete user",
      error: "An error occurred while deleting user",
      successDeteted: "User deleted successfully",
      failedDeleted: "Failed to delete user",
    }
  },
  form: {
    fillFields: "Please fill fields form",
  },
  symbols: {
    fetch: "Error fetching symbols:"
  },
  bots: {
    loading: {
      error: "Error loading bots",
      api: "Error loading api bots",
    },
  },
  analytics: {
    loading: {
      error: "Error loading data analytics",
    },
  },
  account: {
    create: {
      success: "Account added successfully",
      failed: "Failed to add account",
    },
  },
  keyPublic: {
    success: "Public key copied to clipboard",
    failed: "Failed to copy public key: ",
  },
  transfers: {
    success: "Transfered successfully",
    failed: "Failed",
    maxAmount: "Sorry, but the Amount field cannot exceed the available balance.",
  },
  task: {
    delete: {
      success: "Task deleted successfully",
      failed: "Failed to delete task",
      noSelectedTaskError: "Sorry, but it is impossible to delete the task"
    }
  }
};