import React, { useContext, useState, useEffect } from 'react';
import { useBotsContext } from '../../../context/BotsAllContext';
import SimpleSelect from '../../../components/Forms/Select/SimpleSelect';

interface CountPaginationProps { }

const paginationTypes = [
  {
    value: "10",
    text: "10",
  },
  {
    value: "25",
    text: "25",
  },
  {
    value: "50",
    text: "50",
  },
  {
    value: "100",
    text: "100",
  },
];

const CountPagination: React.FC<CountPaginationProps> = ({ }) => {
  const {
      pageSize,
      totalCount,
      currentPage,
      setPageSize,
      getBotsAll
  } = useBotsContext();
  const [counter, setCounter] = useState(pageSize.toString());

  const handleCounterChange = (value: string) => {
    const newPageSize = parseInt(value, 10);
    setCounter(value);
    setPageSize(newPageSize);
    getBotsAll(currentPage, newPageSize, {});
    // console.log(`counter = ${counter}`);
  };

  // console.log(`Переменная pageSize/totalCount/pageCount = ${pageSize}/${totalCount}/`)

  useEffect(() => {
    setCounter(pageSize.toString());
  }, [pageSize]);

  // console.log(`Переменные
  //     pageSize = ${pageSize},
  //     totalCount = ${totalCount},
  //     currentPage = ${currentPage}` )
  return (
    <SimpleSelect
      options={paginationTypes}
      value={counter}
      onChange={handleCounterChange}
      className='w-[5rem]'
    />
  );
};

export default CountPagination;