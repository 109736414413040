import React, { useState, useEffect } from 'react';
import InputField from '../../../../components/Forms/Inputs/InputField';
import Switcher from "../../../../components/Switchers/Switcher";

interface StrategyProps {
  switcherNeedOpenExtra: boolean,
  setSwitcherNeedOpenExtra: (boolean: boolean) => void,
  handleNeedOpenExtra: () => void,
  maxCountAVG: number | '',
  handleMaxCountAVGChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  averageStep: number | '',
  handleAverageStepChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const AverageTwoMashka: React.FC<StrategyProps> = ({
  switcherNeedOpenExtra,
  setSwitcherNeedOpenExtra,
  handleNeedOpenExtra,
  handleMaxCountAVGChange,
  maxCountAVG,
  handleAverageStepChange,
  averageStep
}) => {


  return (
    <div className='rounded-lg border border-stroke bg-white py-6 px-7.5 shadow-default dark:border-strokedark dark:bg-boxdark mt-4'>
      <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mb-4 items-end'>
        <InputField
          label="MaxCountAVG"
          value={maxCountAVG}
          onChange={handleMaxCountAVGChange}
          placeholder="MaxCountAVG"
          type="number"
          className="w-full"
        />
        <InputField
          label="AverageStep"
          value={averageStep}
          onChange={handleAverageStepChange}
          placeholder="AverageStep"
          type="number"
          className="w-full"
        />
        {/* <div className='flex gap-3 items-center'>
          <Switcher
            isActive={switcherNeedOpenExtra}
            onChange={handleNeedOpenExtra}
            id="need-open-extra"
          />
          <p className='text-md text-black dark:text-white font-medium'>NeedOpenExtra</p>
        </div> */}
      </div>
    </div>
  )
}

export default AverageTwoMashka;