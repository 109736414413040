import React from 'react';
import { TransfersHistoryProvider } from '../../context/TransfersHistoryContext';
import TransfersHistoryContent from './TransfersHistoryContent';

const ApiKeys: React.FC = () => {
  return (
    <TransfersHistoryProvider>
      <TransfersHistoryContent />
    </TransfersHistoryProvider>
  );
};

export default ApiKeys;