import React, { useState, useEffect, useMemo } from 'react';
import { AccountsManagersProps } from './interface';
import { useAccountsManagementContext } from '../../context/AccountsManagementContext';
import NotFound from '../../common/NotFound';
import { ManagementAccounts } from './interface';
import { SiMainwp } from "react-icons/si";
import { formatCurrency } from '../../utils/formatCurrency';
import { BsThreeDotsVertical, BsChevronDown, BsChevronUp } from "react-icons/bs";
import ModalBottom from '../../components/Modals/ModalBottom';
import ModalCenter from '../../components/Modals/ModalCenter';
import ContentModalSend from "./ContentModalSend";
import ContentModalAddTask from './ContentModalAddTask';

const TableAccounts: React.FC<AccountsManagersProps> = ({
  // totalCount,
  // onPageChange,
  // currentPage,
  accounts,
  selectedAccount,
  fetchAccounts,
  handleActionsAccount,
  isModalActionsOpen,
  toggleModalActions,
  handleTransferOpen,
  isModalTransfer,
  toggleModalTransfer,
  handleTransfer,
  handleAddTaskOpen,
  isModalAddTask,
  toggleModalAddTask,
  handleAddTask,
}) => {

  const {
    // pageSize,
  } = useAccountsManagementContext();

  // const [accs, setAccs] = useState<ManagementAccounts[]>(accounts);
  const [expandedRows, setExpandedRows] = useState<string[]>([]);

  useEffect(() => {
    // Обновляем таблицу при изменении данных
    // fetchAccounts();
    // console.log("accounts = ", accounts);
  }, []);

  const toggleRowExpansion = (id: string) => {
    setExpandedRows(prevExpandedRows =>
      prevExpandedRows.includes(id)
        ? prevExpandedRows.filter(rowId => rowId !== id)
        : [...prevExpandedRows, id]
    );
  };

  return (
    <div className="overflow-hidden rounded-[10px] mb-4">
      <div className="max-w-full overflow-x-auto">
        <div className="w-full sm:min-w-[1170px]">

          <div className="hidden sm:grid grid-cols-12 gap-2 bg-[#F9FAFB] px-1 py-5 dark:bg-meta-4 lg:px-7.5 2xl:px-11">
            <div className="col-span-1 flex w-[40px] grow-0 shrink justify-start">
              <h5 className="font-medium text-[#637381] dark:text-bodydark uppercase">#</h5>
            </div>
            <div className="col-span-4 flex grow justify-start">
              <h5 className="font-medium text-[#637381] dark:text-bodydark uppercase">Name</h5>
            </div>
            {/* <div className="col-span-2 flex justify-center">
              <h5 className="font-medium text-[#637381] dark:text-bodydark uppercase">Permissions</h5>
            </div> */}
            <div className="col-span-3 flex justify-center">
              <h5 className="font-medium text-[#637381] dark:text-bodydark uppercase">Label</h5>
            </div>
            <div className="col-span-3 flex justify-center">
              <h5 className="font-medium text-[#637381] dark:text-bodydark uppercase">Balance</h5>
            </div>
            {/* <div className="col-span-2 flex justify-center">
              <h5 className="font-medium text-[#637381] dark:text-bodydark uppercase">Info</h5>
            </div> */}
            <div className="col-span-1 flex justify-end items-center">
              <h5 className="font-medium text-[#637381] dark:text-bodydark uppercase">Actions</h5>
            </div>
          </div>

          <div className="bg-white dark:bg-boxdark">
            {
              accounts == null || accounts.length === 0 ?
                (
                  <NotFound />
                )
                : (
                  accounts.map((acc, index) => {
                    const isExpanded = expandedRows.includes(acc.id);

                    return (
                      <div key={"acc_row-" + index}  className="border-t border-[#EEEEEE] px-5 py-4 dark:border-strokedark lg:px-7.5 2xl:px-11">
                        <div className="grid grid-cols-12 grid-rows-auto gap-2">
                          <div className="col-span-1 shrink flex justify-start">
                            {isExpanded ? (
                              <BsChevronUp
                                className="shrink-0 cursor-pointer duration-300 ease-in-out hover:text-primary text-xl"
                                onClick={() => toggleRowExpansion(acc.id)}
                                title="Collapse line"
                              />
                            ) : (
                              <BsChevronDown
                                className="shrink-0 cursor-pointer duration-300 ease-in-out hover:text-primary text-xl"
                                onClick={() => toggleRowExpansion(acc.id)}
                                title="Expand line"
                              />
                            )}
                          </div>

                          <div className="col-span-4 flex grow flex-col items-start gap-3">
                            <h5 className="flex flex-row items-center gap-2 font-semibold text-[#637381] dark:text-bodydark mb-4">
                                <span>{index + 1} </span>
                                {acc.isMain ? <SiMainwp /> : null}
                                <span>{acc.name}</span>
                            </h5>
                            {/* <div className="flex flex-col items-start gap-2 ">
                              <div>UID: {acc.account.uid}</div>
                              <div>Label: {acc.account.label}</div>
                              {
                                !acc.isMain ?
                                  <>
                                    <div>SubName: {acc.account.name ? acc.account.name : "-"}</div>
                                    <div>MainUID: {acc.account.mainUID}</div>
                                  </>
                                  : null
                              }
                            </div> */}
                          </div>

                          {/* <div className="col-span-2 flex flex-col items-center gap-2">
                            <div>Read: {acc.account.canRead ? "YES" : "NO"}</div>
                            <div>Trade: {acc.account.canTrade ? "YES" : "NO"}</div>
                          </div> */}

                          <div className="col-span-3 flex flex-col items-center gap-2">
                            <div className={`flex flex-col w-full text-center items-strech justify-start overflow-hidden transition-all duration-300 ${isExpanded ? 'max-h-max' : 'max-h-7'}`}>
                              <p className="font-semibold">{acc.account.label}</p>
                            </div>
                          </div>

                          <div className="col-span-3 flex flex-col items-center gap-2">
                            {/* <div>Classic: {formatCurrency(acc.valuation.classicBalance)}</div>
                            <div>Earn: {formatCurrency(acc.valuation.earnBalance)}</div>
                            <div>Funding: {formatCurrency(acc.valuation.fundingBalance)}</div>
                            <div>Trading: {formatCurrency(acc.valuation.tradingBalance)}</div> */}
                            <div className="font-semibold">{formatCurrency(acc.valuation.totalBalance)}</div>
                          </div>

                          {/* <div className="col-span-2 flex flex-col items-center gap-2">
                            <div>Market: {acc.exchange_name}</div>
                            <div>HedgeMode: {acc.account.hedgeMode ? "YES" : "NO"}</div>
                            <div>Level: {acc.account.level}</div>
                          </div> */}

                          <div className="col-span-1 flex flex-col items-end gap-2">
                            <BsThreeDotsVertical
                              className="cursor-pointer duration-300 ease-in-out hover:text-primary text-xl"
                              onClick={() => { handleActionsAccount(acc); console.log(selectedAccount, acc);  }}
                              title="Actions"
                            />
                          </div>
                        </div>
                        <div className={`overflow-hidden transition-all duration-300 ${isExpanded ? 'max-h-max' : 'max-h-0'}`}>
                          {
                            acc.subAccounts.sort((a, b) => b.valuation.totalBalance - a.valuation.totalBalance).map((subAcc, i) => {
                              return (
                                <div key={`subAcc-${subAcc.id}-${i}`} className="grid grid-cols-12 grid-rows-auto gap-2">
                                  <div className="col-span-1">
                                    <div className="font-normal mb-4">{i+1}</div>
                                  </div>

                                  <div className="col-span-4">
                                    <p className={`text-[#637381] dark:text-bodydark whitespace-normal`}>{subAcc.name}</p>
                                  </div>

                                  <div className="col-span-3 flex flex-col items-center gap-2">
                                    <p className={`text-[#637381] dark:text-bodydark whitespace-normal`}>{subAcc.account.label}</p>
                                  </div>

                                  <div className="col-span-3 flex flex-col items-center gap-2">
                                    <p className={`text-[#637381] dark:text-bodydark whitespace-normal`}>{formatCurrency(subAcc.valuation.totalBalance)}</p>
                                  </div>

                                  <div className="col-span-1">
                                    
                                  </div>
                                </div>
                              )
                            })
                          }
                        </div>
                      </div>
                    )
                  })
                )
            }
          </div>
        </div>
      </div>
      <ModalBottom
        isOpen={isModalActionsOpen}
        toggleModal={toggleModalActions}
        content={
          <>
            {selectedAccount ?
              <>
                <li className="w-full border-b border-solid bg-white dark:bg-[#5A616B]  outline-none dark:text-white hover:text-primary hover:bg-gray-2 dark:border-strokedark dark:hover:bg-primary cursor-pointer">
                  <span
                    className="flex w-full p-4 justify-center"
                    onClick={() => { toggleModalActions(); handleTransferOpen(selectedAccount) }}
                  >Transfer balance</span>
                </li>
                <li className="w-full border-b border-solid bg-white dark:bg-[#5A616B]  outline-none dark:text-white hover:text-primary hover:bg-gray-2 dark:border-strokedark dark:hover:bg-primary cursor-pointer">
                  <span
                    className="flex w-full p-4 justify-center"
                    onClick={() => { toggleModalActions(); handleAddTaskOpen(selectedAccount) }}
                  >Add Task</span>
                </li>
              </>
            : null
            }
          </>
        }
        data={{
          type: "account",
          value: selectedAccount,
        }}
      />

      <ModalCenter
        isOpen={isModalTransfer}
        toggleModal={toggleModalTransfer}
        size="lg"
        content={
          selectedAccount && (
            <ContentModalSend
              accFrom={selectedAccount}
              onHandle={(result) => {
                handleTransfer(result);
              }}
              toggleModal={toggleModalTransfer}
              accounts={accounts}
            />
          )
        }
      />

      <ModalCenter
        isOpen={isModalAddTask}
        toggleModal={toggleModalAddTask}
        size="lg"
        content={
          selectedAccount && (
            // <></>
            <ContentModalAddTask
              accFrom={selectedAccount}
              onHandle={(result) => {
                  handleAddTask(result);
              }}
              toggleModal={toggleModalAddTask}
              accounts={accounts}
            />
          )
        }
      />
    </div>
  );
};
export default TableAccounts;