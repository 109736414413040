import React from 'react';
import { AccountsProvider } from '../../context/AccountsManagementContext';
import AccountsContent from './AccountsContent';

const AccountsManagement: React.FC = () => {
  
    return (
      <AccountsProvider>
        <AccountsContent />
      </AccountsProvider>
    );
  };
  
  export default AccountsManagement;