import React, { createContext, useContext, useState, useEffect } from 'react';
import api from '../utils/api';
import { Db_Bots } from '../types/botsAll';
import { AnalyticsContextProps } from '../pages/Analytics/interface';

const AnalyticsContext = createContext<AnalyticsContextProps | undefined>(undefined);

export const useAnalyticsContext = () => {
  const context = useContext(AnalyticsContext);
  if (!context) {
    throw new Error('useAnalyticsContext must be used within a AnalyticsProvider');
  }
  return context;
};

export const AnalyticsProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [botsAll, setBotsAll] = useState<Db_Bots[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const currentDateStart = new Date(new Date().setUTCHours(0, 0, 0, 0));
  const currentDateEnd = new Date(new Date().setUTCHours(23, 59, 59, 999));
  const sDate = currentDateStart.setUTCDate(currentDateStart.getUTCDate() - 6);
  const eDate = currentDateEnd.getTime();
  const [startDate, setStartDate] = useState<number>(sDate);
  const [endDate, setEndDate] = useState<number>(eDate);
  
  const [selectedBots, setSelectedBots] = useState<string[]>([]);
  const [selectedSymbols, setSelectedSymbols] = useState<string[]>([]);
  const [selectedApiKeys, setSelectedApiKeys] = useState<string[]>([]);

  useEffect(() => {

  }, []);

  const contextValue = {
    botsAll,
    isLoading,
    error,
    startDate,
    endDate,
    selectedBots,
    selectedSymbols,
    selectedApiKeys,
  };

  return (
    <AnalyticsContext.Provider value={contextValue}>
      {children}
    </AnalyticsContext.Provider>
  );
};