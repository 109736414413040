import React, { useState, useEffect } from 'react';
import api from '../../utils/api';
import { ApiResponse } from '../../types/api';
import { TasksProvider } from '../../context/TasksManagementContext';
import { useTasksManagementContext } from '../../context/TasksManagementContext';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import { Toaster, toast } from 'sonner';
import BtnPrimary from "../../components/Buttons/BtnPrimary";
import { BsPlusCircleFill } from "react-icons/bs";
import ModalSideRight from '../../components/Modals/ModalSideRight';
// import Task from './Task';
import TableTasks from './TableTasks';
import { ManagementTask } from './interface';
import { messages } from '../../data/messages';

const breadcrumbItems = [
  { name: 'Dashboard', link: '/dashboard' },
  { name: 'Management Tasks', link: '/tasks-management' }
];

const TasksContent: React.FC = () => {
  const {
    tasks,
    fetchTasks,
    selectedTask,
    toggleModalActions,
    handleDeleteTask,
    isModalDeleteOpen,
    toggleModalDelete,
    handleDeleteConfirm,
  } = useTasksManagementContext();
  
  const [task, setTask] = useState<ManagementTask[]>(tasks);
  const [isModalSideOpen, setIsModalSideOpen] = useState(false);
  
  const toggleModalSide = () => {
    setIsModalSideOpen(!isModalSideOpen);
  };

  return (
    <TasksProvider>
      <Toaster position="bottom-center" richColors />
      <Breadcrumb items={breadcrumbItems} />

      <TableTasks
        tasks={tasks}
        fetchTasks={fetchTasks}
        selectedTask={selectedTask}
        toggleModalActions={toggleModalActions}
        handleDeleteTask={handleDeleteTask}
        isModalDeleteOpen={isModalDeleteOpen}
        toggleModalDelete={toggleModalDelete}
        handleDeleteConfirm={handleDeleteConfirm}
      />

    </TasksProvider>
  );
};

export default TasksContent;