import React from 'react';
import { AnalyticsProvider } from '../../context/AnalyticsContext';
import AnalyticsContent from './AnalyticsContent';

const Analytics: React.FC = () => {
  
    return (
      <AnalyticsProvider>
        <AnalyticsContent />
      </AnalyticsProvider>
    );
  };
  
  export default Analytics;