export const TimeFrame = [
  {
    value: "1m",
    text: "1m",
  },
  {
    value: "3m",
    text: "3m",
  },
  {
    value: "5m",
    text: "5m",
  },
  {
    value: "15m",
    text: "15m",
  },
  {
    value: "30m",
    text: "30m",
  },
  {
    value: "1h",
    text: "1h",
  },
  {
    value: "2h",
    text: "2h",
  },
  {
    value: "4h",
    text: "4h",
  },
  {
    value: "8h",
    text: "8h",
  },
  {
    value: "12h",
    text: "12h",
  },
  {
    value: "1d",
    text: "1d",
  }
]