import React, { useState, useEffect, useCallback } from 'react';
import api from '../../utils/api';
import { AnalyticsProvider } from '../../context/AnalyticsContext';
import { useAnalyticsContext } from '../../context/AnalyticsContext';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import { Toaster, toast } from 'sonner';
import DatePicker from '../../components/Forms/DatePicker';
import MultipleSelect from '../../components/Forms/Select/MultipleSelect';
import MultiSelect from '../../components/Forms/Select/MultiSelect';
import SimpleSelect from '../../components/Forms/Select/SimpleSelect';
import { Db_Bots } from '../../types/botsAll';
import { transformBotsToOptions } from '../../utils/transformBotsToOption';
import { transformSymbolsToOptions } from '../../utils/transformSymbolsToOption';
import { transformKeysToOptions } from '../../utils/transformKeysToOptions';
import { getFormatUTCTimestamp } from '../../utils/getFormatUTCTimestamp';
import { optionsStrategies } from './optionsStrategies';
import { ApiResponse } from '../../types/api';
import { Db_Symbols } from '../../types/symbols';
import { messages } from '../../data/messages';
import { categorys } from '../../data/categorys';
import { KEY, GetApiKeysAll } from '../../types/key';
import { formatTimeUTC } from '../../utils/formatTimeUTC';
import { formatDateUTC } from '../../utils/formatDateUTC';
import { formatCurrency } from '../../utils/formatCurrency';
import BtnPrimary from "../../components/Buttons/BtnPrimary";
import { FaDownload } from "react-icons/fa";
import { PieChart } from '@mui/x-charts/PieChart';
import './PieChart.css';

const breadcrumbItems = [
  { name: 'Dashboard', link: '/dashboard' },
  { name: `Analytics`, link: '/analytics' }
];

const AnalyticsContent: React.FC = () => {
  const {
    startDate,
    endDate,
    selectedSymbols,
    selectedBots,
    selectedApiKeys,
  } = useAnalyticsContext();
  const [selectedDates, setSelectedDates] = useState<Date[]>([new Date(startDate), new Date(endDate)]);
  const [botsAll, setBotsAll] = useState<string[]>(selectedBots);
  const [arrBotsAll, setArrBotsAll] = useState<Db_Bots[]>([]);
  const [botsAllFetched, setBotsAllFetched] = useState(false);
  const [symbolsSelect, setSymbolsSelect] = useState<string[]>(selectedSymbols || []);
  const [strategy, setStrategy] = useState<string[]>([]);
  const [symbolData, setSymbolData] = useState<{ value: string; text: string; }[]>([])
  const [arrApiKeys, setArrApiKeys] = useState<KEY[]>([]);
  const [apiKeys, setApiKeys] = useState<string[]>(selectedApiKeys);
  const [apiKeysFetched, setApiKeysFetched] = useState(false);

  const [profit, setProfit] = useState<number>(0)
  const [fee, setFee] = useState<number>(0)
  const [turnover, setTurnover] = useState<number>(0)
  const [maxDD, setMaxDD] = useState<number>(0)
  const [maxLoad, setMaxLoad] = useState<number>(0)
  const [maxLoadNotional, setMaxLoadNotional] = useState<number>(0)
  const [countOrders, setCountOrders] = useState<number>(0)
  const [liquidation, setLiquidation] = useState<number>(0)
  const [timeRange, setTimeRange] = useState<string>();

  useEffect(() => {
    const fetchSymbols = async () => {
      try {
        if (!localStorage.getItem('okxFutures_symbols')) {
          const symbolsResponse = await api.get<ApiResponse<Db_Symbols>>('/api/symbols?exchange=okxFuture');
          await new Promise(resolve => setTimeout(resolve, 500));
          localStorage.setItem('okxFutures_symbols', JSON.stringify(symbolsResponse.data.result));
        }
        localStorage.getItem('okxFutures_symbols') ? setSymbolData(transformSymbolsToOptions(JSON.parse(localStorage.getItem('okxFutures_symbols') || ""))) : setSymbolData([])
      } catch (error) {
        console.error(messages.symbols.fetch, error);
      } finally {
      }
    }
    fetchSymbols()
    handleFilteredData(botsAll, symbolsSelect, strategy, apiKeys, selectedDates)
  }, []);

  useEffect(() => {
    if (startDate && endDate) {
      setSelectedDates([new Date(getFormatUTCTimestamp(startDate)), new Date(getFormatUTCTimestamp(endDate))]);
      handleSelectDateRange(startDate, endDate);

      // console.log("startDate = ", startDate)
      // console.log("startDate.getTime() = ", new Date(startDate).getTime())

      // let dateS = new Date(startDate);
      // let utcDateS = new Date(dateS.getTime() + dateS.getTimezoneOffset() * 60000).getTime();
      // console.log("dateS = ", dateS, ", utcDateS = ", utcDateS, "new Date(startDate).getTime() = ", new Date(startDate).getTime(), "new Date(utcDateS).getTime() = ", new Date(utcDateS).getTime())

      // let dateE = new Date(endDate);
      // let utcDateE = new Date(dateE.getTime() + dateE.getTimezoneOffset() * 60000);
      // console.log("dateE = ", dateE, ", utcDateS = ", utcDateE)
    }
  }, [startDate, endDate]);

  const handleSelectDateRange = (startDate: number, endDate: number) => {
    let dateStart = new Date(getFormatUTCTimestamp(startDate));
    let dateEnd = new Date(getFormatUTCTimestamp(endDate));
    let year = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(dateStart);
    let month = new Intl.DateTimeFormat('en', { month: 'short' }).format(dateStart);
    let day = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(dateStart);
    let year2 = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(dateEnd);
    let month2 = new Intl.DateTimeFormat('en', { month: 'short' }).format(dateEnd);
    let day2= new Intl.DateTimeFormat('en', { day: '2-digit' }).format(dateEnd);
    // handleChangeTimeRange(new Date(selectedDate[0]).toString())
    handleChangeTimeRange(`${month} ${day}, ${year} to ${month2} ${day2}, ${year2}`);
  }

  // useEffect(() => {
  //   if (!botsAllFetched) {
  //     getBotsAll();
  //     setBotsAllFetched(true);
  //   }
  // }, [botsAllFetched]);

  const getBotsAll = async () => {
    try {
      const response = await api.post(`/bot-api/bots/`, {
        "pagination": {
          "page": 1,
          "limit": 100
        }
      });
      if (response.data.code === 200 && response.data.result) {
        setArrBotsAll(response.data.result.items);
      } else {
        toast.error(messages.bots.loading.error);
      }
    } catch (error) {
      toast.error(messages.bots.loading.api);
    }
  };

  useEffect(() => {
    if (!apiKeysFetched) {
      getApiKeys();
      setApiKeysFetched(true);
    }
  }, [apiKeysFetched]);

  const getApiKeys = async () => {
    try {
      const response = await api.post<ApiResponse<GetApiKeysAll>>(`/api/api_keys/search`, {
        "pagination": {
          "page": 1,
          "limit": 100
        }
      }
      );
      if (response.data.code === 200 && response.data.result) {
        setArrApiKeys(response.data.result.items);
      } else {
        toast.error(`Error loading api keys`);
      }
    } catch (error) {
      toast.error(`Error loading api keys`);
    }
  };

  const handleDateChange = (selectedDate: Date[]) => {
    if (selectedDate.length === 2) {
      setSelectedDates(selectedDate);
    }
    handleFilteredData(botsAll, symbolsSelect, strategy, apiKeys, selectedDate)
    handleSelectDateRange(new Date(selectedDate[0]).getTime(), new Date(selectedDate[1]).getTime())
  };

  const handleFilteredData = useCallback(async (
    selectedBots: string[] = botsAll,
    selectedSymbols: string[] = symbolsSelect,
    selectedStrategy: string[] = strategy,
    selectedApiKeys: string[] = apiKeys,
    selectedDate: Date[] = selectedDates
  ) => {
    try {
      const data = {
        "from": getFormatUTCTimestamp(selectedDate[0].getTime()),
        "to": getFormatUTCTimestamp(selectedDate[1].getTime()),
        "bots": selectedBots,
        "keys": selectedApiKeys,
        "symbols": selectedSymbols,
        "strategies": selectedStrategy,
      };
      const response = await api.post(`/api/analytics/info`, data);
      if (response.data.code === 200 && response.data.result) {
        setProfit(response.data.result.profit);
        setFee(response.data.result.fee)
        setTurnover(response.data.result.turnover)
        setMaxDD(response.data.result.maxDD)
        setMaxLoad(response.data.result.maxLoad)
        setMaxLoadNotional(response.data.result.maxLoadNotional)
        setCountOrders(response.data.result.countOrders)
        setLiquidation(response.data.result.liquidation)
      } else {
        toast.error(messages.analytics.loading.error);
      }
    } catch (error) {
      toast.error(messages.analytics.loading.error);
    }
  }, [])

  const handleBotChange = (selectedValues: string[]) => {
    setBotsAll(selectedValues);
    handleFilteredData(selectedValues, symbolsSelect, strategy, apiKeys, selectedDates)
  };

  const handleSymbolChange = (selectedValues: string[]) => {
    setSymbolsSelect(selectedValues)
    handleFilteredData(botsAll, selectedValues, strategy, apiKeys, selectedDates)
  };

  const handleStrategyChange = (selectedStrategy: string[]) => {
    setStrategy(selectedStrategy)
    handleFilteredData(botsAll, symbolsSelect, selectedStrategy, apiKeys, selectedDates)
  };

  const handleApiKeysChange = (selectedApiKeys: string[]) => {
    setApiKeys(selectedApiKeys);
    handleFilteredData(botsAll, symbolsSelect, strategy, selectedApiKeys, selectedDates)
  };

  const apiKeyOptions = transformKeysToOptions(arrApiKeys);
  const botsAllOptions = transformBotsToOptions(arrBotsAll);

  const downloadReport = async () => {

  };

  // console.log(arrApiKeys)

  const transformApiKeysForDataPieChart = (arrApiKeys: KEY[]) => {
    let arrData: { id: string, value: number, label: string }[] = [];
    arrApiKeys?.map((item, index) => {
      arrData.push({
        id: item.id,
        value: 10 * index + 5,
        label: item.name,
        // color: `#0088FE`,
      })
    })
    return arrData;
  }

  // console.log(transformApiKeysForDataPieChart(arrApiKeys))

  const handleChangeTimeRange = (selectedPeriod: string) => {
    setTimeRange(selectedPeriod)
  };

  return (
    <AnalyticsProvider>
      <Toaster position="bottom-center" richColors />
      <Breadcrumb items={breadcrumbItems} />

      <div className="grid grid-cols-1 sm:grid-cols-1 2xl:grid-cols-2 h-full gap-6">
        <div className="flex flex-col h-full mb-6 rounded-lg border border-stroke bg-white py-6 px-7.5 shadow-default dark:border-strokedark dark:bg-boxdark">
          <div className="flex-grow">
            <div className='flex flex-col gap-2 mb-4'>
              <p className='text-black dark:text-white'>Choose period data</p>
              <DatePicker
                options={{
                  mode: 'range',
                  static: true,
                  monthSelectorType: 'static',
                  dateFormat: 'M j, Y',
                  altFormat: "F j, Y",
                  onClose: handleDateChange,
                  defaultDate: selectedDates,
                }}
                // handleChangeTimeRange={handleChangeTimeRange}
              />
            </div>

            <div className="flex flex-col sm:flex-row gap-4 mb-0">
              {/* <MultiSelect
              label="Choose bots"
              options={botsAllOptions.map(bot => ({ value: bot.value, text: bot.text, selected: botsAll.includes(bot.value) }))}
              defaultValue={botsAll}
              onChange={handleBotChange}
              className='w-full sm:mb-4'
              disabled={apiKeys.length > 0}
              multiple={true}
            /> */}
              {/* </div>

            <div className="flex flex-col sm:flex-row gap-4 mb-0"> */}
              <MultiSelect
                label="Choose api keys"
                options={apiKeyOptions.map(key => ({ value: key.value, text: key.text, selected: apiKeys.includes(key.value) }))}
                defaultValue={apiKeys}
                onChange={handleApiKeysChange}
                className='z-50 w-full'
                disabled={botsAll.length > 0}
                multiple={true}
              />
              <MultiSelect
                label="Category"
                options={categorys}
                defaultValue={strategy}
                onChange={handleStrategyChange}
                className='z-40 w-full'
                multiple={true}
              />
              
              <MultiSelect
                label="Choose symbols"
                options={symbolData}
                defaultValue={symbolsSelect}
                onChange={handleSymbolChange}
                className='z-30 w-full'
                multiple={true}
              />
            </div>
          </div>
        </div>
        <div className="order-1 2xl:order-none flex-grow rounded-lg border border-stroke bg-white py-6 px-7.5 shadow-default dark:border-strokedark dark:bg-boxdark font-medium text-[#637381] dark:text-white">
          <PieChart
            // colors={['red', 'blue', 'green', 'pink', 'yellow', 'lightblue', 'grey']} // Use palette
            // labelStyle={{
            //   fill: 'white',
            //   fontSize: '10px'
            // }}
            series={[
              {
                arcLabel: (item) => `${item.value}%`,
                arcLabelMinAngle: 35,
                arcLabelRadius: '60%',
                data: transformApiKeysForDataPieChart(arrApiKeys),
              },
            ]}
            width={500}
            height={200}
            slotProps={{
              legend: {
                position: {
                  vertical: 'middle',
                  horizontal: 'right',
                },
                itemMarkWidth: 20,
                itemMarkHeight: 2,
                markGap: 5,
                itemGap: 10,
              }
            }}
          />
        </div>
      {/* </div> */}
      <div className="flex flex-col items-start h-full gap-4 sm:gap-0 rounded-lg border border-stroke bg-white py-6 px-7.5 shadow-default dark:border-strokedark dark:bg-boxdark">
        <h2 className="w-full mb-5 font-medium text-center text-[#637381] dark:text-white font-bold">Analytics for the period: {timeRange}</h2>
        <div className="w-full flex flex-col sm:flex-row gap-4 mb-0">
          <h3 className="w-[50%] sm:mb-2.5 block font-medium text-[#637381] dark:text-white">
            Profit: <span className={`font-normal ${profit === 0 ? "text-[#637381] dark:text-bodydark" : profit < 0 ? "text-[#FF5733]" : "text-[#3CA745]"}`}>{formatCurrency(profit)}</span>
          </h3>
          <h3 className="w-[50%] sm:mb-2.5 block font-medium text-[#637381] dark:text-white">
            Commission: <span className={`font-normal ${fee === 0 ? "text-[#637381] dark:text-bodydark" : fee < 0 ? "text-[#FF5733]" : "text-[#3CA745]"}`}>{formatCurrency(fee)}</span>
          </h3>
        </div>
        {/* {apiKeys.length > 0 && ( */}
        <div className="w-full flex flex-col sm:flex-row gap-4 mb-0">
          <h3 className="w-[50%] sm:mb-2.5 block font-medium text-[#637381] dark:text-white">
            Max. Load: <span className="font-normal text-[#637381] dark:text-bodydark">{formatCurrency(maxLoadNotional)}</span>
          </h3>
          <h3 className="w-[50%] sm:mb-2.5 block font-medium text-[#637381] dark:text-white">
            Load Leverage: <span className="font-normal text-[#637381] dark:text-bodydark">{(maxLoad / 100).toFixed(0)}x</span>
          </h3>
        </div>
        {/* )} */}

        <div className="w-full flex flex-col sm:flex-row gap-4 mb-0">
          <h3 className="w-[50%] sm:mb-2.5 block font-medium text-[#637381] dark:text-white">
            Count of orders: <span className="font-normal text-[#637381] dark:text-bodydark">{countOrders}</span>
          </h3>
          <h3 className="w-[50%] sm:mb-2.5 block font-medium text-[#637381] dark:text-white">
            Turnover: <span className="font-normal text-[#637381] dark:text-bodydark">{formatCurrency(turnover)}</span>
          </h3>

        </div>
        <div className="w-full flex flex-col sm:flex-row gap-4 mb-0">
          {/* {apiKeys.length > 0 && ( */}
          <h3 className="w-[50%] sm:mb-2.5 block font-medium text-[#637381] dark:text-white">
            Max. Drawdown: <span className="font-normal text-[#637381] dark:text-bodydark">{maxDD.toFixed(2)}%</span>
          </h3>
          {/* )} */}
          <h3 className="w-[50%] sm:mb-2.5 block font-medium text-[#637381] dark:text-white">
            Liquid losses: <span className={`font-normal ${liquidation === 0 ? "text-[#637381] dark:text-bodydark" : liquidation < 0 ? "text-[#FF5733]" : "text-[#3CA745]"}`}>{formatCurrency(liquidation)}</span>
          </h3>
        </div>


        <BtnPrimary onClick={downloadReport} disabled className="self-end">
          <FaDownload /> Download report
        </BtnPrimary>
      </div>
      </div>

    </AnalyticsProvider>
  );
};

export default AnalyticsContent;