import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import api from '../../utils/api'
import { Reports_struct, BotsForReport_struct, BotSettings_struct, Report_struct, Reports_struct_tmp } from '../../types/report';
import Settings from './Settings';
import { formatCurrency } from '../../utils/formatCurrency';
import { BsFileBarGraphFill } from "react-icons/bs";
import { useNavigate } from 'react-router-dom';
import NotFound from "../../common/NotFound";
import InputField from '../../components/Forms/Inputs/InputField';
import BtnPrimary from '../../components/Buttons/BtnPrimary';
import { BsSearch, BsCloudDownloadFill, BsDownload } from "react-icons/bs";
import ModalCenter from '../../components/Modals/ModalCenter';
import DetailsReport from '../Benchmark/DetailsReport';

const downloadFile = async (path: string, name: string) => {
  try {
    // console.log(`/benchmark/reports/${path}/${name}`)
    fetch(`/benchmark/reports/${path}/${name}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
      },
    })
      .then(response => response.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.download = name;
        document.body.appendChild(link);
        link.click();
        link.parentNode?.removeChild(link)
      })
  } catch (error) {
    console.log("Errr data", error)
  }
};

const ReportPage: React.FC = () => {
  const { idTask } = useParams<{ idTask: string }>();
  const [reportData, setReportData] = useState<Reports_struct[] | null>(null);
  const navigate = useNavigate();
  const [minusPeriods, setMinusPeriods] = useState<number>(0);
  const [drawDownFrom, setDrawDownFrom] = useState<number>(0);
  const [drawDownTo, setDrawDownTo] = useState<number>(0);
  const [profitFrom, setProfitFrom] = useState<number>(0);
  const [profitTo, setProfitTo] = useState<number>(0);
  const [isSelectedReport, setIsSelectedReport] = useState<boolean>(false);
  const [selectedReport, setSelectedReport] = useState<Reports_struct>();

  useEffect(() => {
    const fetchReportData = async () => {
      try {
        const response = await api.get<Reports_struct_tmp>(`/benchmark-api/reports/light/${idTask}`);
        // console.log(response.data.result);

        setReportData(response.data.result);

        let arrTotalPeriods: number[] = []
        response.data.result?.map((res) => {
          arrTotalPeriods.push(res.report.totalPeriods)
        })
        let maxEl = Math.max(...arrTotalPeriods);
        setMinusPeriods(maxEl);
        // console.log(arrTotalPeriods, maxEl)

        // // Extract settings, periods, and bots
        // const settingsArray: BotSettings_struct[] = [];
        // const periodsArray: Report_struct[] = [];
        // const botsArray: BotsForReport_struct[] = response.data.report.bots || [];

        // botsArray.forEach(bot => {
        //   settingsArray.push(bot.settings);
        //   periodsArray.push(...bot.reports);
        // });

        // setSettings(settingsArray);
        // setPeriods(periodsArray);
        // setBots(botsArray);
      } catch (error) {
        console.error('Error fetching report data:', error);
      }
    };

    fetchReportData();
  }, [idTask]);

  // if (!reportData) {
  //   return <div>No report data found</div>;
  // }

  const handleReportClick = (reportId: string) => {
    navigate(`/details-report/${reportId}`);
  };
  // const { report } = reportData;
  // const { symbol, total } = report;
  // console.log(reportData, reportData.length)

  const handleMinusPeriodsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setMinusPeriods(Number(value === '' ? '' : parseFloat(value.replace(",", "."))));
  };

  const handleDrawDownFromChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setDrawDownFrom(Number(value === '' ? '' : parseFloat(value.replace(",", "."))));
  };

  const handleDrawDownToChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setDrawDownTo(Number(value === '' ? '' : parseFloat(value.replace(",", "."))));
  };

  const handleProfitFromChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setProfitFrom(Number(value === '' ? '' : parseFloat(value.replace(",", "."))));
  };

  const handleProfitToChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setProfitTo(Number(value === '' ? '' : parseFloat(value.replace(",", "."))));
  };

  const handleSearch = async () => {
    try {
      const data = {
        minusPeriods: minusPeriods,
        drawDown: {
          from: drawDownFrom,
          to: drawDownTo
        },
        profit: {
          from: profitFrom,
          to: profitTo
        }
      }

      const response = await api.post<Reports_struct_tmp>(`/benchmark-api/reports/light/${idTask}`, data);
      // console.log(response.data.result);
      setReportData(response.data.result);
    } catch (error) {
      console.error('Error fetching report data:', error);
    }
  }

  const toggleModalReportDetails = (report: Reports_struct) => {
    setSelectedReport(report)
    setIsSelectedReport(!isSelectedReport);
  };

  return (
    <div>
      <div className='grid'>
        <h2 className="text-title-md2 font-semibold text-black dark:text-white mb-6">Results Benchmark {reportData ? `"${reportData[0]?.taskName}"` : null}</h2>
        {reportData && reportData.length > 0 && (
          <div className="flex items-end gap-4">
            <InputField
              label="Minus Periods, <="
              value={minusPeriods}
              onChange={handleMinusPeriodsChange}
              placeholder="Periods"
              className="mb-4"
              type="number"
              step="0.01"
            />

            <InputField
              label="DrawDown"
              value={drawDownFrom}
              onChange={handleDrawDownFromChange}
              placeholder="from"
              className="mb-4"
              type="number"
              step="0.01"
            />

            <InputField
              label=""
              value={drawDownTo}
              onChange={handleDrawDownToChange}
              placeholder="to"
              className="mb-4"
              type="number"
              step="0.01"
            />

            <InputField
              label="Profit"
              value={profitFrom}
              onChange={handleProfitFromChange}
              placeholder="from"
              className="mb-4"
              type="number"
              step="0.01"
            />

            <InputField
              label=""
              value={profitTo}
              onChange={handleProfitToChange}
              placeholder="to"
              className="mb-4"
              type="number"
              step="0.01"
            />

            <BtnPrimary
              onClick={() => {
                handleSearch()
              }}
              className="mb-6"
              disabled={reportData.length === 0}
            >
              <BsSearch /> Search
            </BtnPrimary>
          </div>
        )}
        <div className='overflow-hidden rounded-[10px]'>
          <div className='max-w-full overflow-x-auto'>
            <div className='min-w-[1170px]'>

              <div className='grid grid-cols-12 gap-4 bg-[#F9FAFB] px-5 py-4 dark:bg-meta-4 lg:px-7.5 2xl:px-11'>
                <div className="col-span-1">
                  <h5 className="font-medium text-[#637381] dark:text-bodydark">#</h5>
                </div>
                <div className="col-span-2">
                  <h5 className="font-medium text-center text-[#637381] dark:text-bodydark">NAME</h5>
                </div>
                <div className="col-span-2">
                  <h5 className="font-medium text-center text-[#637381] dark:text-bodydark">PERIODS/MINUS</h5>
                </div>
                <div className="col-span-1">
                  <h5 className="font-medium text-[#637381] dark:text-bodydark">DD</h5>
                </div>
                <div className="col-span-2">
                  <h5 className="font-medium text-[#637381] dark:text-bodydark">PROFIT</h5>
                </div>
                <div className="col-span-2">
                  <h5 className="font-medium text-[#637381] dark:text-bodydark">VOLUME</h5>
                </div>
                {/* <div className="col-span-1">
                  <h5 className="font-medium text-[#637381] dark:text-bodydark">FEE</h5>
                </div> */}
                {/* <div className="col-span-1">
                  <h5 className="font-medium text-[#637381] dark:text-bodydark">CASHBACK</h5>
                </div> */}
                <div className="col-span-1">
                  <h5 className="font-medium text-[#637381] dark:text-bodydark">Liqudation</h5>
                </div>
                <div className="col-span-1">
                  <h5 className="font-medium text-[#637381] dark:text-bodydark">ACTIONS</h5>
                </div>
              </div>

              <div className='bg-white dark:bg-boxdark'>
                {reportData && reportData.length > 0 ?
                  (reportData.map((report, key) => {
                    let vol = report.report.total.volume
                    // let fee = vol / 100 * 0.2
                    // let cashback = fee / 100 * 30
                    // console.log(report, vol, fee, cashback)
                    return (
                      <div className='grid grid-cols-12 gap-4 border-t border-[#EEEEEE] px-5 py-4 dark:border-strokedark lg:px-7.5 2xl:px-11' key={key}>
                        <div className="col-span-1">
                          <div className="flex gap-3 items-center">
                            <p className="text-[#637381] dark:text-bodydark">{key + 1}</p>
                          </div>
                        </div>
                        <div className="col-span-2">
                          <p className="text-[#637381] dark:text-bodydark">{report.strategyName}</p>
                        </div>
                        <div className="col-span-2">
                          <p className="text-center text-[#637381] dark:text-bodydark">{report.report.totalPeriods}/{report.report.minusPeriods}</p>
                        </div>
                        <div className="col-span-1">
                          <p className="text-[#637381] dark:text-bodydark">
                            {(report.report.maxDrawDown * 100).toFixed(2)}%
                          </p>
                        </div>
                        <div className="col-span-2">
                          <p className="text-[#637381] dark:text-bodydark">{formatCurrency(report.report.total.realizedProfit)}</p>
                        </div>
                        <div className="col-span-2">
                          <div className="flex gap-3 items-start">
                            <p className="text-[#637381] dark:text-bodydark">{formatCurrency(vol)}</p>
                          </div>
                        </div>
                        {/* <div className="col-span-1 flex items-center gap-4">
                          <p className="text-[#637381] dark:text-bodydark">{formatCurrency(fee)}</p>
                        </div> */}
                        {/* <div className="col-span-1 flex items-center gap-4">
                          <p className="text-[#637381] dark:text-bodydark">{formatCurrency(cashback)}</p>
                        </div> */}
                        <div className="col-span-1">
                          <p className="text-[#637381] dark:text-bodydark">{report.report?.liqudation}</p>
                        </div>
                        <div className="col-span-1 flex items-center gap-4">
                          <BsFileBarGraphFill
                            onClick={() => {
                              // handleReportClick(report.id);
                              toggleModalReportDetails(report);
                            }}
                          />
                          {report.save_orders && (
                            <BsCloudDownloadFill
                              onClick={() => {
                                downloadFile(`${idTask}/${report.strategyName}`, `${report.strategyName}-orders.csv`);
                              }}
                            />
                          )}
                          {report.save_orders && (
                            <BsDownload
                              onClick={() => {
                                downloadFile(`${idTask}/${report.strategyName}`, `${report.strategyName}-days_uPnL.csv`);
                              }}
                            />
                          )}
                        </div>

                      </div>
                    )
                  })) :
                  <NotFound />
                }
              </div>
              {reportData && reportData.length > 0 ?
                <ModalCenter
                  isOpen={isSelectedReport}
                  toggleModal={() => selectedReport ? toggleModalReportDetails(selectedReport) : null}
                  content={
                    <DetailsReport
                      toggleModal={() => selectedReport ? toggleModalReportDetails(selectedReport) : null}
                      report={selectedReport}
                      idReport={selectedReport?.id}
                    />
                  }
                  size={'xl'}
                />
                : null
              }
            </div>
          </div>
        </div>

      </div>
    </div>
  );
};

export default ReportPage;